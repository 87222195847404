import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import { useSelector, useDispatch, useStore } from "react-redux";
import { toast } from "react-toastify";

import { AddressAutofill } from "@mapbox/search-js-react";

import {
  addCorporate,
  updateCorporate,
  resetCorporation,
} from "../../../Redux/Corporate/CorporateAction";

import moment from "moment";
import { useFormik } from "formik";
import * as yup from "yup";

import Box from "@mui/material/Box";
import {
  TextField,
  Autocomplete,
  Button,
  Checkbox,
  Switch,
  FormGroup,
  FormControl,
  FormLabel,
  FormControlLabel,
  Tooltip,
  InputAdornment,
} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";

import BorderColorIcon from "@mui/icons-material/BorderColor";
import AedasLoading from "../AedasLoading/AedasLoading";
import CustomModal from "../ModalCustom/CustomModal";

import { useFetch, useIsLoading } from "../../../Hooks/useFetch";
import { suffixRemover } from "../../../Utils/stringTransform";
import {
  getCorporateById,
  getCorporatesTypes,
  addNewCorporate,
  getMilestonesTypes,
  updateCorporateService,
  getDocumentByID,
  getCorporateBody,
  getCapitalByCorpID,
  removeCorporation,
} from "../../../Services/CorporateServices/CorporateServices";

import "./../../Static/Corporate/CorporateMain.css";

export const Corporate = (props: any) => {
  const validationSchema = yup.object({
    registered_name: yup
      .string()
      .min(2, "Campo obligatorio")
      .required("Campo obligatorio"),
    cif: yup
      .string()
      .min(9, "Debes ingresar 9 caracteres")
      .max(9, "Debes ingresar 9 caracteres")
      .required("Campo obligatorio"),
    creation_date: yup.string().required("Campo obligatorio"),
    files: !props.masterEditor
      ? yup
          .array()
          .min(1, "Debes adjuntar las escrituras de constitución")
          .nullable()
      : yup.object().nullable(),
    // protocol_number: yup.string().min(2, 'Campo obligatorio').required('Campo obligatorio'),
    // date_protocol: yup.string().min(2, 'Campo obligatorio').required('Campo obligatorio'),
    // notary_name: yup.string().min(2, 'Campo obligatorio').required('Campo obligatorio'),
    // registration_info: yup.string().min(2, 'Campo obligatorio').required('Campo obligatorio'),
    corporate_purpose: yup
      .string()
      .min(2, "Campo obligatorio")
      .required("Campo obligatorio"),
    full_address: yup
      .string()
      .min(2, "Campo obligatorio")
      .required("Campo obligatorio"),
    postal_code: yup
      .number()
      .typeError("Campo obligatorio")
      .required("Campo obligatorio"),
    town: yup
      .string()
      .min(2, "Campo obligatorio")
      .required("Campo obligatorio"),
    province: yup
      .string()
      .min(2, "Campo obligatorio")
      .required("Campo obligatorio"),
    country: yup
      .string()
      .min(2, "Campo obligatorio")
      .required("Campo obligatorio"),
  });

  const navigate = useNavigate();
  const store = useStore();
  const dispatch = useDispatch();
  const params: any = useParams();
  const [id, setId] = useState(params ? params.corporate_id : "nueva");

  // const [ isLoading, setIsLoading ] = useState(false);
  const [milestonesTypes, isFetchingMilestones] = useFetch(getMilestonesTypes);

  const storeCorporateData = store.getState().corporateReducer.corporate;

  const [corporateTypes, isFetchingCorporateTypes] = useFetch(
    getCorporatesTypes,
    id
  );
  const [files, setFiles] = useState<any[]>([]);

  const isLoading = useIsLoading([isFetchingCorporateTypes]);

  const [editModalShow, setEditModalShow] = useState(false);
  const [showText, setShowText] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [milestone, setMilestone] = useState("");
  const [isLoadingButton, setIsLoadingButton] = useState(false);

  const [newCorporateCreated, setNewCorporateCreated] = useState(null);
  const [nextStep, setNextStep] = useState("");
  const [openDeleteModal, setOpenDeleteModal] = useState(false);

  const userRol = useSelector(
    (state: any) => state.userReducer.user.employee.id_rol
  );
  const isEditor = userRol === 11 ? true : false;
  const isAdmin = userRol === 12 ? true : false;

  useEffect(() => {
    if (id == 0) {
      dispatch(resetCorporation());
    }
  }, []);

  useEffect(() => {
    if (props.masterEditor) {
      getCapitalByCorpID(props.corporate.id_corporation)
        .then((response: any) => {
          if (response.data.length == 0) {
            setNextStep("capital-inicial");
          }
        })
        .then(() => {
          getCorporateBody(props.corporate.id_corporation).then(
            (response: any) => {
              if (response.data.length == 0) {
                setNextStep("alta-organo-societario");
              }
            }
          );
        });
    }
  }, [props.masterEditor]);

  const formik = useFormik({
    initialValues: {
      registered_name: props.corporate
        ? suffixRemover(props.corporate?.registered_name)
        : storeCorporateData.registered_name,
      type: props.corporate ? props.corporate?.type : storeCorporateData.type,
      cif: props.corporate ? props.corporate?.cif : storeCorporateData.cif,
      controlled: props.corporate
        ? props.corporate?.controlled
        : storeCorporateData.controlled,
      group: props.corporate
        ? props.corporate?.group
        : storeCorporateData.group,
      audited: props.corporate
        ? props.corporate?.audited
        : storeCorporateData.audited.value,
      audited_end:
        props.corporate && props.corporate.audited
          ? props.corporate?.audited_person[0].date_end
          : storeCorporateData.audited.end_date,
      auditor:
        props.corporate && props.corporate.audited
          ? props.corporate?.audited_person[0].name
          : storeCorporateData.audited.person[0].name,
      auditor_juridical:
        props.corporate && props.corporate.audited
          ? props.corporate?.audited_person[0].juridical
          : storeCorporateData.audited.person[0].juridical,
      auditor_nif:
        props.corporate && props.corporate.audited
          ? props.corporate?.audited_person[0].juridical.cif_nif
          : storeCorporateData.audited.person[0].cif_nif,
      creation_date: props.corporate
        ? moment(props.corporate?.creation_date).format("YYYY-MM-DD")
        : storeCorporateData.creation_date,
      end_date: props.corporate
        ? moment(props.corporate?.end_date).format("YYYY-MM-DD")
        : storeCorporateData.end_date,
      files: files,
      date_registration: null,
      corporate_purpose: props.corporate
        ? props.corporate?.corporate_purpose
        : storeCorporateData.corporate_purpose,
      full_address: props.corporate
        ? props.corporate?.full_address
        : storeCorporateData.full_address,
      postal_code: props.corporate
        ? props.corporate?.postal_code
        : storeCorporateData.postal_code,
      town: props.corporate ? props.corporate?.town : storeCorporateData.town,
      province: props.corporate
        ? props.corporate?.province
        : storeCorporateData.province,
      country: props.corporate
        ? props.corporate?.country
        : storeCorporateData.country,
      comments: props.corporate
        ? props.corporate?.comments
        : storeCorporateData.comments,
      protocol_number: props.corporate
        ? props.corporate?.protocol_number
        : storeCorporateData.protocol_number,
      date_protocol: props.corporate
        ? moment(props.corporate?.date_protocol).format("YYYY-MM-DD")
        : storeCorporateData.date_protocol,
      notary_name: props.corporate
        ? props.corporate?.notary_name
        : storeCorporateData.notary_name,
      registration_info: props.corporate
        ? props.corporate?.registration_info
        : storeCorporateData.registration_info,
    },

    validationSchema: validationSchema,
    enableReinitialize: props.masterEditor ? true : false,

    onSubmit: async (values: any) => {
      if (!isLoadingButton && newCorporateCreated) {
        navigate(`/sociedades`);
        return;
      }

      setIsLoadingButton(true);

      const filesToSend = files.map((file64: string) => {
        return file64.replace("data:", "").replace(/^.+,/, "");
      });

      const dateRegistration = moment(values.end_date).isValid()
        ? values.end_date
        : values.date_registration ?? values.creation_date;
      const documentObject = {
        //Remove "C:\\fakepath\\" and extension from file name
        name: values.files.slice(12, -4),
        document: filesToSend,
        date_registration: dateRegistration + "T00:00",
      };

      const auditObject = {
        value: values.audited,
        person: [
          {
            name: values.auditor,
            juridical: values.auditor_juridical,
            cif_nif: values.auditor_nif,
          },
        ],
        end_date: values.audited_end,
      };

      if (!props.edit) {
        if (props.masterEditor) {
          dispatch(updateCorporate({ ...values, audited: auditObject }));

          updateCorporateService(
            store.getState().corporateReducer.corporate,
            props.corporate.id_corporation
          )
            .then((response: any) => {
              toast.success("Se han actualizado los datos");
              setIsLoadingButton(false);
            })
            .then((response: any) => dispatch(resetCorporation()))
            .then((response: any) => navigate(`/sociedades/`));
        } else {
          dispatch(
            addCorporate({
              ...values,
              document_creation: documentObject,
              audited: auditObject,
            })
          );
          addNewCorporate({
            ...values,
            document_creation: documentObject,
            audited: auditObject,
          })
            .then((response: any) => {
              toast.success(
                `Se ha creado la sociedad ${response.data.id_corporation}`
              );
              setNewCorporateCreated(response.data.id_corporation);
              setIsLoadingButton(false);
            })
            .then((response: any) => dispatch(resetCorporation()));
        }
      } else {
        switch (milestone) {
          case "Modificación de la denominación social":
            dispatch(
              updateCorporate({
                ...values,
                document_registered_name: documentObject,
                audited: auditObject,
              })
            );
            break;

          case "Transformación social":
            dispatch(
              updateCorporate({
                ...values,
                document_type: documentObject,
                audited: auditObject,
              })
            );
            break;

          case "Disolución":
            dispatch(
              updateCorporate({
                ...values,
                document_end: documentObject,
                audited: auditObject,
              })
            );
            break;

          case "Redomiciliación internacional":
            dispatch(
              updateCorporate({
                ...values,
                document_address_international: documentObject,
                audited: auditObject,
              })
            );
            break;

          case "Modificación del domicilio social":
            dispatch(
              updateCorporate({
                ...values,
                document_address: documentObject,
                audited: auditObject,
              })
            );
            break;

          case "Modificación del objeto social":
            dispatch(
              updateCorporate({
                ...values,
                document_corporate_purpose: documentObject,
                audited: auditObject,
              })
            );
            break;

          default:
            dispatch(updateCorporate({ ...values, audited: auditObject }));
            break;
        }

        updateCorporateService(
          store.getState().corporateReducer.corporate,
          params.corporate_id
        )
          .then((response: any) =>
            toast.success("Se han actualizado los datos")
          )
          .then((response: any) => setEditModalShow(!editModalShow));
      }
    },
  });

  useEffect(() => {
    console.log(formik.values);
  }, [formik.values]);

  const handleControlledOptions = () => {
    formik.setFieldValue("controlled", !formik.values.controlled);
  };

  const handleUploadFiles = (files: any, event: any) => {
    let filesUploadedList = event.currentTarget.files;

    let fileListBase64: any[] = [];
    let fileNameList: any[] = [];

    Object.values(filesUploadedList).forEach((file: any) => {
      fileNameList.push(file.name);

      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        let fileBase64 = reader.result?.toString() || "";
        fileListBase64.push(fileBase64);
      };
    });

    formik.handleChange(event);
    setFiles(fileListBase64);
  };

  const handleEdit = (e: any) => {
    const inputId = e.target.id != "" ? e.target.id : e.target.parentNode.id;
    const milestoneDict: any = {
      "edit-registered-name": "Modificación de la denominación social",
      "edit-type": "Transformación social",
      "edit-end": "Disolución",
      "edit-corporate-purpose": "Modificación del objeto social",
      "edit-address": "Modificación del domicilio social",
      "edit-address-international": "Redomiciliación internacional",
      "edit-comments": "Modificar anotaciones",
    };
    const milestoneId = milestonesTypes.findIndex(
      (type: any) => type === milestoneDict[inputId]
    );
    const title =
      inputId === "edit-end"
        ? `Registrar ${milestoneDict[inputId]}`
        : milestoneDict[inputId];

    setMilestone(milestonesTypes[milestoneId]);
    setModalTitle(title);
    setEditModalShow(!editModalShow);
  };

  const handleOpenFile = () => {
    if (props.corporate.document_creation) {
      getDocumentByID(props.corporate.document_creation.id)
        .then((response) => {
          let pdfWindow = window.open("");
          pdfWindow?.document.write(
            `<iframe
                title='${response.data[0].name}'
                width='100%'
                height='100%'
                src='data:application/pdf;base64, ${encodeURI(
                  response.data[0].document
                )} '
            ></iframe>`
          );
        })
        .catch(() => toast.error("No se ha podido cargar el documento."));
    }
  };

  const handleProvince = (e: any) => {
    let provinceAutofill = e.target.value;

    if (provinceAutofill.startsWith("Provincia de ")) {
      const removePrefix = provinceAutofill.split("Provincia de ")[1];
      formik.setFieldValue("province", removePrefix);
    } else if (provinceAutofill.startsWith("provincia de ")) {
      const removePrefix = provinceAutofill.split("provincia de ")[1];
      formik.setFieldValue("province", removePrefix);
    } else if (provinceAutofill.startsWith("Región de ")) {
      const removePrefix = provinceAutofill.split("Región de ")[1];
      formik.setFieldValue("province", removePrefix);
    } else if (provinceAutofill.startsWith("región de ")) {
      const removePrefix = provinceAutofill.split("región de ")[1];
      formik.setFieldValue("province", removePrefix);
    } else {
      formik.setFieldValue("province", provinceAutofill);
    }
  };

  const handleNextStep = (e: any) => {
    if (newCorporateCreated != null) {
      navigate(`/alta-organo-societario/${newCorporateCreated}`);
    } else {
      navigate(`/${nextStep}/${props.corporate.id_corporation}`);
    }
  };

  const handleDelete = () => {
    removeCorporation(props.corporate.id_corporation)
      .then((response: any) => {
        setOpenDeleteModal(false);
        toast.success("Borrado");
      })
      .then((response: any) => navigate(`/sociedades/`))
      .catch(() => toast.error("Ha habido un problema"));
  };

  return (
    <>
      {isLoading ? (
        <AedasLoading></AedasLoading>
      ) : (
        <>
          <Box
            component="form"
            id="corporate-form"
            className={`text-center ${props.edit ? "milestone-edit" : ""}`}
            sx={{
              width: "100%",
              mx: "auto",
              my: 2,
              // backgroundColor: "#fff"
            }}
            noValidate
            autoComplete="on"
            onSubmit={formik.handleSubmit}
          >
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <div
                style={{
                  width: "50%",
                  textAlign: "left",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <TextField
                    id="registered_name"
                    label={
                      props.edit || props.masterEditor
                        ? "Denominación Social"
                        : "Denominación Social *"
                    }
                    variant={props.edit ? "standard" : "outlined"}
                    className={props.masterEditor ? "margin-left-0" : ""}
                    disabled={props.edit}
                    InputProps={
                      props.edit && !props.report && (isAdmin || isEditor)
                        ? {
                            endAdornment: (
                              <InputAdornment position="start">
                                <BorderColorIcon
                                  onClick={handleEdit}
                                  style={{ cursor: "pointer" }}
                                  id="edit-registered-name"
                                />
                              </InputAdornment>
                            ),
                          }
                        : {
                            ...params.InputProps,
                          }
                    }
                    helperText={
                      !props.edit
                        ? "Nombre de la sociedad sin abreviatura de tipo de sociedad"
                        : ""
                    }
                    style={{ margin: "1rem", width: "70%" }}
                    value={formik.values.registered_name}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.registered_name &&
                      Boolean(formik.errors.registered_name)
                    }
                  />

                  <Autocomplete
                    id="type"
                    className="autocomplete coporate"
                    options={corporateTypes}
                    // getOptionLabel={(option: any) => option.types}
                    onChange={(e, value) => {
                      formik.setFieldValue("type", value);
                    }}
                    style={{ margin: "1rem", width: "35%" }}
                    value={formik.values.type}
                    disabled={props.edit}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label={
                          props.edit || props.masterEditor ? "Tipo" : "Tipo *"
                        }
                        variant={props.edit ? "standard" : "outlined"}
                        InputProps={
                          props.edit && !props.report && (isAdmin || isEditor)
                            ? {
                                endAdornment: (
                                  <InputAdornment position="end">
                                    <BorderColorIcon
                                      onClick={handleEdit}
                                      style={{ cursor: "pointer" }}
                                      id="edit-type"
                                    />
                                  </InputAdornment>
                                ),
                              }
                            : {
                                ...params.InputProps,
                              }
                        }
                        error={
                          formik.touched.type && Boolean(formik.errors.type)
                        }
                        helperText={formik.touched.type && formik.errors.type}
                      />
                    )}
                  />
                </div>
                <TextField
                  id="cif"
                  label={props.edit || props.masterEditor ? "NIF" : "NIF *"}
                  disabled={props.edit}
                  style={{ margin: "1rem", width: "60%" }}
                  className={props.masterEditor ? "margin-left-0" : ""}
                  variant={props.edit ? "standard" : "outlined"}
                  helperText={
                    !props.edit
                      ? "Formato A12345678, sin espacios, ni guiones"
                      : ""
                  }
                  value={formik.values.cif}
                  onChange={formik.handleChange}
                  error={formik.touched.cif && Boolean(formik.errors.cif)}
                />

                <div style={{ display: "flex", alignItems: "center" }}>
                  {props.edit ? (
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        width: "100%",
                      }}
                    >
                      <div style={{ display: "flex" }}>
                        <TextField
                          label="Controlada"
                          variant="standard"
                          disabled
                          style={{ margin: "1rem", width: "40%" }}
                          value={formik.values.controlled ? "Si" : "No"}
                        />
                        <TextField
                          label="Grupo AEDAS Homes"
                          variant="standard"
                          disabled
                          style={{
                            margin: "1rem",
                            width: "50%",
                            flexGrow: "2",
                          }}
                          //
                          value={formik.values.group ? "Si" : "No"}
                        />
                        <TextField
                          label="Auditada"
                          variant="standard"
                          disabled
                          style={{ margin: "1rem", width: "40%" }}
                          value={formik.values.audited ? "Si" : "No"}
                        />
                      </div>

                      <div style={{ display: "flex", width: "100%" }}>
                        {formik.values.audited ? (
                          <>
                            <TextField
                              label="Auditor"
                              variant="standard"
                              disabled
                              style={{
                                margin: "1rem",
                                maxWidth: "100%",
                                flexGrow: "2",
                              }}
                              value={formik.values.auditor}
                            />
                            <TextField
                              label="NIF del auditor"
                              variant="standard"
                              disabled
                              style={{ margin: "1rem", maxWidth: "100%" }}
                              value={formik.values.auditor_nif}
                            />
                            <TextField
                              label="Caduca el"
                              variant="standard"
                              disabled
                              style={{ margin: "1rem", maxWidth: "100%" }}
                              value={
                                formik.values.audited_end != null
                                  ? moment(formik.values.audited_end).format(
                                      "MM/DD/YYYY"
                                    )
                                  : "-"
                              }
                            />
                          </>
                        ) : (
                          <></>
                        )}
                      </div>
                    </div>
                  ) : (
                    <div style={{ display: "flex", width: "100%" }}>
                      <FormControlLabel
                        value={formik.values.controlled}
                        style={{
                          margin: "1rem",
                          flexDirection: "row-reverse",
                          justifyContent: "flex-end",
                        }}
                        className="check-box-label"
                        control={
                          <Switch
                            id="is-controlled"
                            checked={formik.values.controlled}
                            onClick={() =>
                              formik.setFieldValue(
                                "controlled",
                                !formik.values.controlled
                              )
                            }
                          />
                        }
                        label="Controlada"
                      />

                      <FormControlLabel
                        value={formik.values.group}
                        style={{
                          margin: "1rem",
                          flexDirection: "row-reverse",
                          justifyContent: "flex-end",
                        }}
                        className="check-box-label"
                        control={
                          <Switch
                            id="is-group"
                            checked={formik.values.group}
                            onClick={() =>
                              formik.setFieldValue(
                                "group",
                                !formik.values.group
                              )
                            }
                          />
                        }
                        label="Grupo AEDAS Homes"
                      />

                      <FormControlLabel
                        value={formik.values.audited}
                        style={{
                          margin: "1rem",
                          flexDirection: "row-reverse",
                          justifyContent: "flex-end",
                        }}
                        className="check-box-label"
                        control={
                          <Switch
                            id="is-audited"
                            checked={formik.values.audited}
                            onClick={() =>
                              formik.setFieldValue(
                                "audited",
                                !formik.values.audited
                              )
                            }
                          />
                        }
                        label="Auditada"
                      />
                    </div>
                  )}
                </div>
                {formik.values.audited && !props.edit ? (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "strech",
                      flexDirection: "column",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "flex-start",
                      }}
                    >
                      <TextField
                        id="auditor"
                        label="Auditor"
                        className={props.masterEditor ? "margin-left-0" : ""}
                        variant={props.edit ? "standard" : "outlined"}
                        disabled={props.edit}
                        // InputProps={props.edit && !props.report && (isAdmin || isEditor) ? {
                        //   endAdornment: (
                        //     <InputAdornment position="start">
                        //       <BorderColorIcon onClick={handleEdit} style={{cursor: "pointer"}} id="edit-registered-name"/>
                        //     </InputAdornment>
                        //   ),
                        // } : <></> }
                        style={{ margin: "1rem", width: "60%" }}
                        value={formik.values.auditor}
                        onChange={formik.handleChange}
                        error={
                          formik.touched.auditor &&
                          Boolean(formik.errors.auditor)
                        }
                      />

                      <FormControlLabel
                        value={formik.values.auditor_juridical}
                        style={{
                          margin: "1rem",
                          flexDirection: "row-reverse",
                          padding: "0",
                        }}
                        className="check-box-label"
                        control={
                          <Switch
                            id="is-jurirical"
                            checked={formik.values.auditor_juridical}
                            onClick={() =>
                              formik.setFieldValue(
                                "auditor_juridical",
                                !formik.values.auditor_juridical
                              )
                            }
                          />
                        }
                        label="Persona Juridica"
                      />
                    </div>

                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "flex-start",
                      }}
                    >
                      <TextField
                        id="auditor_nif"
                        label="NIF"
                        disabled={props.edit}
                        style={{ margin: "1rem", width: "60%" }}
                        className={props.masterEditor ? "margin-left-0" : ""}
                        variant={props.edit ? "standard" : "outlined"}
                        helperText={
                          !props.edit
                            ? "Formato A12345678, sin espacios, ni guiones"
                            : ""
                        }
                        value={formik.values.auditor_nif}
                        onChange={formik.handleChange}
                        error={
                          formik.touched.auditor_nif &&
                          Boolean(formik.errors.auditor_nif)
                        }
                      />

                      <TextField
                        id="audited_end"
                        label="Caduca el"
                        disabled={props.edit}
                        InputLabelProps={{ shrink: true }}
                        style={{ margin: "1rem", width: "30%" }}
                        variant={props.edit ? "standard" : "outlined"}
                        type={"date"}
                        value={formik.values.audited_end}
                        onChange={formik.handleChange}
                        error={
                          formik.touched.audited_end &&
                          Boolean(formik.errors.audited_end)
                        }
                        helperText={
                          formik.touched.audited_end &&
                          formik.errors.audited_end
                        }
                      />
                    </div>
                  </div>
                ) : (
                  <></>
                )}

                {/* <div style={{display: "flex", flexDirection:`${props.masterEditor ? "column" : "row"}`}}> */}
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <div
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <TextField
                      id="creation_date"
                      label={
                        props.edit || props.masterEditor
                          ? "Fecha de Constitución"
                          : "Fecha de Constitución *"
                      }
                      disabled={props.edit}
                      InputLabelProps={{ shrink: true }}
                      style={{
                        margin: "1rem",
                        width: `${
                          props.masterEditor || props.edit ? "50%" : "60%"
                        }`,
                      }}
                      className={props.masterEditor ? "margin-left-0" : ""}
                      variant={props.edit ? "standard" : "outlined"}
                      type={"date"}
                      value={formik.values.creation_date}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.creation_date &&
                        Boolean(formik.errors.creation_date)
                      }
                      helperText={
                        formik.touched.creation_date &&
                        formik.errors.creation_date
                      }
                    />
                    {props.edit || props.masterEditor ? (
                      <TextField
                        id="end_date"
                        label="Fecha de Extinción"
                        InputLabelProps={{ shrink: true }}
                        style={{ margin: "1rem", width: "50%" }}
                        disabled
                        InputProps={
                          props.edit &&
                          !props.report &&
                          (isAdmin || isEditor) ? (
                            {
                              endAdornment: (
                                <InputAdornment position="start">
                                  <BorderColorIcon
                                    onClick={handleEdit}
                                    style={{ cursor: "pointer" }}
                                    id="edit-end"
                                  />
                                </InputAdornment>
                              ),
                            }
                          ) : (
                            <></>
                          )
                        }
                        variant={props.edit ? "standard" : "outlined"}
                        type={"date"}
                        value={formik.values.end_date}
                        onChange={formik.handleChange}
                        error={
                          formik.touched.end_date &&
                          Boolean(formik.errors.end_date)
                        }
                        helperText={
                          formik.touched.end_date && formik.errors.end_date
                        }
                      />
                    ) : (
                      <></>
                    )}
                  </div>

                  <div
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    {!props.edit ? (
                      <TextField
                        id="files"
                        label={
                          props.edit || props.masterEditor
                            ? "Doc. de constitución"
                            : "Doc. de constitución *"
                        }
                        variant={props.edit ? "standard" : "outlined"}
                        className={props.masterEditor ? "margin-left-0" : ""}
                        inputProps={{
                          // multiple: true,
                          accept: "application/pdf",
                        }}
                        // style={{marginLeft: "0 important!"}}
                        style={{ margin: "1rem", width: "60%" }}
                        InputLabelProps={{ shrink: true }}
                        type={"file"}
                        onChange={(event: any) => {
                          handleUploadFiles(
                            event.currentTarget.files[0],
                            event
                          );
                        }}
                        // value={formik.values.files}
                        // onChange={formik.handleChange}
                        error={
                          formik.touched.files && Boolean(formik.errors.files)
                        }
                        helperText={formik.touched.files && formik.errors.files}
                      />
                    ) : (
                      <></>
                    )}

                    {props.edit || (props.masterEditor && !props.report) ? (
                      <Button
                        className={
                          props.masterEditor
                            ? "button blue-button document-download margin-right-0"
                            : "button blue-button document-download"
                        }
                        onClick={handleOpenFile}
                      >
                        Ver Escrituras
                      </Button>
                    ) : (
                      <></>
                    )}
                  </div>
                </div>

                <div
                  className={
                    props.masterEditor ? "grey-box margin-left-0" : "grey-box"
                  }
                >
                  <h4>Datos notariales y Registrales</h4>
                  <div style={{ display: "flex" }}>
                    <TextField
                      id="protocol_number"
                      label={"Nº Protocolo"}
                      disabled={props.edit}
                      style={{
                        backgroundColor: "#d8d8d8",
                        width: "50%",
                        margin: "1rem",
                      }}
                      variant={props.edit ? "standard" : "outlined"}
                      value={formik.values.protocol_number}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.protocol_number &&
                        Boolean(formik.errors.protocol_number)
                      }
                      helperText={
                        formik.touched.protocol_number &&
                        formik.errors.protocol_number
                      }
                    />
                    <TextField
                      id="date_protocol"
                      label={"Fecha Escritura"}
                      disabled={props.edit}
                      style={{
                        backgroundColor: "#d8d8d8",
                        width: "50%",
                        margin: "1rem",
                      }}
                      InputLabelProps={{ shrink: true }}
                      variant={props.edit ? "standard" : "outlined"}
                      type={"date"}
                      value={formik.values.date_protocol}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.date_protocol &&
                        Boolean(formik.errors.date_protocol)
                      }
                      helperText={
                        formik.touched.date_protocol &&
                        formik.errors.date_protocol
                      }
                    />
                  </div>
                  <div style={{ display: "flex" }}>
                    <TextField
                      id="notary_name"
                      label={"Notario"}
                      disabled={props.edit}
                      style={{
                        backgroundColor: "#d8d8d8",
                        width: "50%",
                        margin: "1rem",
                      }}
                      variant={props.edit ? "standard" : "outlined"}
                      value={formik.values.notary_name}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.notary_name &&
                        Boolean(formik.errors.notary_name)
                      }
                      helperText={
                        formik.touched.notary_name && formik.errors.notary_name
                      }
                    />
                    <TextField
                      id="registration_info"
                      label={"Datos Registro"}
                      disabled={props.edit}
                      style={{
                        backgroundColor: "#d8d8d8",
                        width: "50%",
                        margin: "1rem",
                      }}
                      variant={props.edit ? "standard" : "outlined"}
                      value={formik.values.registration_info}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.registration_info &&
                        Boolean(formik.errors.registration_info)
                      }
                      helperText={
                        formik.touched.registration_info &&
                        formik.errors.registration_info
                      }
                    />
                  </div>
                </div>
              </div>

              <div style={{ width: "50%", textAlign: "right" }}>
                <TextField
                  id="corporate_purpose"
                  label={
                    props.edit || props.masterEditor
                      ? "Objeto Social"
                      : "Objeto Social *"
                  }
                  fullWidth
                  disabled={props.edit}
                  InputProps={
                    props.edit && !props.report && (isAdmin || isEditor) ? (
                      {
                        endAdornment: (
                          <InputAdornment position="start">
                            <BorderColorIcon
                              onClick={handleEdit}
                              style={{ cursor: "pointer" }}
                              id="edit-corporate-purpose"
                            />
                          </InputAdornment>
                        ),
                      }
                    ) : (
                      <></>
                    )
                  }
                  style={{ maxWidth: "90%", margin: "1rem" }}
                  className={props.masterEditor ? "margin-right-0" : ""}
                  variant={props.edit ? "standard" : "outlined"}
                  value={formik.values.corporate_purpose}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.corporate_purpose &&
                    Boolean(formik.errors.corporate_purpose)
                  }
                  helperText={
                    formik.touched.corporate_purpose &&
                    formik.errors.corporate_purpose
                  }
                />
                <div
                  style={{
                    maxWidth: "90%",
                    margin: `${
                      props.masterEditor
                        ? "1rem 0 1rem auto"
                        : "1rem 1rem 1rem auto"
                    }`,
                    display: "flex",
                  }}
                >
                  {console.log(props.edit)}
                  <AddressAutofill
                    accessToken={`${process.env.REACT_APP_MAPBOX_TOKEN}`}
                    options={{
                      language: "es",
                      // country: 'ES',
                      limit: 10,
                    }}
                  >
                    <TextField
                      id="full_address"
                      label={
                        props.edit || props.masterEditor
                          ? "Domicilio social"
                          : "Domicilio social *"
                      }
                      disabled={props.edit}
                      InputProps={
                        props.edit && !props.report && (isAdmin || isEditor) ? (
                          {
                            endAdornment: (
                              <InputAdornment position="start">
                                <BorderColorIcon
                                  style={{ cursor: "pointer" }}
                                  onClick={handleEdit}
                                  id="edit-address"
                                />
                              </InputAdornment>
                            ),
                          }
                        ) : (
                          <></>
                        )
                      }
                      fullWidth
                      style={{ width: "100%" }}
                      autoComplete="address-line1"
                      className={props.masterEditor ? "margin-right-0" : ""}
                      variant={props.edit ? "standard" : "outlined"}
                      value={formik.values.full_address}
                      onChange={(e) => {
                        formik.setFieldValue("full_address", e.target.value);
                      }}
                      error={
                        formik.touched.full_address &&
                        Boolean(formik.errors.full_address)
                      }
                      helperText={
                        formik.touched.full_address &&
                        formik.errors.full_address
                      }
                    />
                  </AddressAutofill>
                </div>
                <div
                  style={{
                    maxWidth: "90%",
                    margin: `${
                      props.masterEditor
                        ? "1rem 0 1rem auto"
                        : "1rem 1rem 1rem auto"
                    }`,
                    display: "flex",
                  }}
                >
                  <TextField
                    id="postal_code"
                    label={props.edit || props.masterEditor ? "CP" : "CP *"}
                    disabled={props.edit}
                    style={{ margin: "1rem 2rem 1rem 0" }}
                    variant={props.edit ? "standard" : "outlined"}
                    value={formik.values.postal_code}
                    onChange={formik.handleChange}
                    autoComplete="postal-code"
                    error={
                      formik.touched.postal_code &&
                      Boolean(formik.errors.postal_code)
                    }
                    helperText={
                      formik.touched.postal_code && formik.errors.postal_code
                    }
                  />
                  <TextField
                    id="town"
                    label={
                      props.edit || props.masterEditor
                        ? "Localidad"
                        : "Localidad *"
                    }
                    disabled={props.edit}
                    style={{
                      flexGrow: "1",
                      width: "50%",
                      margin: "1rem 2rem 1rem 0",
                    }}
                    variant={props.edit ? "standard" : "outlined"}
                    autoComplete="address-level2"
                    value={formik.values.town}
                    onChange={formik.handleChange}
                    error={formik.touched.town && Boolean(formik.errors.town)}
                    helperText={formik.touched.town && formik.errors.town}
                  />
                  <TextField
                    id="province"
                    label={
                      props.edit || props.masterEditor
                        ? "Provincia"
                        : "Provincia *"
                    }
                    disabled={props.edit}
                    style={{ margin: "1rem 0" }}
                    variant={props.edit ? "standard" : "outlined"}
                    value={formik.values.province}
                    autoComplete="address-level1"
                    onChange={handleProvince}
                    // onChange={formik.handleChange}
                    error={
                      formik.touched.province && Boolean(formik.errors.province)
                    }
                    helperText={
                      formik.touched.province && formik.errors.province
                    }
                  />
                </div>
                <TextField
                  id="country"
                  label={props.edit || props.masterEditor ? "País" : "País *"}
                  fullWidth
                  disabled={props.edit}
                  autoComplete="country-name"
                  InputProps={
                    props.edit && !props.report && (isAdmin || isEditor) ? (
                      {
                        endAdornment: (
                          <InputAdornment position="start">
                            <BorderColorIcon
                              style={{ cursor: "pointer" }}
                              onClick={handleEdit}
                              id="edit-address-international"
                            />
                          </InputAdornment>
                        ),
                      }
                    ) : (
                      <></>
                    )
                  }
                  style={{ maxWidth: "90%", margin: "0 1rem 2rem" }}
                  className={props.masterEditor ? "margin-right-0" : ""}
                  variant={props.edit ? "standard" : "outlined"}
                  value={formik.values.country}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.country && Boolean(formik.errors.country)
                  }
                  helperText={formik.touched.country && formik.errors.country}
                />
                <div>
                  <TextField
                    id="comments"
                    label="Anotaciones"
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                    multiline={!props.edit}
                    minRows={formik.values.audited ? 3 : 3}
                    style={{ maxWidth: "90%", margin: "0 1rem 1rem" }}
                    className={props.masterEditor ? "margin-right-0" : ""}
                    variant={props.edit ? "standard" : "outlined"}
                    disabled={props.edit}
                    InputProps={
                      props.edit && !props.report && (isAdmin || isEditor) ? (
                        {
                          endAdornment: (
                            <InputAdornment position="start">
                              <BorderColorIcon
                                style={{ cursor: "pointer" }}
                                onClick={handleEdit}
                                id="edit-comments"
                              />
                            </InputAdornment>
                          ),
                        }
                      ) : (
                        <></>
                      )
                    }
                    value={formik.values.comments}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.comments && Boolean(formik.errors.comments)
                    }
                    helperText={
                      formik.touched.comments && formik.errors.comments
                    }
                  />
                </div>
              </div>
            </div>
            {!props.edit ? (
              <div className="button-container new-corporate">
                {props.masterEditor && isAdmin && props.corporate?.activated ? (
                  <Button
                    onClick={() => setOpenDeleteModal(true)}
                    color="primary"
                    className="button reject-button"
                  >
                    Borrar
                  </Button>
                ) : (
                  <></>
                )}

                <LoadingButton
                  type="submit"
                  loading={isLoadingButton}
                  loadingPosition="start"
                  variant="contained"
                  color="primary"
                  className={`button blue-button ${
                    isLoadingButton ? "is-loading" : ""
                  }`}
                >
                  {isLoadingButton
                    ? "Enviando…"
                    : props.masterEditor
                    ? "Actualizar"
                    : !isLoadingButton && newCorporateCreated != null
                    ? "Volver"
                    : "Crear sociedad"}
                </LoadingButton>

                {!isLoadingButton && newCorporateCreated != null ? (
                  <Button
                    className="button blue-button"
                    onClick={handleNextStep}
                  >
                    Siguiente
                  </Button>
                ) : (
                  <></>
                )}

                {props.masterEditor && nextStep !== "" ? (
                  <Button
                    id="alta-organo-societario"
                    className="button blue-button"
                    onClick={handleNextStep}
                  >
                    Completar información
                  </Button>
                ) : (
                  <></>
                )}
              </div>
            ) : (
              <></>
            )}
          </Box>

          <CustomModal
            size="l"
            show={editModalShow}
            onChange={() => {
              setEditModalShow(!editModalShow);
            }}
            title={modalTitle}
            body={
              <ModalBody
                formik={formik}
                handleUploadFiles={handleUploadFiles}
                modalTitle={modalTitle}
                corporateTypes={corporateTypes}
              />
            }
          />

          <CustomModal
            size="lg"
            show={openDeleteModal}
            onChange={() => {
              setOpenDeleteModal(!openDeleteModal);
            }}
            title="Confirmar borrado"
            body={
              <div>
                <div className="d-flex justify-content-center">
                  <h5>Confirmo el borrado esta sociedad</h5>
                </div>
                <div className="d-flex justify-content-center">
                  <Button
                    onClick={() => handleDelete()}
                    className="button reject-button"
                  >
                    Confirmar borrado
                  </Button>
                </div>
              </div>
            }
          />
        </>
      )}
    </>
  );
};

const ModalBody = (props: any) => {
  const { formik, handleUploadFiles, modalTitle, corporateTypes } = props;

  return (
    <div style={{ textAlign: "center" }}>
      {modalTitle === "Modificación de la denominación social" ? (
        <TextField
          id="registered_name"
          label="Denominación Social *"
          variant="outlined"
          helperText="Nombre de la sociedad sin abreviatura de tipo de sociedad"
          style={{ margin: "1rem", width: "60%" }}
          value={formik.values.registered_name}
          onChange={formik.handleChange}
          error={
            formik.touched.registered_name &&
            Boolean(formik.errors.registered_name)
          }
        />
      ) : modalTitle === "Transformación social" ? (
        <Autocomplete
          id="type"
          className="autocomplete coporate"
          options={corporateTypes}
          // getOptionLabel={(option: any) => option.types}
          onChange={(e, value) => {
            formik.setFieldValue("type", value);
          }}
          value={formik.values.type}
          renderInput={(params) => (
            <TextField
              {...params}
              style={{ margin: "1rem", width: "60%" }}
              label="Tipo *"
              error={formik.touched.type && Boolean(formik.errors.type)}
              helperText={formik.touched.type && formik.errors.type}
            />
          )}
        />
      ) : modalTitle === "Registrar Disolución" ? (
        <TextField
          id="end_date"
          label="Fecha de Extinción *"
          InputLabelProps={{ shrink: true }}
          style={{ margin: "1rem", width: "60%" }}
          variant="outlined"
          type={"date"}
          value={formik.values.end_date}
          onChange={formik.handleChange}
          error={formik.touched.end_date && Boolean(formik.errors.end_date)}
          helperText={formik.touched.end_date && formik.errors.end_date}
        />
      ) : modalTitle === "Modificación del objeto social" ? (
        <TextField
          id="corporate_purpose"
          label="Objeto Social *"
          fullWidth
          style={{ maxWidth: "90%", margin: "1rem" }}
          variant="outlined"
          value={formik.values.corporate_purpose}
          onChange={formik.handleChange}
          error={
            formik.touched.corporate_purpose &&
            Boolean(formik.errors.corporate_purpose)
          }
          helperText={
            formik.touched.corporate_purpose && formik.errors.corporate_purpose
          }
        />
      ) : modalTitle === "Modificación del domicilio social" ? (
        <>
          <div style={{ maxWidth: "90%", margin: "1rem", display: "flex" }}>
            <TextField
              id="full_address"
              label="Domicilio social *"
              fullWidth
              variant="outlined"
              value={formik.values.full_address}
              onChange={formik.handleChange}
              error={
                formik.touched.full_address &&
                Boolean(formik.errors.full_address)
              }
              helperText={
                formik.touched.full_address && formik.errors.full_address
              }
            />
          </div>
          <div style={{ maxWidth: "90%", margin: "1rem", display: "flex" }}>
            <TextField
              id="postal_code"
              label="CP *"
              disabled={props.edit}
              style={{ margin: "1rem 2rem 1rem 0" }}
              variant="outlined"
              value={formik.values.postal_code}
              onChange={formik.handleChange}
              error={
                formik.touched.postal_code && Boolean(formik.errors.postal_code)
              }
              helperText={
                formik.touched.postal_code && formik.errors.postal_code
              }
            />
            <TextField
              id="town"
              label="Localidad *"
              disabled={props.edit}
              style={{
                flexGrow: "1",
                width: "50%",
                margin: "1rem 2rem 1rem 0",
              }}
              variant="outlined"
              value={formik.values.town}
              onChange={formik.handleChange}
              error={formik.touched.town && Boolean(formik.errors.town)}
              helperText={formik.touched.town && formik.errors.town}
            />
            <TextField
              id="province"
              label="Provincia *"
              disabled={props.edit}
              style={{ margin: "1rem 0" }}
              variant="outlined"
              value={formik.values.province}
              onChange={formik.handleChange}
              error={formik.touched.province && Boolean(formik.errors.province)}
              helperText={formik.touched.province && formik.errors.province}
            />
          </div>
        </>
      ) : modalTitle === "Redomiciliación internacional" ? (
        <>
          <div style={{ maxWidth: "90%", margin: "1rem", display: "flex" }}>
            <TextField
              id="full_address"
              label="Dirección *"
              fullWidth
              variant="outlined"
              value={formik.values.full_address}
              onChange={formik.handleChange}
              error={
                formik.touched.full_address &&
                Boolean(formik.errors.full_address)
              }
              helperText={
                formik.touched.full_address && formik.errors.full_address
              }
            />
          </div>
          <div style={{ maxWidth: "90%", margin: "1rem", display: "flex" }}>
            <TextField
              id="postal_code"
              label="CP *"
              disabled={props.edit}
              style={{ margin: "1rem 2rem 1rem 0" }}
              variant="outlined"
              value={formik.values.postal_code}
              onChange={formik.handleChange}
              error={
                formik.touched.postal_code && Boolean(formik.errors.postal_code)
              }
              helperText={
                formik.touched.postal_code && formik.errors.postal_code
              }
            />
            <TextField
              id="town"
              label="Localidad *"
              disabled={props.edit}
              style={{
                flexGrow: "1",
                width: "50%",
                margin: "1rem 2rem 1rem 0",
              }}
              variant="outlined"
              value={formik.values.town}
              onChange={formik.handleChange}
              error={formik.touched.town && Boolean(formik.errors.town)}
              helperText={formik.touched.town && formik.errors.town}
            />
            <TextField
              id="province"
              label="Provincia *"
              disabled={props.edit}
              style={{ margin: "1rem 0" }}
              variant="outlined"
              value={formik.values.province}
              onChange={formik.handleChange}
              error={formik.touched.province && Boolean(formik.errors.province)}
              helperText={formik.touched.province && formik.errors.province}
            />
          </div>
          <TextField
            id="country"
            label="País *"
            fullWidth
            style={{ maxWidth: "90%", margin: "0 1rem 2rem" }}
            variant="outlined"
            value={formik.values.country}
            onChange={formik.handleChange}
            error={formik.touched.country && Boolean(formik.errors.country)}
            helperText={formik.touched.country && formik.errors.country}
          />
        </>
      ) : (
        <TextField
          id="comments"
          label="Anotaciones"
          fullWidth
          style={{ maxWidth: "90%", margin: "0 1rem" }}
          variant="outlined"
          value={formik.values.comments}
          onChange={formik.handleChange}
          error={formik.touched.comments && Boolean(formik.errors.comments)}
          helperText={formik.touched.comments && formik.errors.comments}
        />
      )}

      {modalTitle !== "Modificar anotaciones" ? (
        <>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {modalTitle !== "Registrar Disolución" ? (
              <TextField
                id="date_registration"
                label="Fecha del Hito"
                className="milestone-date"
                InputLabelProps={{ shrink: true }}
                style={{ margin: "1rem", width: "50%" }}
                variant="outlined"
                type={"date"}
                value={formik.values.date_registration}
                onChange={formik.handleChange}
                error={
                  formik.touched.date_registration &&
                  Boolean(formik.errors.date_registration)
                }
                helperText={
                  formik.touched.date_registration &&
                  formik.errors.date_registration
                }
              />
            ) : (
              <div style={{ width: "0" }}></div>
            )}

            <TextField
              id="files"
              label="Doc. asociado"
              variant="outlined"
              inputProps={{
                // multiple: true,
                accept: "application/pdf",
              }}
              style={{ margin: "1rem", width: "60%" }}
              InputLabelProps={{ shrink: true }}
              type={"file"}
              onChange={(event: any) => {
                handleUploadFiles(event.currentTarget.files[0], event);
              }}
              // value={formik.values.files}
              // onChange={formik.handleChange}
              error={formik.touched.files && Boolean(formik.errors.files)}
              helperText={formik.touched.files && formik.errors.files}
            />
          </div>

          <p>
            La modificación de estos datos constituye el Hito Societario{" "}
            {modalTitle === "Registrar Disolución" ? "Disolución" : modalTitle}.
          </p>
        </>
      ) : (
        <></>
      )}

      <Button
        onClick={formik.handleSubmit}
        color="primary"
        className="button blue-button"
      >
        Guardar cambios
      </Button>
    </div>
  );
};
