import React from 'react'
import moment from "moment";


import { GridColDef, GridRenderEditCellParams, GridValueGetterParams } from "@mui/x-data-grid";

import { TabListComponent } from '../../../../Shared/TabsListComponent/TabListComponent';
import { useFetch, useIsLoading } from '../../../../../Hooks/useFetch';
import { getCapitalByCorpID } from '../../../../../Services/CorporateServices/CorporateServices';


import './../InfoCorporate.css';

export const Capital = (props: any) => {
	const [capital, isFetchingCapital] = useFetch(getCapitalByCorpID, props.corporate.id_corporation);
    console.log(props);

		
		const columns: GridColDef[] = [
			{
				field: "id_capital",
				headerName: "ID",
				width: 9,
				hide: true,
				editable: false,
			},
			{
				field: "description",
				headerName: "Descripción",
				type: "string",
				editable: false,
				flex: 1,
			},
			{
				field: "nominal_value",
				headerName: "Valor Nominal",
				type: "string",
				renderCell: (params: GridRenderEditCellParams) => {
          return <div>{ params.row.currency === "EUR" 
					? new Intl.NumberFormat('es-ES', { style: 'currency', currency: 'EUR', useGrouping: true }).format(params.row.nominal_value) 
					: `${params.row.nominal_value} ${params.row.nominal_value.currency}` }</div>;
      	},
				editable: false,
				flex: 1,
			},
			{
				field: "currency",
				headerName: "Divisa",
				type: "string",
				editable: false,
				flex: 1,
			},
			{
				field: "shares_issued",
				headerName: "Nº de títulos emitidos",
				type: "string",
				editable: false,
				flex: 1,
			},
		]
	
    return (
			<>
				{
					isFetchingCapital ? <></> : (
						<TabListComponent 
							corporate={props.corporate}
							title={'Títulos Emitidos'}    
							capital={capital ? capital[0].total_capital_issued : false}
							columns={columns}
							rows={capital}
							getRowId={(row: any) => row.id_capital}
						/>
					)
				}
			</>
    )
}
