import { useEffect, useState } from "react";
import moment from "moment";
import { toast } from "react-toastify";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";

import { useFormik } from "formik";
import * as yup from "yup";

import Box from "@mui/material/Box";
import { TextField, Autocomplete, InputAdornment } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import { useFetch, useIsLoading } from "../../Hooks/useFetch";

import AedasLoading from "../../Components/Shared/AedasLoading/AedasLoading";
import { getAllCorporates } from "../../Services/CorporateServices/CorporateServices";
import {
  getCFAById,
  addCFA,
  updateCFA,
} from "../../Services/CFAServices/CFAServices";

import "../../Components/Static/Corporate/CorporateMain.css";

export const CFAInputs = (props: any) => {
  const validationSchema = yup.object({
    creditor: yup
      .object()
      .typeError("Campo obligatorio")
      .required("Campo obligatorio"),
    debtor: yup
      .object()
      .typeError("Campo obligatorio")
      .required("Campo obligatorio"),
    signature_date: yup
      .date()
      .typeError("Campo obligatorio")
      .required("Campo obligatorio"),
    effective_date: yup
      .date()
      .typeError("Campo obligatorio")
      .required("Campo obligatorio"),
    repayment_date: yup
      .date()
      .typeError("Campo obligatorio")
      .required("Campo obligatorio"),
    credit_facility_maximum_amount: yup
      .number()
      .min(0, "Valor mínimo 0")
      .typeError("Campo obligatorio")
      .required("Campo obligatorio"),
    credit_facility_disposals: yup
      .number()
      .min(0, "Valor mínimo 0")
      .typeError("Campo obligatorio")
      .required("Campo obligatorio"),
    rate_type: yup
      .string()
      .min(1, "Campo obligatorio")
      .typeError("Campo obligatorio")
      .required("Campo obligatorio"),
    rate: yup
      .number()
      .min(0, "Valor mínimo 0")
      .max(100, "Valor máximo 100")
      .typeError("Campo obligatorio")
      .required("Campo obligatorio"),
    comments: yup.string().nullable(),
    document_cfa: !props.edit
      ? yup
          .string()
          .typeError("Campo obligatorio")
          .required("Campo obligatorio")
      : yup.object().nullable(),
  });

  const params: any = useParams();
  const navigate: any = useNavigate();

  const [allCorporates, isFetchingCorporates] = useFetch(getAllCorporates);
  const [cfa, isFetchingCfa] = useFetch(getCFAById, params);
  const [selectedRow, setSelectedRow] = useState<any>();
  const [activatedCorporates, setActivatedCorporates] = useState<any>(null);
  const [filesAttached, setFilesAttached] = useState<any[]>([]);

  const statusOptions = [
    { status: true, name: "Activo" },
    { status: false, name: "Terminado" },
  ];
  const isLoading = useIsLoading([isFetchingCorporates, isFetchingCfa]);
  const [isLoadingButton, setIsLoadingButton] = useState(false);
  const [editedField, setEditedField] = useState(false);

  const userId = useSelector(
    (state: any) => state.userReducer.user.employee.id
  );
  const isNewCFA = window.location.pathname.includes("nuevo") ?? false;

  useEffect(() => {
    if (!isLoading) {
      setSelectedRow(cfa);
      const activatedCorporates = allCorporates.filter(
        (corporate: any) => corporate.activated === true
      );

      if (
        isNewCFA &&
        (allCorporates == null || activatedCorporates.length < 2)
      ) {
        alert(
          "No se podrán crear nuevos CFAs, sin tener al menos dos sociedades dadas de alta."
        );
        navigate("/cfa");
      }

      setActivatedCorporates(activatedCorporates);
    }
  }, [isLoading]);

  const formik = useFormik({
    initialValues: {
      status:
        props.edit && selectedRow
          ? statusOptions.find(
              (option: any) => option.status == selectedRow.status
            )
          : null,
      creditor:
        props.edit && selectedRow
          ? activatedCorporates.find(
              (corporate: any) =>
                corporate.id_corporation == selectedRow.creditor
            )
          : null,
      debtor:
        props.edit && selectedRow
          ? activatedCorporates.find(
              (corporate: any) => corporate.id_corporation == selectedRow.debtor
            )
          : null,
      signature_date:
        props.edit && selectedRow
          ? moment(selectedRow?.signature_date).format("yyyy-MM-DD")
          : null,
      effective_date:
        props.edit && selectedRow && selectedRow.effective_date !== null
          ? moment(selectedRow?.effective_date).format("yyyy-MM-DD")
          : null,
      repayment_date:
        props.edit && selectedRow && selectedRow.repayment_date !== null
          ? moment(selectedRow?.repayment_date).format("yyyy-MM-DD")
          : null,
      termination_date:
        props.edit && selectedRow && selectedRow.termination_date !== null
          ? moment(selectedRow?.termination_date).format("yyyy-MM-DD")
          : null,
      credit_facility_maximum_amount:
        props.edit && selectedRow
          ? selectedRow.credit_facility_maximum_amount
          : null,
      credit_facility_disposals:
        props.edit && selectedRow
          ? selectedRow.credit_facility_disposals
          : null,
      credit_facility_available_amount:
        props.edit && selectedRow
          ? selectedRow.credit_facility_available_amount
          : null,
      rate: props.edit && selectedRow ? selectedRow.rate : null,
      rate_type: props.edit && selectedRow ? selectedRow.rate_type : null,
      earned_interests:
        props.edit && selectedRow ? selectedRow.earned_interests : 0,
      period_interests:
        props.edit && selectedRow ? selectedRow.period_interests : 0,
      comments: props.edit && selectedRow ? selectedRow.comments : null,
      document_cfa: null,
      document_amendment: [],
      document_termination: null,
      document_cession: null,
      amendment_date: null,
    },

    validationSchema: validationSchema,
    enableReinitialize: true,

    onSubmit: async (values: any) => {
      console.log(values);

      const cfaAvailableAmount =
        parseFloat(values.credit_facility_maximum_amount) -
        parseFloat(values.credit_facility_disposals);

      if (cfaAvailableAmount < 0) {
        formik.setFieldError(
          "credit_facility_available_amount",
          "Valor negativo no posible, Revisa los importes ingresados."
        );
        return;
      }

      setIsLoadingButton(true);

      if (props.amendment) {
        const message = "Campo obligatorio para editar ";

        if (
          (!values.status.status &&
            !selectedRow.document_termination?.id &&
            !values.termination_date) ||
          (!values.status.status &&
            !selectedRow.document_termination?.id &&
            values.document_termination == null)
        ) {
          if (!values.termination_date) {
            formik.setFieldError("termination_date", message + "estado.");
          }

          if (values.document_termination == null) {
            formik.setFieldError("document_termination", message + "estado.");
          }

          setIsLoadingButton(false);
          return false;
        }

        if (
          (selectedRow.debtor != values.debtor.id_corporation &&
            values.document_cession == null) ||
          (selectedRow.creditor != values.creditor.id_corporation &&
            values.document_cession == null)
        ) {
          formik.setFieldError(
            "document_cession",
            message + "acreedor y/o deudor."
          );
          setIsLoadingButton(false);
          return false;
        }

        if (
          (editedField && values.document_amendment.length <= 1) ||
          (editedField && !values.amendment_date)
        ) {
          if (values.document_amendment.length <= 1) {
            formik.setFieldError("document_amendment", message + "CFA.");
          }

          if (!values.amendment_date) {
            formik.setFieldError("amendment_date", message + "CFA.");
          }
          setIsLoadingButton(false);
          return false;
        }
      }

      const filesToSend = filesAttached.map((item: any) => {
        let document: any = {};
        let content = {
          name: item.file.name[0],
          document: item.file.document[0]
            .replace("data:", "")
            .replace(/^.+,/, ""),
        };

        document[item.type] = content;

        return document;
      });

      filesToSend.forEach((item: any) => {
        const key = Object.keys(item)[0];

        for (const value in values) {
          if (value === key) {
            if (key === "document_amendment") {
              let documentObject: any = Object.values(item)[0];
              documentObject.date_registration =
                formik.values.amendment_date + "T00:00";
              values[key] = new Array(documentObject);
            } else values[key] = Object.values(item)[0];
          }
        }
      });

      const body = {
        ...values,
        id_cfa: props.edit ? params.cfa_id : null,
        id_employee: userId,
        status: props.edit ? values.status.status : true,
        creditor: values.creditor.id_corporation,
        debtor: values.debtor.id_corporation,
        credit_facility_available_amount: cfaAvailableAmount,
        signature_date: values.signature_date
          ? values.signature_date + "T00:00"
          : null,
        effective_date: values.effective_date
          ? values.effective_date + "T00:00"
          : null,
        repayment_date: values.repayment_date
          ? values.repayment_date + "T00:00"
          : null,
        termination_date: values.termination_date
          ? values.termination_date + "T00:00"
          : null,
      };

      if (props.edit) {
        updateCFA(body)
          .then((response: any) => {
            if (response.status == 200) {
              toast.success("CFA Actualizado con éxito");
              navigate("/cfa");
            }
          })
          .then(() => setIsLoadingButton(false));
      } else {
        addCFA(body)
          .then((response: any) => {
            if (response.status == 200) {
              toast.success("Guardado");
              navigate("/cfa");
            }
          })
          .then(() => setIsLoadingButton(false));
      }
    },
  });

  const handleChangeValue = (e: any) => {
    formik.setFieldValue(e.target.id, e.target.value);
    setEditedField(true);
  };

  const handleUploadFiles = (files: any, event: any) => {
    let filesUploadedList = event.currentTarget.files;
    let fileListBase64: any[] = [];
    let fileNameList: any[] = [];

    Object.values(filesUploadedList).forEach((file: any) => {
      fileNameList.push(file.name);

      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        let fileBase64 = reader.result?.toString() || "";
        fileListBase64.push(fileBase64);
      };
    });

    formik.handleChange(event);
    setFilesAttached([
      ...filesAttached,
      {
        type: event.target.id,
        file: { name: fileNameList, document: fileListBase64 },
      },
    ]);
  };

  return (
    <>
      {isLoading ? (
        <AedasLoading></AedasLoading>
      ) : (
        <>
          <div className="h-100 m-4">
            <div className="main-container p-3">
              <h1 className="main-title">{`${
                props.edit ? "Modificar" : "Nuevo"
              } Acuerdo de Línea de Crédito`}</h1>

              <Box
                component="form"
                id="corporate-form"
                sx={{
                  width: "100%",
                  mx: "auto",
                  my: 2,
                }}
                noValidate
                autoComplete="on"
                onSubmit={formik.handleSubmit}
              >
                {props.edit ? (
                  <div
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <Autocomplete
                      id="status"
                      options={statusOptions ?? []}
                      getOptionLabel={(option: any) => option.name}
                      disabled={props.monthly}
                      onChange={(e, value) => {
                        formik.setFieldValue("status", value);
                      }}
                      isOptionEqualToValue={(option: any, value: any) =>
                        option.status === value.status
                      }
                      style={{ width: "50%", margin: "1rem" }}
                      value={formik.values.status}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Estado"
                          error={
                            formik.touched.status &&
                            Boolean(formik.errors.status)
                          }
                          helperText={
                            formik.touched.status && formik.errors.status
                          }
                        />
                      )}
                    />
                    {!formik.values.status?.status ? (
                      <>
                        <TextField
                          id="termination_date"
                          label="Fecha de Finalización"
                          InputLabelProps={{ shrink: true }}
                          style={{ margin: "1rem", width: "50%" }}
                          variant="outlined"
                          type={"date"}
                          disabled={
                            props.edit && !selectedRow?.status ? true : false
                          }
                          value={formik.values.termination_date}
                          onChange={formik.handleChange}
                          error={
                            formik.touched.termination_date &&
                            Boolean(formik.errors.termination_date)
                          }
                          helperText={
                            formik.touched.termination_date &&
                            formik.errors.termination_date
                          }
                        />

                        <TextField
                          id="document_termination"
                          label={"Acuerdo de Finalización"}
                          variant={"outlined"}
                          disabled={
                            props.edit && !selectedRow?.status ? true : false
                          }
                          inputProps={{
                            // multiple: true,
                            accept: "application/pdf",
                          }}
                          style={{ margin: "1rem", width: "50%" }}
                          InputLabelProps={{ shrink: true }}
                          type={"file"}
                          onChange={(event: any) => {
                            handleUploadFiles(
                              event.currentTarget.files[0],
                              event
                            );
                          }}
                          error={
                            formik.touched.document_termination &&
                            Boolean(formik.errors.document_termination)
                          }
                          helperText={
                            formik.touched.document_termination &&
                            formik.errors.document_termination
                          }
                        />
                      </>
                    ) : (
                      <></>
                    )}
                  </div>
                ) : (
                  <></>
                )}

                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <Autocomplete
                    id="creditor"
                    options={
                      activatedCorporates
                        ? activatedCorporates.filter(
                            (corporate: any) =>
                              corporate.id_corporation !==
                              formik.values.debtor?.id_corporation
                          )
                        : []
                    }
                    getOptionLabel={(option: any) => option.registered_name}
                    disabled={props.monthly}
                    onChange={(e, value) => {
                      formik.setFieldValue("creditor", value);
                    }}
                    isOptionEqualToValue={(option: any, value: any) =>
                      option.id === value.id
                    }
                    style={{ width: "50%", margin: "1rem" }}
                    value={formik.values.creditor}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Acreedor"
                        error={
                          formik.touched.creditor &&
                          Boolean(formik.errors.creditor)
                        }
                        helperText={
                          formik.touched.creditor && formik.errors.creditor
                        }
                      />
                    )}
                  />

                  <Autocomplete
                    id="debtor"
                    options={
                      activatedCorporates
                        ? activatedCorporates.filter(
                            (corporate: any) =>
                              corporate.id_corporation !==
                              formik.values.creditor?.id_corporation
                          )
                        : []
                    }
                    getOptionLabel={(option: any) => option.registered_name}
                    disabled={props.monthly}
                    onChange={(e, value) => {
                      formik.setFieldValue("debtor", value);
                    }}
                    isOptionEqualToValue={(option: any, value: any) =>
                      option.id === value.id
                    }
                    style={{ width: "50%", margin: "1rem" }}
                    value={formik.values.debtor}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Deudor"
                        error={
                          formik.touched.debtor && Boolean(formik.errors.debtor)
                        }
                        helperText={
                          formik.touched.debtor && formik.errors.debtor
                        }
                      />
                    )}
                  />
                  {props.edit && !props.monthly ? (
                    <TextField
                      id="document_cession"
                      label={"Acuerdo de Cesión"}
                      variant={"outlined"}
                      inputProps={{
                        // multiple: true,
                        accept: "application/pdf",
                      }}
                      style={{ margin: "1rem", width: "50%" }}
                      InputLabelProps={{ shrink: true }}
                      type={"file"}
                      onChange={(event: any) => {
                        handleUploadFiles(event.currentTarget.files[0], event);
                      }}
                      error={
                        formik.touched.document_cession &&
                        Boolean(formik.errors.document_cession)
                      }
                      helperText={
                        formik.touched.document_cession &&
                        formik.errors.document_cession
                      }
                    />
                  ) : (
                    <></>
                  )}
                </div>

                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <TextField
                    id="signature_date"
                    label="Fecha de firma"
                    disabled={props.monthly}
                    InputLabelProps={{ shrink: true }}
                    style={{ margin: "1rem", width: "50%" }}
                    variant="outlined"
                    type={"date"}
                    value={formik.values.signature_date}
                    onChange={(e) => handleChangeValue(e)}
                    error={
                      formik.touched.signature_date &&
                      Boolean(formik.errors.signature_date)
                    }
                    helperText={
                      formik.touched.signature_date &&
                      formik.errors.signature_date
                    }
                  />

                  <TextField
                    id="effective_date"
                    label="Fecha efectiva"
                    disabled={props.monthly}
                    InputLabelProps={{ shrink: true }}
                    style={{ margin: "1rem", width: "50%" }}
                    variant="outlined"
                    type={"date"}
                    value={formik.values.effective_date}
                    onChange={(e) => handleChangeValue(e)}
                    error={
                      formik.touched.effective_date &&
                      Boolean(formik.errors.effective_date)
                    }
                    helperText={
                      formik.touched.effective_date &&
                      formik.errors.effective_date
                    }
                  />

                  <TextField
                    id="repayment_date"
                    label="Plazo Máximo de Amortización"
                    disabled={props.monthly}
                    InputLabelProps={{ shrink: true }}
                    style={{ margin: "1rem", width: "50%" }}
                    variant="outlined"
                    type={"date"}
                    value={formik.values.repayment_date}
                    onChange={(e) => handleChangeValue(e)}
                    error={
                      formik.touched.repayment_date &&
                      Boolean(formik.errors.repayment_date)
                    }
                    helperText={
                      formik.touched.repayment_date &&
                      formik.errors.repayment_date
                    }
                  />
                </div>

                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <TextField
                    id="credit_facility_maximum_amount"
                    label="Importe Máximo"
                    disabled={props.monthly}
                    InputLabelProps={{ shrink: true }}
                    style={{ margin: "1rem", width: "50%" }}
                    variant="outlined"
                    type={"number"}
                    InputProps={{
                      inputProps: { min: 0 },
                      endAdornment: (
                        <InputAdornment position="end">€</InputAdornment>
                      ),
                    }}
                    value={formik.values.credit_facility_maximum_amount}
                    onChange={(e) => handleChangeValue(e)}
                    error={
                      formik.touched.credit_facility_maximum_amount &&
                      Boolean(formik.errors.credit_facility_maximum_amount)
                    }
                    helperText={
                      formik.touched.credit_facility_maximum_amount &&
                      formik.errors.credit_facility_maximum_amount
                    }
                  />

                  <TextField
                    id="credit_facility_disposals"
                    label="Importe Dispuesto"
                    InputLabelProps={{ shrink: true }}
                    style={{ margin: "1rem", width: "50%" }}
                    variant="outlined"
                    type={"number"}
                    InputProps={{
                      inputProps: { min: 0 },
                      endAdornment: (
                        <InputAdornment position="end">€</InputAdornment>
                      ),
                    }}
                    value={formik.values.credit_facility_disposals}
                    onChange={(e) => handleChangeValue(e)}
                    error={
                      formik.touched.credit_facility_disposals &&
                      Boolean(formik.errors.credit_facility_disposals)
                    }
                    helperText={
                      formik.touched.credit_facility_disposals &&
                      formik.errors.credit_facility_disposals
                    }
                  />

                  <TextField
                    id="credit_facility_available_amount"
                    label="Importe Disponible"
                    InputLabelProps={{ shrink: true }}
                    style={{ margin: "1rem", width: "50%" }}
                    variant="outlined"
                    disabled={true}
                    type={"number"}
                    InputProps={{
                      inputProps: { min: 0 },
                      endAdornment: (
                        <InputAdornment position="end">€</InputAdornment>
                      ),
                    }}
                    value={
                      formik.values.credit_facility_maximum_amount -
                      formik.values.credit_facility_disposals
                    }
                    onChange={(e) => handleChangeValue(e)}
                    error={
                      formik.touched.credit_facility_available_amount &&
                      Boolean(formik.errors.credit_facility_available_amount)
                    }
                    helperText={
                      formik.touched.credit_facility_available_amount &&
                      formik.errors.credit_facility_available_amount
                    }
                  />
                </div>

                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <TextField
                    id="rate_type"
                    label="Tipo Interés"
                    disabled={props.monthly}
                    InputLabelProps={{ shrink: true }}
                    style={{ margin: "1rem", width: "50%" }}
                    variant="outlined"
                    value={formik.values.rate_type}
                    onChange={(e) => handleChangeValue(e)}
                    error={
                      formik.touched.rate_type &&
                      Boolean(formik.errors.rate_type)
                    }
                    helperText={
                      formik.touched.rate_type && formik.errors.rate_type
                    }
                  />

                  <TextField
                    id="rate"
                    label="Interés Aplicado"
                    InputLabelProps={{ shrink: true }}
                    style={{ margin: "1rem", width: "50%" }}
                    variant="outlined"
                    type={"number"}
                    InputProps={{
                      inputProps: { min: 0 },
                      endAdornment: (
                        <InputAdornment position="end">%</InputAdornment>
                      ),
                    }}
                    value={formik.values.rate}
                    onChange={(e) => handleChangeValue(e)}
                    error={formik.touched.rate && Boolean(formik.errors.rate)}
                    helperText={formik.touched.rate && formik.errors.rate}
                  />

                  {!props.amendment && !isNewCFA ? (
                    <>
                      <TextField
                        id="earned_interests"
                        label="Intereses Devengados No Pagados"
                        InputLabelProps={{ shrink: true }}
                        style={{ margin: "1rem", width: "50%" }}
                        variant="outlined"
                        type={"number"}
                        InputProps={{
                          inputProps: { min: 0 },
                          endAdornment: (
                            <InputAdornment position="end">€</InputAdornment>
                          ),
                        }}
                        value={formik.values.earned_interests}
                        onChange={(e) => handleChangeValue(e)}
                        error={
                          formik.touched.earned_interests &&
                          Boolean(formik.errors.earned_interests)
                        }
                        helperText={
                          formik.touched.earned_interests &&
                          formik.errors.earned_interests
                        }
                      />

                      <TextField
                        id="period_interests"
                        label="Intereses Período PG"
                        InputLabelProps={{ shrink: true }}
                        style={{ margin: "1rem", width: "50%" }}
                        variant="outlined"
                        type={"number"}
                        InputProps={{
                          inputProps: { min: 0 },
                          endAdornment: (
                            <InputAdornment position="end">€</InputAdornment>
                          ),
                        }}
                        value={formik.values.period_interests}
                        onChange={(e) => handleChangeValue(e)}
                        error={
                          formik.touched.period_interests &&
                          Boolean(formik.errors.period_interests)
                        }
                        helperText={
                          formik.touched.period_interests &&
                          formik.errors.period_interests
                        }
                      />
                    </>
                  ) : (
                    <></>
                  )}

                  {!props.monthly ? (
                    props.edit ? (
                      <>
                        <TextField
                          id="document_amendment"
                          label={"Adenda"}
                          variant={"outlined"}
                          inputProps={{
                            multiple: true,
                            accept: "application/pdf",
                          }}
                          style={{
                            margin: "1rem",
                            width: "50%",
                            flexGrow: "2",
                          }}
                          InputLabelProps={{ shrink: true }}
                          type={"file"}
                          onChange={(event: any) => {
                            handleUploadFiles(
                              event.currentTarget.files[0],
                              event
                            );
                          }}
                          error={
                            formik.touched.document_amendment &&
                            Boolean(formik.errors.document_amendment)
                          }
                          helperText={
                            formik.touched.document_amendment &&
                            formik.errors.document_amendment
                          }
                        />

                        <TextField
                          id="amendment_date"
                          label="Fecha adenda"
                          InputLabelProps={{ shrink: true }}
                          style={{ margin: "1rem", width: "50%" }}
                          variant="outlined"
                          type={"date"}
                          value={formik.values.amendment_date}
                          onChange={formik.handleChange}
                          error={
                            formik.touched.amendment_date &&
                            Boolean(formik.errors.amendment_date)
                          }
                          helperText={
                            formik.touched.amendment_date &&
                            formik.errors.amendment_date
                          }
                        />
                      </>
                    ) : (
                      <TextField
                        id="document_cfa"
                        label={"Acuerdo de Línea de Crédito"}
                        variant={"outlined"}
                        inputProps={{
                          // multiple: true,
                          accept: "application/pdf",
                        }}
                        style={{ margin: "1rem", width: "50%" }}
                        InputLabelProps={{ shrink: true }}
                        type={"file"}
                        onChange={(event: any) => {
                          handleUploadFiles(
                            event.currentTarget.files[0],
                            event
                          );
                        }}
                        error={
                          formik.touched.document_cfa &&
                          Boolean(formik.errors.document_cfa)
                        }
                        helperText={
                          formik.touched.document_cfa &&
                          formik.errors.document_cfa
                        }
                      />
                    )
                  ) : (
                    <></>
                  )}
                </div>

                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <TextField
                    id="comments"
                    label="Anotaciones"
                    // fullWidth
                    multiline={true}
                    minRows={5}
                    style={{ width: "100%", margin: "1rem" }}
                    variant="outlined"
                    value={formik.values.comments}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.comments && Boolean(formik.errors.comments)
                    }
                    helperText={
                      formik.touched.comments && formik.errors.comments
                    }
                  />
                </div>

                <div className="section-container">
                  <LoadingButton
                    type="submit"
                    loading={isLoadingButton}
                    loadingPosition="start"
                    variant="contained"
                    color="primary"
                    className={`button blue-button ${
                      isLoadingButton ? "is-loading" : ""
                    }`}
                  >
                    {isLoadingButton
                      ? "Enviando…"
                      : props.monthly
                      ? "Cargar cierre mes"
                      : props.edit
                      ? "Modificar"
                      : "Crear"}
                  </LoadingButton>
                </div>
              </Box>
            </div>
          </div>
        </>
      )}
    </>
  );
};
