import React, { useEffect, useState } from 'react';
import moment from "moment";
import { useSelector } from 'react-redux';
import { GridColDef, GridRenderEditCellParams, GridValueGetterParams } from "@mui/x-data-grid";
import { Box, Tooltip } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import CheckIcon from '@mui/icons-material/Check';

import AedasLoading from '../../../Shared/AedasLoading/AedasLoading';
import DataGridEditabled from '../../../Shared/DataGridEditabled/DataGridEditabled';

import { Corporate } from '../../../Shared/SharedForms/Corporate';

import { useFetch, useIsLoading } from '../../../../Hooks/useFetch';
import { getAllCorporates } from './../../../../Services/CorporateServices/CorporateServices';

import './../CorporateMain.css';

export const EditCoorporateMain = () => {
  const [ allCorporates, isFetchingCorporates] = useFetch(getAllCorporates);
  const [ corporateToUpdate, setCorporateToUpdate] = useState<any>();
  const [ corporatesActivated, setCorporatesActivated] = useState<any[]>([]) 

  const isLoading = useIsLoading([isFetchingCorporates]);

  const userRol = useSelector((state: any) => state.userReducer.user.employee.id_rol);
  const isAdmin = userRol === 12 ? true : false;

  const columns: GridColDef[] = [
    {
      field: "id_corporation",
      headerName: "ID",
      width: 9,
      hide: true,
      editable: false,
    },
    {
      field: "registered_name",
      headerName: "Denominación Social",
      type: "string",
      editable: false,
      flex: 1,
    },
    {
      field: "activated",
      headerName: "Estado",
      type: "string",
      renderCell: (params: GridRenderEditCellParams) => {
        if ( !params.row.activated ) {
          return <Tooltip title="Borrada">
                  <CloseIcon />
                 </Tooltip>
        } else { 
          return <Tooltip title="Activa">
                    <CheckIcon />
                  </Tooltip>
        }
      },
      editable: false,
      hide: !isAdmin, 
      width: 100,
    },
    {
      field: "cif",
      headerName: "NIF",
      type: "string",
      editable: false,
      flex: 1,
    },
    {
      field: "country",
      headerName: "País",
      type: "string",
      editable: false,
      flex: 1,
    },
    {
      field: "creation_date",
      headerName: "F. constitución",
      type: "string",
      renderCell: (params: GridRenderEditCellParams) => {
          return <div>{ moment(params.row.creation_date).format("YYYY/MM/DD") }</div>;
      },
      editable: false,
      flex: 1,
    },
    {
      field: "end_date",
      headerName: "Hasta",
      type: "string",
      renderCell: (params: GridRenderEditCellParams) => {
        return <div>{ params.row.end_date ? moment(params.row.end_date).format("YYYY/MM/DD") : '-'}</div>;
      },
      editable: false,
      flex: 1,
    },
  ]

  useEffect(() => {
    if (!isLoading) {
      setCorporatesActivated( isAdmin ? allCorporates : allCorporates.filter((corporate: any) => corporate.activated === true));
    }
  }, [isLoading])

  const handleSetCorporate = (params: any) => {
    setCorporateToUpdate(corporatesActivated.find((corporate: any) => corporate.id_corporation === params.row.id_corporation))
  }

  return (
    <>
      {isLoading ? (
        <AedasLoading></AedasLoading>
      ) : (      
      <div className="h-100 m-4">
        <div className="main-container">
          <Box
            component="div"
            className="text-center"
            sx={{
              width: "100%",
              mx: "auto",
              my: 2,
              display: "flex",
              flexDirection: "column",
              alignItems: 'stretch'
            }}
            >
            <h1 className="main-title" style={{marginBottom: "1.5rem"}}>Edición de sociedades</h1>

            <DataGridEditabled
              onlyEdit={false}
              showHeader={false}
              rows={corporatesActivated ?? []}
              columns={columns}
              onCellClick={(params: any) => handleSetCorporate(params)}
              getRowId={(row: any) => row.id_corporation}
              disableSelectionOnClick = {false}

            ></DataGridEditabled>  

            {
              corporateToUpdate ? 
                <div style={{ width: "90%", margin: "0 auto" }}>
                  <hr style={{width: "75%", margin: "1.5rem auto 1rem"}}/>

                  <p>
                    <strong>{`Sociedad a editar: ${corporateToUpdate.registered_name}`}</strong>
                    <span> {!corporateToUpdate.activated ? "(Borrada)" : "" }</span>
                  </p>
                  <Corporate corporate={corporateToUpdate} masterEditor={true}/>
                </div>
              : <></>
            }  

          </Box>       
        </div>
      </div> 
      )}
    </>
  )
}
