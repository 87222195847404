import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import moment from "moment";
import { toast } from "react-toastify";
import { useFormik } from "formik";

import {
  GridColDef,
  GridRenderEditCellParams,
  GridCellValue,
  GridCellParams,
  GridValueFormatterParams,
  GridValueGetterParams,
} from "@mui/x-data-grid";
import { Button, Box, TextField } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";

import AedasLoading from "../../Components/Shared/AedasLoading/AedasLoading";
import CustomModal from "../../Components/Shared/ModalCustom/CustomModal";

import { TabListComponent } from "../../Components/Shared/TabsListComponent/TabListComponent";
import { useFetch, useIsLoading } from "../../Hooks/useFetch";

import { HistoricCFA } from "./HistoricCFA";

import {
  getAllCorporates,
  getDocumentByID,
} from "../../Services/CorporateServices/CorporateServices";
import { updateCFA, removeCFA } from "../../Services/CFAServices/CFAServices";

import "../../Components/Static/Corporate/InfoCorporate/InfoCorporate.css";

export const InfoCFAs = (props: any) => {
  const params: any = useParams();
  const corporateId: number = params?.corporate_id;

  const [corporates, isFetchingCorporates] = useFetch(getAllCorporates);
  const isLoading = useIsLoading([isFetchingCorporates, props.isFetchingCfas]);

  const [openDetailsModal, setOpenDetailsModal] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [openUpdateFileModal, setOpenUpdateFileModal] = useState(false);
  const [documentId, setDocumentId] = useState(null);
  const [updateDocumentModalTitle, setUpdateDocumentModalTitle] = useState("");
  const [documentType, setDocumentType] = useState(null);

  const [selectedRow, setSelectedRow] = useState(null);
  const [selectedRowRawData, setSelectedRowRawData] = useState<any>(null);

  const [isLoadingProcessData, setisLoadingProcessData] = useState(true);
  const [rowsData, setRowsData] = useState([]);

  const [displayHistoric, setDisplayHistoric] = useState(false);
  const userId = useSelector(
    (state: any) => state.userReducer.user.employee.id
  );

  const processData = () => {
    const cfas = !props.list
      ? props.cfas.filter(
          (cfa: any) => cfa.creditor == corporateId || cfa.debtor == corporateId
        )
      : props.cfas;
    let auxArray: any = cfas?.map((item: any) => {
      return {
        id: item.id,
        created_at: item.created_at
          ? moment(item.created_at).format("DD/MM/YYYY")
          : "-",
        updated_at: item.updated_at
          ? moment(item.updated_at).format("DD/MM/YYYY")
          : "-",
        status: item.status ? "Activo" : "Terminado",
        creditor: item.creditor
          ? corporates.find(
              (corporate: any) => corporate.id_corporation === item.creditor
            )?.registered_name
          : "-",
        debtor: item.debtor
          ? corporates.find(
              (corporate: any) => corporate.id_corporation === item.debtor
            )?.registered_name
          : "-",
        signature_date: item.signature_date
          ? moment(item.signature_date).format("DD/MM/YYYY")
          : "-",
        effective_date: item.effective_date
          ? moment(item.effective_date).format("DD/MM/YYYY")
          : "-",
        repayment_date: item.repayment_date
          ? moment(item.repayment_date).format("DD/MM/YYYY")
          : "-",
        termination_date: item.termination_date
          ? moment(item.termination_date).format("DD/MM/YYYY")
          : "-",
        credit_facility_maximum_amount: item.credit_facility_maximum_amount
          ? new Intl.NumberFormat("es-ES", {
              style: "currency",
              currency: "EUR",
            }).format(item.credit_facility_maximum_amount)
          : "-",
        credit_facility_disposals: item.credit_facility_disposals
          ? new Intl.NumberFormat("es-ES", {
              style: "currency",
              currency: "EUR",
            }).format(item.credit_facility_disposals)
          : "-",
        credit_facility_available_amount: item.credit_facility_available_amount
          ? new Intl.NumberFormat("es-ES", {
              style: "currency",
              currency: "EUR",
            }).format(item.credit_facility_available_amount)
          : "-",
        rate_type: item.rate_type,
        rate: item.rate ? `${item.rate}%` : "-",
        earned_interests: new Intl.NumberFormat("es-ES", {
          style: "currency",
          currency: "EUR",
        }).format(item.earned_interests),
        period_interests: new Intl.NumberFormat("es-ES", {
          style: "currency",
          currency: "EUR",
        }).format(item.period_interests),
        bold_creditor: corporateId == item.creditor,
        bold_debtor: corporateId == item.debtor,
        comments: item.comments,
      };
    });

    setRowsData(auxArray);
    setisLoadingProcessData(false);
  };

  const onCellClick = (params: any) => {
    setSelectedRow(params.row);
    setSelectedRowRawData(
      props.cfas.find((cfa: any) => cfa.id === params.row.id)
    );
    setOpenDetailsModal(true);
  };

  const handleDelete = () => {
    removeCFA(selectedRowRawData.id, userId)
      .then((response: any) => {
        setOpenDeleteModal(false);
        toast.success("Borrado");
        props.updateAllCfas();
      })
      .catch(() => toast.error("Ha habido un problema"));
  };

  useEffect(() => {
    if (!isLoading) {
      processData();
    } else {
      setisLoadingProcessData(true);
    }
  }, [isLoading, props.cfas]);

  const calcuteInterests = (
    credit_facility_disposals: number,
    rate: number,
    effective_date: string
  ) => {
    const now = moment();
    const effectiveDate = moment(effective_date);
    const daysBetweenDates = now.diff(effectiveDate, "days");

    const earnedInterests =
      (((credit_facility_disposals * rate) / 100) * daysBetweenDates) / 360;
    return earnedInterests;
  };

  const columns: GridColDef[] = [
    {
      field: "id",
      headerName: "ID",
      width: 0,
    },
    {
      field: "created_at",
      headerName: "F. Creación",
      type: "string",
      // width: 50,
      flex: 1,
    },
    {
      field: "updated_at",
      headerName: "F. Última modificación",
      type: "string",
      // width: 50,
      flex: 1,
    },
    {
      field: "status",
      headerName: "Estado",
      type: "string",
      width: 50,
      flex: 1,
    },
    {
      field: "termination_date",
      headerName: "F. Finalización",
      type: "string",
      flex: 1,
    },
    {
      field: "creditor",
      headerName: "Acreedor",
      renderCell: (params: GridRenderEditCellParams) => {
        return (
          <div
            style={{
              fontWeight: `${params.row.bold_creditor ? "bolder" : "400"}`,
            }}
          >
            {params.row.creditor}
          </div>
        );
      },
      type: "string",
      flex: 2,
    },
    {
      field: "debtor",
      headerName: "Deudor",
      renderCell: (params: GridRenderEditCellParams) => {
        return (
          <div
            style={{
              fontWeight: `${params.row.bold_debtor ? "bolder" : "400"}`,
            }}
          >
            {params.row.debtor}
          </div>
        );
      },
      type: "string",
      flex: 2,
    },
    {
      field: "credit_facility_maximum_amount",
      headerName: "Importe Máximo",
      type: "string",
      flex: 1,
    },
    {
      field: "credit_facility_disposals",
      headerName: "Importe Dispuesto",
      type: "string",
      flex: 1,
    },
    {
      field: "credit_facility_available_amount",
      headerName: "Importe Disponible",
      type: "string",
      flex: 1,
    },
    {
      field: "rate_type",
      headerName: "Tipo Interés",
      type: "string",
      flex: 1,
    },
    {
      field: "rate",
      headerName: "Interés Aplicado",
      type: "string",
      flex: 1,
    },
    {
      field: "earned_interests",
      headerName: "Intereses Devengados No Pagados",
      type: "string",
      flex: 1,
    },
    {
      field: "period_interests",
      headerName: "Intereses Período PG",
      type: "string",
      flex: 1,
    },
    {
      field: "signature_date",
      headerName: "F. Firma",
      type: "string",
      flex: 1,
    },
    {
      field: "effective_date",
      headerName: "F. Efectiva",
      type: "string",
      flex: 1,
    },
    {
      field: "repayment_date",
      headerName: "F. Reembolso",
      type: "string",
      flex: 1,
    },
    {
      field: "comments",
      headerName: "Comentarios",
      type: "string",
    },
  ];

  return (
    <>
      {isLoading || isLoadingProcessData ? (
        <AedasLoading />
      ) : (
        <>
          <CustomModal
            size='xl'
            show={openDetailsModal}
            onChange={() => {
              setOpenDetailsModal(!openDetailsModal);
            }}
            title={"Detalles CFA"}
            body={
              <CFADetails
                selectedRow={selectedRow}
                corporates={corporates}
                selectedRowRawData={selectedRowRawData}
                show={openDeleteModal}
                setOpenDetailsModal={setOpenDetailsModal}
                setopenModal={setOpenDeleteModal}
                setUpdateDocumentModalTitle={setUpdateDocumentModalTitle}
                setDocumentType={setDocumentType}
                setDocumentId={setDocumentId}
                openUpdateFileModal={openUpdateFileModal}
                setOpenUpdateFileModal={setOpenUpdateFileModal}
                calcuteInterests={calcuteInterests}
              />
            }
          />

          <CustomModal
            size='lg'
            show={openDeleteModal}
            onChange={() => {
              setOpenDeleteModal(!openDeleteModal);
            }}
            title='Confirmar borrado'
            body={
              <div>
                <div className='d-flex justify-content-center'>
                  <h5>Confirmo el borrado de este CFA</h5>
                </div>
                <div className='d-flex justify-content-center'>
                  <Button
                    onClick={() => handleDelete()}
                    className='button reject-button'
                  >
                    Confirmar borrado
                  </Button>
                </div>
              </div>
            }
          />

          <CustomModal
            size='lg'
            show={openUpdateFileModal}
            onChange={() => {
              setOpenUpdateFileModal(!openUpdateFileModal);
            }}
            title={updateDocumentModalTitle}
            body={
              <UpdateDocument
                cfa_id={selectedRowRawData?.id || undefined}
                selectedRowRawData={selectedRowRawData}
                documentId={documentId}
                documentType={documentType}
                label={updateDocumentModalTitle}
                setOpenUpdateFileModal={setOpenUpdateFileModal}
                updateAllCfas={props.updateAllCfas}
              />
            }
          />

          <TabListComponent
            corporate={props.corporate}
            title={!props.list ?? "Acuerdos de Líneas de Crédito"}
            columns={columns}
            rows={rowsData || []}
            onCellClick={onCellClick}
            exportIcon={props.export}
            initialState={{
              columns: {
                columnVisibilityModel: {
                  id: false,
                  comments: false,
                  repayment_date: false,
                  effective_date: false,
                  // rate: false,
                  // credit_facility_disposals: false,
                  // credit_facility_maximum_amount: false,
                  credit_facility_available_amount: false,
                  termination_date: false,
                  rate_type: false,
                  earned_interests: false,
                  period_interests: false,
                  signature_date: false,
                },
              },
            }}
            disableSelectionOnClick={true}
          />

          {/* { !props.list && rowsData.length > 0 ? (
						<>
							<Button
								onClick={() => setDisplayHistoric(!displayHistoric)}
								color="primary"
								className="button blue-button historical"
							>
								{ `${!displayHistoric ? "Ver" : "Ocultar"} historial` }
							</Button>

							{ displayHistoric 
								?	<HistoricCFA listByCorporate={true} export={false}/>
								:  <></> }
						</>

						) : <></> } */}
        </>
      )}
    </>
  );
};

const CFADetails = (props: any) => {
  // const [earnedInterests, setEarnedInterests] = useState<number>();

  const navigate = useNavigate();

  const userIdRol = useSelector(
    (state: any) => state.userReducer.user.employee.id_rol
  );
  const isCfaEditor = userIdRol === 14 ? true : false;
  const isAccouter = userIdRol === 15 ? true : false;
  const isAdmin = userIdRol === 12 ? true : false;
  const isEditor = userIdRol === 11 ? true : false;

  // useEffect(() => {
  // 	if (props.selectedRowRawData.credit_facility_disposals && props.selectedRowRawData.effective_date && props.selectedRowRawData.rate ) {
  // 		const interest = props.calcuteInterests(props.selectedRowRawData.credit_facility_disposals, props.selectedRowRawData.rate, props.selectedRowRawData.effective_date)
  // 		setEarnedInterests(interest);
  // 	}
  // }, [props.selectedRowRawData])

  const docsDict: any = {
    document_cfa: "Acuerdo de Línea de Crédito",
    document_amendment: "Adendas:",
    document_termination: "Acuerdo de Finalización",
    document_cession: "Acuerdo de Cesión",
  };

  const openFile = (file: any) => {
    const pdfWindow = window.open("", `${file.name}`);
    pdfWindow?.document.write(
      `<iframe
					name='${file.name}'
					width='100%'
					height='100%'
					style='border:none'
					src='data:application/pdf;base64, ${encodeURI(file.document)} '
			></iframe>`
    );
  };

  const handleOpenFile = (id: number) => {
    getDocumentByID(id).then((response) => openFile(response.data[0]));
  };

  const handleUpdateFile = (document: any, type: any) => {
    const documentType: any = Object.entries(docsDict).find(
      (item: any) => item[1] == type
    );
    const modalTitle =
      documentType[0] != "document_amendment"
        ? `Modificar ${docsDict[documentType[0]]}`
        : `Modificar Adenda del ${moment(document.date_registration).format(
            "DD/MM/YYYY"
          )}`;

    props.setUpdateDocumentModalTitle(modalTitle);
    props.setDocumentType(documentType ? documentType[0] : null);
    props.setDocumentId(document.id);
    props.setOpenDetailsModal(false);
    props.setOpenUpdateFileModal(!props.openUpdateFileModal);
  };

  return (
    <>
      <div className='row '>
        <div
          className='d-flex justify-content-between p-2 mx-4'
          style={{ maxWidth: "calc(100% - 3rem)" }}
        >
          <div className='col  '>
            <h2 className='modal-field-title'>Acreedor</h2>
            <h3 style={{ fontSize: "15px" }}>
              {props.selectedRow.creditor
                ? props.selectedRow.creditor
                : "No hay datos"}
            </h3>
          </div>

          <div className='col  '>
            <h2 className='modal-field-title'>Deudor</h2>
            <h3 style={{ fontSize: "15px" }}>
              {props.selectedRow.debtor
                ? props.selectedRow.debtor
                : "No hay datos"}
            </h3>
          </div>

          {props.selectedRow.status == "Activo" ? (
            <div className='col  '>
              <h2 className='modal-field-title'>Estado</h2>
              <h3 style={{ fontSize: "15px" }}>
                {props.selectedRow.status
                  ? props.selectedRow.status
                  : "No hay datos"}
              </h3>
            </div>
          ) : (
            <div className='col'></div>
          )}
        </div>

        {props.selectedRow.status == "Terminado" ? (
          <div
            className='d-flex justify-content-between p-2 mx-4'
            style={{ maxWidth: "calc(100% - 3rem)" }}
          >
            <div className='col  '>
              <h2 className='modal-field-title'>Estado</h2>
              <h3 style={{ fontSize: "15px" }}>
                {props.selectedRow.status
                  ? props.selectedRow.status
                  : "No hay datos"}
              </h3>
            </div>

            <div className='col  '>
              <h2 className='modal-field-title'>Fecha de finalización</h2>
              <h3 style={{ fontSize: "15px" }}>
                {" "}
                {props.selectedRow.termination_date}
              </h3>
            </div>

            <div className='col'></div>
          </div>
        ) : (
          <></>
        )}

        <div
          className='d-flex justify-content-between p-2 mx-4'
          style={{ maxWidth: "calc(100% - 3rem)" }}
        >
          <div className='col  '>
            <h2 className='modal-field-title'>Fecha de firma</h2>
            <h3 style={{ fontSize: "15px" }}>
              {props.selectedRow.signature_date &&
              props.selectedRow.signature_date != ""
                ? props.selectedRow.signature_date
                : "No hay datos"}
            </h3>
          </div>

          <div className='col  '>
            <h2 className='modal-field-title'>Fecha efectiva</h2>
            <h3 style={{ fontSize: "15px" }}>
              {props.selectedRow.effective_date &&
              props.selectedRow.effective_date != ""
                ? props.selectedRow.effective_date
                : "No hay datos"}
            </h3>
          </div>

          <div className='col  '>
            <h2 className='modal-field-title'>Plazo Máximo de Amortización</h2>
            <h3 style={{ fontSize: "15px" }}>
              {props.selectedRow.repayment_date &&
              props.selectedRow.repayment_date != ""
                ? props.selectedRow.repayment_date
                : "No hay datos"}
            </h3>
          </div>
        </div>

        <div
          className='d-flex justify-content-between p-2 mx-4'
          style={{ maxWidth: "calc(100% - 3rem)" }}
        >
          <div className='col  '>
            <h2 className='modal-field-title'>Importe Máximo</h2>
            <h3 style={{ fontSize: "15px" }}>
              {props.selectedRow.credit_facility_maximum_amount
                ? props.selectedRow.credit_facility_maximum_amount
                : "No hay datos"}
            </h3>
          </div>

          <div className='col  '>
            <h2 className='modal-field-title'>Importe Dispuesto</h2>
            <h3 style={{ fontSize: "15px" }}>
              {props.selectedRow.credit_facility_disposals
                ? props.selectedRow.credit_facility_disposals
                : "No hay datos"}
            </h3>
          </div>

          <div className='col  '>
            <h2 className='modal-field-title'>Importe Disponible</h2>
            <h3 style={{ fontSize: "15px" }}>
              {props.selectedRow.credit_facility_available_amount
                ? props.selectedRow.credit_facility_available_amount
                : "No hay datos"}
            </h3>
          </div>
        </div>

        <div
          className='d-flex justify-content-between p-2 mx-4'
          style={{ maxWidth: "calc(100% - 3rem)" }}
        >
          <div className='col  '>
            <h2 className='modal-field-title'>Tipo Interés</h2>
            <h3 style={{ fontSize: "15px" }}>
              {props.selectedRow.rate_type
                ? props.selectedRow.rate_type
                : "No hay datos"}
            </h3>
          </div>

          <div className='col  '>
            <h2 className='modal-field-title'>Interés Aplicado</h2>
            <h3 style={{ fontSize: "15px" }}>
              {props.selectedRow.rate ? props.selectedRow.rate : "No hay datos"}
            </h3>
          </div>

          <div className='col'></div>
        </div>

        <div
          className='d-flex justify-content-between p-2 mx-4'
          style={{ maxWidth: "calc(100% - 3rem)" }}
        >
          <div className='col  '>
            <h2 className='modal-field-title'>
              Intereses Devengados No Pagados
            </h2>
            <h3 style={{ fontSize: "15px" }}>
              {props.selectedRow.earned_interests ?? "No hay datos"}
            </h3>
          </div>

          <div className='col  '>
            <h2 className='modal-field-title'>Intereses Período PG</h2>
            <h3 style={{ fontSize: "15px" }}>
              {props.selectedRow.period_interests ?? "No hay datos"}
            </h3>
          </div>

          <div className='col'></div>
        </div>

        <div
          className='d-flex justify-content-between p-2 mx-4'
          style={{ maxWidth: "calc(100% - 3rem)" }}
        >
          <div className='col'>
            <h2 className='modal-field-title'>Comentarios</h2>
            <div style={{ fontSize: "15px" }}>
              {props.selectedRowRawData.comments &&
              props.selectedRowRawData.comments != ""
                ? props.selectedRowRawData.comments
                    .split("\n")
                    .map((p: string) => {
                      return <p> {p} </p>;
                    })
                : "No hay datos"}
            </div>
          </div>
        </div>

        <div className='d-flex flex-column justify-content-between p2 mx-4'>
          <h2 className='modal-field-title'>Documentos asociados al CFA</h2>

          <ul className='documents-list'>
            {Object.keys(props.selectedRowRawData)
              .filter((key: string) => key.startsWith("document_"))
              .map((document: any, index: number) => {
                return props.selectedRowRawData[document] != null ? (
                  <li key={index} data-icon='◾ '>
                    {props.selectedRowRawData[document].length ? (
                      <>
                        <span>{docsDict[document]}</span>
                        {props.selectedRowRawData[document].map(
                          (item: any, index: number) => (
                            <>
                              <span key={index} className='dates'>
                                - Documento Adenda del{" "}
                                {moment(item.date_registration).format(
                                  "DD/MM/YYYY"
                                )}
                                <a
                                  onClick={() => handleOpenFile(item.id)}
                                  id={`${document}-${index}`}
                                  className='document-button view'
                                >
                                  ver
                                </a>
                                <a
                                  onClick={() =>
                                    handleUpdateFile(item, docsDict[document])
                                  }
                                  id={`${document}-${index}`}
                                  className='document-button replace'
                                >
                                  modificar
                                </a>
                              </span>
                            </>
                          )
                        )}
                      </>
                    ) : (
                      <>
                        <span>{docsDict[document]}</span>
                        <a
                          className='document-button view'
                          onClick={() =>
                            handleOpenFile(
                              props.selectedRowRawData[document].id
                            )
                          }
                          id={document}
                        >
                          ver
                        </a>
                        <a
                          className='document-button replace'
                          onClick={() =>
                            handleUpdateFile(
                              props.selectedRowRawData[document],
                              docsDict[document]
                            )
                          }
                          id={document}
                        >
                          modificar
                        </a>
                      </>
                    )}
                  </li>
                ) : (
                  <></>
                );
              })}
          </ul>
        </div>
      </div>

      <div className='d-flex justify-content-center px-4 button-container mx-auto'>
        {isAdmin ? (
          <Button
            onClick={() => {
              props.setopenModal(true);
              props.setOpenDetailsModal(false);
            }}
            color='primary'
            className='button reject-button'
          >
            Borrar
          </Button>
        ) : (
          <></>
        )}

        {isCfaEditor || isEditor || isAdmin ? (
          <Button
            onClick={() => navigate(`/editar-cfa/${props.selectedRow.id}`)}
            color='primary'
            className='button blue-button'
          >
            Añadir o Modificar
          </Button>
        ) : (
          <></>
        )}

        {isAccouter || isEditor || isAdmin ? (
          <Button
            onClick={() => navigate(`/cierre-mes/${props.selectedRow.id}`)}
            color='primary'
            className='button blue-button'
          >
            Cierre de Mes
          </Button>
        ) : (
          <></>
        )}

        <Button
          onClick={() => navigate(`/historico-cfa/${props.selectedRow.id}`)}
          color='primary'
          className='button blue-button'
        >
          Ver historial
        </Button>
      </div>
    </>
  );
};

const UpdateDocument = (props: any) => {
  const navigate: any = useNavigate();
  const userId = useSelector(
    (state: any) => state.userReducer.user.employee.id
  );
  const [filesAttached, setFilesAttached] = useState<any[]>([]);
  const [isLoadingButton, setIsLoadingButton] = useState(false);

  const formik = useFormik({
    initialValues: {
      document: null,
    },

    validationSchema: null,

    onSubmit: async (values: any) => {
      setIsLoadingButton(true);

      const docObject: any = {
        id: props.documentId,
        name: filesAttached[0].name[0],
        document: filesAttached[0].document[0]
          .replace("data:", "")
          .replace(/^.+,/, ""),
      };

      const documentNotModified = Object.keys(props.selectedRowRawData)
        .filter((key: string) => key.startsWith("document_"))
        .filter((key: any) => key !== props.documentType);

      const bodyDocuments: any = {};

      documentNotModified.forEach((doc: string) => (bodyDocuments[doc] = null));

      const body: any = {
        ...props.selectedRowRawData,
        id_cfa: props.cfa_id ?? null,
        id_employee: userId,
        ...bodyDocuments,
      };

      if (props.documentType == "document_amendment") {
        const date = props.selectedRowRawData.document_amendment.find(
          (document: any) => document.id == props.documentId
        )?.date_registration;
        docObject["date_registration"] = date;
        body[props.documentType] = new Array(docObject);
      } else body[props.documentType] = docObject;

      updateCFA(body)
        .then((response: any) => {
          if (response.statusText === "OK") {
            toast.success("Documento actualizado con éxito");
            props.setOpenUpdateFileModal(false);
            navigate("/cfa");
          }
        })
        .then(() => setIsLoadingButton(false))
        .then(() => props.updateAllCfas());
    },
  });

  const handleUploadFiles = (files: any, event: any) => {
    let filesUploadedList = event.currentTarget.files;
    let fileListBase64: any[] = [];
    let fileNameList: any[] = [];

    Object.values(filesUploadedList).forEach((file: any) => {
      fileNameList.push(file.name);

      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        let fileBase64 = reader.result?.toString() || "";
        fileListBase64.push(fileBase64);
      };
    });

    formik.handleChange(event);
    setFilesAttached([
      ...filesAttached,
      { name: fileNameList, document: fileListBase64 },
    ]);
  };

  return (
    <>
      <Box
        component='form'
        id='corporate-form'
        sx={{
          width: "100%",
          mx: "auto",
          my: 2,
          display: "flex",
          alignItems: "center",
        }}
        noValidate
        autoComplete='on'
        onSubmit={formik.handleSubmit}
      >
        <TextField
          id={props.documentType}
          label={props.label.split("Modificar ")[1]}
          variant={"outlined"}
          inputProps={{
            // multiple: true,
            accept: "application/pdf",
          }}
          style={{ margin: "1rem", width: "50%" }}
          InputLabelProps={{ shrink: true }}
          type={"file"}
          onChange={(event: any) => {
            handleUploadFiles(event.currentTarget.files[0], event);
          }}
        />
        <LoadingButton
          type='submit'
          loading={isLoadingButton}
          loadingPosition='start'
          variant='contained'
          color='primary'
          className={`button blue-button modal ${
            isLoadingButton ? "is-loading" : ""
          }`}
        >
          {isLoadingButton ? "Enviando…" : "Modificar documento"}
        </LoadingButton>
      </Box>
    </>
  );
};
