const initialState = {
	corporate: {
		registered_name: ' ',
		type: 'Limitada',
		cif: '',
		controlled: false,
		audited: {
			value: false,
			person: [{
				name: ' ',
				juridical: true,
				cif_nif: '',
			  }],
			end_date:''
		},
		group: false,
		creation_date: '',
		end_date: '',
		document_creation: {},
		corporate_purpose: ' ',
		full_address: ' ',
		postal_code: ' ',
		town: ' ',
		province: ' ',
		country: ' ',
		comments: ' ',
		protocol_number: ' ',
		date_protocol: '',
		notary_name: ' ',
		registration_info: ' ',
		document_registered_name: {},
		document_type: {},
		document_end: {},
		document_corporate_purpose: {},
		document_address: {},
		document_address_international: {},
	},
	bodyCorporate: {
		type: 'Administrador único',
    	registered_date: '',
    	deregistered_date: '',
		document_regulation: {},
    	comments:' ',
    	files: '',
	}, 
	members: [],
	capital: {
		description: 'Acciones ordinarias',
		nominal_value: 0,
		currency: 'EUR',
		shares_issued: 0,
		total_capital: 0,
		shares_partially_paid: 0,
		percentage_paid: 0,
		total_partially_paid: 0,
		political_rights: false,
		comments: ' ',
		shares_comments: ' ',
		date_from: '',
		date_to: '',
		person: [{
			id: 0,
			name: ' ',
			juridical: true,
			cif_nif: '',
			number_shares: 0,
			percentage: 0
		  }],
		test: true
	}
};


export const corporateReducer = (state: any = initialState, action: any) => {
    switch (action.type) {
        case "@corporate/add":
        return {
					...state,
            corporate: action.payload,
        };

		case "@corporate/update":
        return {
					...state,
            corporate: action.payload,
        };

		case "@body-corporate/add":
			return {
				...state,
				bodyCorporate: action.payload,
		};

		case "@members/add":
			return {
				...state,
				members: [...state.members, action.payload ],
		};

		case "@members/reset":
			return {
				...state,
				members: [],
		};

		case "@inicial-capital/add":
			return {
				...state,
				capital: action.payload,
		};

		case "@reset-corporation":
		return initialState;

        default:
        return state;
    }
};
