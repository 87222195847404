import { useEffect, useMemo, useState } from "react";

import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { toast, ToastContainer } from "react-toastify";

import { goToURL, validatePermission } from "./Utils/permissionsUtils";
import { loginAzure } from "./Services/AuthService/AuthService";
import { userLogin } from "./Redux/User/UserAction";
import { AedasLayout, AedasLoading } from "aedas-library";
import { Toaster } from "sonner";
import { AppRoutes } from "./routes";

import "react-toastify/dist/ReactToastify.css";
import "./App.css";
import { useAuth } from "react-oidc-context";
import { usePermission } from "./Hooks/usePermission";
import { IoMdAddCircleOutline } from "react-icons/io";
import { PiGavelFill } from "react-icons/pi";
import { AiFillEdit } from "react-icons/ai";
import { MdOutlinePayments } from "react-icons/md";
import { BsGraphUp } from "react-icons/bs";
import { IoBusinessSharp } from "react-icons/io5";
import { IoIosPerson } from "react-icons/io";
import { HiOutlineClipboardDocumentList } from "react-icons/hi2";
import HomeIcon from "@mui/icons-material/Home";
import { everyRol, userIDRolDict } from "./Utils/stringTransform";
import {
  getAzureToken,
  getUserPhoto,
} from "./Services/UserService/keycloakService";
import { getAllLitigiosCollaborators } from "./Services/UserService/UserService";
import { log } from "console";

function App() {
  return (
    <>
      <ToastContainer autoClose={1500}></ToastContainer>
      <Router></Router>
    </>
  );
}

export default App;

const Router = () => {
  const [isLoadingAll, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const { user, isAuthenticated, isLoading, signinRedirect, signoutRedirect } =
    useAuth();
  const dispatch = useDispatch();
  const { token, hasPermission, isPermissionLoading } = usePermission();
  const userSelector = useSelector((state: any) => state.userReducer.user);
  const [userPhoto, setUserPhoto] = useState("");
  const [collaborators, setCollaborators] = useState<any[]>();
  const [userInfo, setUserInfo] = useState();

  useEffect(() => {
    if (token) {
      getAllLitigiosCollaborators().then((response: any) => {
        setCollaborators(response);
      });
    }
  }, [token]);

  useEffect(() => {
    if (collaborators && userInfo) {
      const user = collaborators?.find(
        //@ts-ignore
        (coll: any) => coll.email.toLowerCase() == userInfo?.email.toLowerCase()
      );

      if (user?.collaborator_type?.id == 9) {
        goToURL(process.env.REACT_APP_LITIGIOS + "/estadisticas");
      }
      // else {
      //   console.log("entro");

      //   navigate("/sociedades");
      // }
    }
  }, [collaborators, userInfo]);

  useEffect(() => {
    if (token) {
      getAzureToken(token)
        .then((res) => {
          getUserPhoto(res.access_token)
            .then((photo: any) => {
              const imageUrl = URL.createObjectURL(photo);
              setUserPhoto(imageUrl);
            })
            .catch(() => setUserPhoto(""));
        })
        .catch((err) => {
          console.log(err);
        });
      // getAzureToken().then((response: any) => {
      //   console.log("hice la llamada 1", response);
      //   getUserPhoto(response.access_token).then((response) => {
      //     console.log(response);
      //     setUserPhoto(response);
      //   });
      // });
      loginAzure(token)
        .then((responseBackend) => {
          if (responseBackend) {
            let position: string = "Por defecto";
            dispatch(userLogin(responseBackend.data, token, position));
            localStorage.setItem(
              "aedas_user",
              JSON.stringify(responseBackend.data)
            );
            setUserInfo(responseBackend.data.employee);
          } else {
            toast.error("No tienes permiso");
            navigate("/login");
            setIsLoading(false);
          }
        })
        .catch((error) => console.log(error));
    }
  }, [token]);

  useEffect(() => {
    if (!isAuthenticated && !isLoading) {
      signinRedirect();
    }
  }, [isAuthenticated, isLoading]);

  const handleLogOut = () => {
    setIsLoading(true);
    signoutRedirect().finally(() => setIsLoading(false));
  };

  const menu = useMemo(
    () => [
      {
        name: "Home",
        localPath: "/",
        icon: <HomeIcon />,
        onClick: navigate,
        canView: true,
        shortName: "Home",
        hasPermissions: true,
      },
      {
        name: "Sociedades",
        localPath: "/sociedades",
        icon: <IoBusinessSharp />,
        onClick: navigate,
        canView:
          validatePermission(userSelector, [
            userIDRolDict.lector,
            userIDRolDict.editor,
            userIDRolDict.admin,
          ]) && hasPermission("societaria.access"),
        shortName: "Soc.",
        hasPermissions: true,
      },
      {
        name: "Nueva Sociedad",
        localPath: "/constituir-sociedad/0",
        icon: <IoMdAddCircleOutline />,
        onClick: navigate,
        canView:
          validatePermission(userSelector, [
            userIDRolDict.editor,
            userIDRolDict.admin,
          ]) && hasPermission("societaria.access"),
        shortName: "Nueva Soc.",
        hasPermissions: true,
      },
      {
        name: "Editar sociedad",
        localPath: "/editar-sociedad",
        icon: <AiFillEdit />,
        onClick: navigate,
        canView:
          validatePermission(userSelector, [
            userIDRolDict.editor,
            userIDRolDict.admin,
          ]) && hasPermission("societaria.access"),
        shortName: "Edit. Soc.",
        hasPermissions: true,
      },
      {
        name: "Acuerdos de Línea de Crédito",
        localPath: "/cfa",
        icon: <MdOutlinePayments />,
        onClick: navigate,
        canView:
          validatePermission(userSelector, everyRol) &&
          hasPermission("societaria.access"),
        shortName: "CFAs",
        hasPermissions: true,
      },
      {
        name: "Nuevo litigio",
        localPath: process.env.REACT_APP_LITIGIOS + "/nuevo",
        icon: <IoMdAddCircleOutline />,
        onClick: goToURL,
        canView: hasPermission("newLitigation.access"),
        shortName: "Nuevo Litigio",
        hasPermissions: hasPermission("newLitigation.access"),
      },
      {
        name: "Litigios",
        localPath: process.env.REACT_APP_LITIGIOS + "/lista",
        icon: <PiGavelFill />,
        shortName: "Litigios",
        onClick: goToURL,
        canView: hasPermission("litigationTable.access"),
        hasPermissions: hasPermission("litigationTable.access"),
      },
      {
        name: "Gestión de usuarios Litigios",
        localPath: process.env.REACT_APP_LITIGIOS + "/colaboradores",
        icon: <IoIosPerson />,
        shortName: "Gestión",
        onClick: goToURL,
        canView:
          hasPermission("manageEnterprises.access") ||
          hasPermission("manageUsers.access"),
        hasPermissions:
          hasPermission("manageEnterprises.access") ||
          hasPermission("manageUsers.access"),
      },
      {
        name: "Nueva Reclamación",
        localPath: process.env.REACT_APP_LITIGIOS + "/reclamacion/nueva",
        icon: <IoMdAddCircleOutline />,
        shortName: "Nueva Reclam.",
        onClick: goToURL,
        canView: hasPermission("newComplaint.access"),
        hasPermissions: hasPermission("newComplaint.access"),
      },
      {
        name: "Reclamaciones",
        localPath: process.env.REACT_APP_LITIGIOS + "/reclamacion/lista",
        icon: <HiOutlineClipboardDocumentList />,
        shortName: "Reclam.",
        onClick: goToURL,
        canView: hasPermission("complaintTable.access"),
        hasPermissions: hasPermission("complaintTable.access"),
      },
      {
        name: "Estadísticas",
        localPath: process.env.REACT_APP_LITIGIOS + "/estadisticas",
        icon: <BsGraphUp />,
        shortName: "Estad.",
        onClick: goToURL,
        canView: hasPermission("statistics.access"),
        hasPermissions: hasPermission("statistics.access"),
      },
    ],
    [isLoading, userSelector, token]
  );

  if (isLoadingAll || isPermissionLoading || isLoading) {
    return (
      <div className="w-100 h-100 d-flex justify-content-center">
        <AedasLoading></AedasLoading>
      </div>
    );
  }

  return (
    <AedasLayout
      handleLogOut={handleLogOut}
      user={{
        displayName: user?.profile.name ?? "Usuario",
        url_img: userPhoto !== "" ? userPhoto : null,
      }}
      menu={menu}
      projectName="Sociedades"
    >
      <div className="w-full h-full p-4">
        <Toaster position="top-right" duration={2000} richColors></Toaster>
        <AppRoutes hasPermission={hasPermission}></AppRoutes>
      </div>
    </AedasLayout>
  );
};
