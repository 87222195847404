/**
 * Obtiene la cabecera por defecto para enviar peticiones.
 *
 * @returns Object
 */
export const getDefaultConfigHeader = () => {
  return {
    headers: {
      Authorization: `Bearer ${
        JSON.parse(localStorage.getItem("aedas_user") ?? "{}").token
      }`,
    },
  };
};

export const getDefaultConfigHeaderLit = () => {
  return {
    headers: {
      Authorization: `Bearer ${
        JSON.parse(
          sessionStorage.getItem(
            `oidc.user:${process.env.REACT_APP_KEYCLOAK_LIT}:aedas-litigios`
          ) ?? "{}"
        ).access_token
      }`,
    },
  };
};
