export const addCorporate = (corporate: any) => {
  return {
    type: "@corporate/add",
    payload: corporate,
  };
};
  

export const updateCorporate = (corporate: any) => {
  return {
    type: "@corporate/update",
    payload: corporate,
  };
};
  
export const addBodyCorporate = (bodyCorporate: any) => {
    return {
        type: "@body-corporate/add",
        payload: bodyCorporate,
    };
};

export const addMembers = (member: any) => {
  return {
      type: "@members/add",
      payload: member,
  };
};

export const resetMembers = () => {
  return {
      type: "@members/reset",
  };
};
  
export const addInicialCapital = (capital: any) => {
  return {
      type: "@inicial-capital/add",
      payload: capital,
  };
};
  
export const resetCorporation = () => {
  return {
      type: "@reset-corporation",
  };
};

