import { Routes, Route, Navigate } from "react-router-dom";
import { CorporateMain } from "./Components/Static/Corporate/CorporateMain";
import { InfoCorporate } from "./Components/Static/Corporate/InfoCorporate/InfoCorporate";
import { NewBodyCorporation } from "./Components/Static/Corporate/NewBodyCorporation/NewBodyCorporation";
import { NewCorporate } from "./Components/Static/Corporate/NewCorporate/NewCorporate";
import { AddMembers } from "./Components/Static/Corporate/AddMembers/AddMembers";
import { InicialCapital } from "./Components/Static/Corporate/InicialCapital/InicialCapital";
import { CFAsList } from "./Pages/CFAs/CFAsList";
import { Report } from "./Components/Static/Report/Report";
import { Users } from "./Components/Static/Users/Users";
import { EditCoorporateMain } from "./Components/Static/Corporate/EditCorporate/EditCoorporateMain";
import { CFAInputs } from "./Pages/CFAs/CFAInputs";
import { HistoricCFA } from "./Pages/CFAs/HistoricCFA";
import { MainMenu } from "./Pages/MainMenu";

interface Props {
  hasPermission: (perm: string) => void;
}

export function AppRoutes({ hasPermission }: Props) {
  const canView = hasPermission("societaria.access");
  console.log(canView);

  return (
    <Routes>
      <Route path="" element={<MainMenu />}></Route>
      <Route
        path="/sociedades"
        element={
          <PrivateRoute canView={canView}>
            <CorporateMain />
          </PrivateRoute>
        }
      ></Route>
      <Route
        path="/sociedad/:corporate_id"
        element={
          <PrivateRoute canView={canView}>
            <InfoCorporate></InfoCorporate>
          </PrivateRoute>
        }
      ></Route>
      <Route
        path="/constituir-sociedad/:corporate_id"
        element={
          <PrivateRoute canView={canView}>
            <NewCorporate />
          </PrivateRoute>
        }
      ></Route>
      <Route
        path="/alta-organo-societario/:corporate_id"
        element={
          <PrivateRoute canView={canView}>
            <NewBodyCorporation />
          </PrivateRoute>
        }
      ></Route>
      <Route
        path="/agregar-miembros/:corporate_id"
        element={
          <PrivateRoute canView={canView}>
            <AddMembers />
          </PrivateRoute>
        }
      ></Route>
      <Route
        path="/capital-inicial/:corporate_id"
        element={
          <PrivateRoute canView={canView}>
            <InicialCapital />
          </PrivateRoute>
        }
      ></Route>
      <Route
        path="/informe/:corporate_id"
        element={
          <PrivateRoute canView={canView}>
            <Report />
          </PrivateRoute>
        }
      ></Route>
      <Route
        path="/cfa"
        element={
          <PrivateRoute canView={canView}>
            <CFAsList />
          </PrivateRoute>
        }
      ></Route>
      <Route
        path="/editar-sociedad"
        element={
          <PrivateRoute canView={canView}>
            <EditCoorporateMain />
          </PrivateRoute>
        }
      ></Route>
      <Route
        path="/usuarios"
        element={
          <PrivateRoute canView={canView}>
            <Users />
          </PrivateRoute>
        }
      ></Route>
      <Route
        path="/cfa/nuevo"
        element={
          <PrivateRoute canView={canView}>
            <CFAInputs edit={false} />
          </PrivateRoute>
        }
      ></Route>
      <Route
        path="/editar-cfa/:cfa_id"
        element={
          <PrivateRoute canView={canView}>
            <CFAInputs amendment={true} edit={true} />
          </PrivateRoute>
        }
      ></Route>
      <Route
        path="/cierre-mes/:cfa_id"
        element={
          <PrivateRoute canView={canView}>
            <CFAInputs monthly={true} edit={true} />
          </PrivateRoute>
        }
      ></Route>
      <Route
        path="/historico-cfa/:cfa_id"
        element={
          <PrivateRoute canView={canView}>
            <HistoricCFA />
          </PrivateRoute>
        }
      ></Route>
    </Routes>
  );
}

const PrivateRoute = ({ canView, children }: any) => {
  console.log(canView);
  console.log("entro");
  console.log(children);

  return canView ? children : <Navigate to="/" />;
};
