import React, { useEffect, useState } from 'react';
import moment from "moment";

import { GridColDef, GridRenderEditCellParams, GridRenderCellParams } from "@mui/x-data-grid";
import Button from "@mui/material/Button";

import { TabListComponent } from '../../../../Shared/TabsListComponent/TabListComponent';
import { useFetch, useIsLoading } from '../../../../../Hooks/useFetch';

import { getMilestonesByCorpID, getDocumentByID } from '../../../../../Services/CorporateServices/CorporateServices';


import './../InfoCorporate.css';

export const Milestones = (props: any) => {
	const [ milestones, isFetchingMilestones ] = useFetch(getMilestonesByCorpID, props.corporate.id_corporation);

	const onCellClick = (params: any) => {
		if (params.field === 'document') return;
	}

	const handleDownloadFile = (params: any, e: any) => {
		getDocumentByID(params.row?.document?.id)
			.then((response) => {
				let pdfWindow = window.open("");
				pdfWindow?.document.write(
					`<iframe
							title='${response.data[0].name}'
							width='100%'
							height='100%'
							src='data:application/pdf;base64, ${encodeURI(response.data[0].document)} '
					></iframe>`
				)
			});
		}

		const columns: GridColDef[] = [
			{
				field: "id_milestone",
				headerName: "ID",
				width: 9,
				hide: true,
				editable: false,
			},
			{
				field: "type",
				headerName: "Descripción",
				type: "string",
				editable: false,
				flex: 1,
			},
			{
				field: "date",
				headerName: "Fecha",
				type: "string",
				renderCell: (params: GridRenderEditCellParams) => {
					return <div>{ moment(params.row.document.date_registration).format("YYYY/MM/DD") }</div>;
				},
				// width: 50,
				editable: false,
				flex: 1,
			},
			{
				field: "document",
				headerName: "Escritura Asociada",
				flex: 1,
				renderCell: (params: GridRenderCellParams) => {
					return  (
						<> { params.row.document ? (
									<Button
											onClick={(e: any) => handleDownloadFile(params, e)}
											color="primary"
											className="button grey-button"
											id={`button-file-${params.row.id_milestone}`}
											key={params.row.id_milestone}
									>
											Ver
									</Button> 
								) : <></> }
						</> )             
				}
			},
    ]

    return (
        <TabListComponent 
					corporate={props.corporate}
					title={'Histórico de Hitos Societarios'}   
					columns={columns}
					rows={milestones} 
					getRowId={(row: any) =>  row.id_milestone}
					onCellClick={onCellClick}
				/>
    )
}
