import React, { useState } from 'react';
import { useParams } from "react-router-dom";

import { Corporate } from '../../../Shared/SharedForms/Corporate';

import './../CorporateMain.css';

export const NewCorporate = () => {
  const params: any = useParams();  
  const [ id, setId ] = useState(params ? params.corporate_id : 'nueva');

  return (
    <>
      <div className="h-100 m-4">

        <div className="main-container p-3">
          <h1 className="main-title">Constitución Sociedad</h1>
          <Corporate id={id} edit={false}/>
        </div>
      </div>
    </>
  )
}
