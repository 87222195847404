import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import moment from "moment";
import { toast } from "react-toastify";

import { useFormik } from "formik";
import * as yup from "yup";
import { useSelector, useDispatch, useStore } from "react-redux";
import {
  addMembers,
  resetMembers,
} from "../../../../Redux/Corporate/CorporateAction";

import Box from "@mui/material/Box";
import {
  TextField,
  Autocomplete,
  Button,
  Checkbox,
  Switch,
  FormGroup,
  FormControl,
  FormLabel,
  FormControlLabel,
} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";

import {
  GridColDef,
  GridRenderEditCellParams,
  GridValueGetterParams,
} from "@mui/x-data-grid";

import AedasLoading from "../../../Shared/AedasLoading/AedasLoading";
import DataGridEditabled from "../../../Shared/DataGridEditabled/DataGridEditabled";

import { useFetch, useIsLoading } from "../../../../Hooks/useFetch";
import {
  addMember,
  getCorporateBodyById,
  getPeople,
  getPeoplePositions,
  addNewCorporateBody,
} from "./../../../../Services/CorporateServices/CorporateServices";

import "./../CorporateMain.css";

const validationSchema = yup.object({
  name: yup.string().min(2, "Campo obligatorio").required("Campo obligatorio"),
  registered_date: yup.string().required("Campo obligatorio"),
  // cif: yup.string().min(9, 'Debes ingresar 9 caracteres').max(9, 'Debes ingresar 9 caracteres'),
  // nif: yup.string().min(9, 'Debes ingresar 9 caracteres').max(9, 'Debes ingresar 9 caracteres'),
  // cif: yup.string().when("nif", {
  //   is: (nif: any) => !nif || nif.length != 9,
  //   then: yup.string()
  //           .min(9, 'Debes ingresar 9 caracteres')
  //           .max(9, 'Debes ingresar 9 caracteres'),
  //   otherwise: yup.string()
  //           .min(9, 'Debes ingresar 9 caracteres')
  //           .max(9, 'Debes ingresar 9 caracteres'),
  // }),
  // nif: yup.string().when("cif", {
  //   is: (cif: any) => !cif || cif.length != 9,
  //   then: yup.string()
  //           .min(9, 'Debes ingresar 9 caracteres')
  //           .max(9, 'Debes ingresar 9 caracteres'),
  //   otherwise: yup.string()
  //           .min(9, 'Debes ingresar 9 caracteres')
  //           .max(9, 'Debes ingresar 9 caracteres'),
  // }),

  // legal_representative: yup.string().min(2, 'Campo obligatorio'),
  // nationality: yup.string().min(2, 'Campo obligatorio'),
});

export const AddMembers = () => {
  const navigate = useNavigate();
  const params: any = useParams();
  const dispatch = useDispatch();
  const store = useStore();

  // const [ isLoading, setIsLoading ] = useState(false);

  const id = params ? params.corporate_id : "nueva";

  const { bodyCorporate, members } = useSelector(
    (state: any) => state.corporateReducer
  );
  const [typeBodyCorporate, setTypeBodyCorporate] = useState<any>({});
  // const [ peoplePositions, isFetchingPositions ] = useFetch(getPeoplePositions);
  // const isLoading = useIsLoading([isFetchingPositions]);
  const [isLoadingButton, setIsLoadingButton] = useState(false);
  const [newBodyCorporateCreated, setNewBodyCorporateCreated] = useState(null);

  const peoplePositions = [
    "Administrador",
    "Consejero",
    "Consejero delegado",
    "Presidente",
    "Secretario",
  ];
  const isLoading = false;

  useEffect(() => {
    switch (bodyCorporate.type) {
      case "Consejo de administración":
        setTypeBodyCorporate({
          header: "Miembros del ",
          minMembers: 3,
          maxMembers: 12,
          position: "Consejero",
        });
        break;

      case "Administradores mancomunados":
      case "Administradores solidarios":
        setTypeBodyCorporate({
          header: "",
          minMembers: 2,
          maxMembers: 1000,
          position: "Administrador",
        });
        break;

      default:
        setTypeBodyCorporate({
          header: "",
          minMembers: 1,
          maxMembers: 1,
          position: "Administrador",
        });
    }
  }, [isLoading, bodyCorporate]);

  const formik = useFormik({
    initialValues: {
      name: " ",
      registered_date: " ",
      cif: "",
      nif: "",
      position:
        bodyCorporate.type != "Consejo de administración"
          ? peoplePositions[0]
          : peoplePositions[1],
      juridical: false,
      legal_representative: " ",
      nationality: " ",
      email: " ",
      telephone: " ",
    },

    validationSchema: validationSchema,

    onSubmit: async (values: any) => {
      console.log(values);
      const member = {
        ...values,
        id_member: store.getState().corporateReducer.members.length + 1,
        position:
          values.position && values.position !== ""
            ? values.position
            : typeBodyCorporate.position,
        cif_nif:
          values.cif && values.cif !== ""
            ? values.cif
            : values.nif && values.nif !== ""
            ? values.nif
            : null,
      };

      if (
        members.length < typeBodyCorporate.maxMembers ||
        members.length < typeBodyCorporate.minMembers
      ) {
        dispatch(addMembers(member));
        formik.resetForm();
      }
    },
  });

  const handlePersonTypeOptions = () => {
    formik.setFieldValue("juridical", !formik.values.juridical);
  };

  const handleCustomSubmit = () => {
    if (!isLoadingButton && newBodyCorporateCreated) {
      navigate(`/sociedades`);
      return;
    }

    setIsLoadingButton(true);

    const storedData = store.getState().corporateReducer;

    console.log(storedData.bodyCorporate, storedData.members, params);
    addNewCorporateBody(
      storedData.bodyCorporate,
      storedData.members,
      params.corporate_id
    ).then((response: any) => {
      toast.success(`Se ha añadido el organo de administración`);
      setNewBodyCorporateCreated(response.data.id_corporate_body);
      setIsLoadingButton(false);
    });
  };

  const handleNextStep = () => {
    navigate(`/capital-inicial/${newBodyCorporateCreated}`);
  };

  const columns: GridColDef[] = [
    {
      field: "id_member",
      headerName: "ID",
      width: 9,
      hide: true,
      editable: false,
    },
    {
      field: "name",
      headerName: "Nombre",
      type: "string",
      // width: 50,
      editable: false,
      flex: 1,
    },
    {
      field: "juridical",
      headerName: "Es persona jurídica",
      type: "string",
      renderCell: (params: GridRenderEditCellParams) => {
        return <div>{params.row.juridical ? "Si" : "No"}</div>;
      },
      // width: 50,
      editable: false,
      flex: 1,
    },
    {
      field: "legal_representative",
      headerName: "Representante",
      type: "string",
      // width: 50,
      editable: false,
      flex: 1,
    },
    {
      field: "position",
      headerName: "Cargo",
      type: "string",
      // width: 50,
      editable: false,
      flex: 1,
    },
    {
      field: "registered_date",
      headerName: "Fecha",
      type: "string",
      renderCell: (params: GridRenderEditCellParams) => {
        return (
          <div>{moment(params.row.registered_date).format("YYYY/MM/DD")}</div>
        );
      },
      // width: 50,
      editable: false,
      flex: 1,
    },
  ];

  const handleSave = (params: any) => {
    dispatch(resetMembers());

    params.removed.map((removedId: number) => {
      members.map((member: any) => {
        if (member.id_member !== removedId) {
          dispatch(addMembers(member));
        }
      });
    });
  };

  const onCellClick = (params: any, event: any) => {
    console.log(event, params);
  };

  return (
    <>
      {isLoading && typeBodyCorporate !== null ? (
        <AedasLoading></AedasLoading>
      ) : (
        <div className="h-100 m-4">
          <div className="main-container p-3">
            <h1 className="main-title">
              {typeBodyCorporate
                ? `Añadir ${typeBodyCorporate.header}${bodyCorporate.type}`
                : ""}
            </h1>
            <Box
              component="form"
              sx={{
                "& .MuiTextField-root": {
                  my: 2,
                  mx: 4,
                  width: "40%",
                  background: "#fff",
                },
                width: "100%",
                mx: "auto",
                my: 2,
                textAlign: "left !important",
                // backgroundColor: "#fff"
              }}
              noValidate
              autoComplete="on"
              className="text-center"
              onSubmit={formik.handleSubmit}
            >
              {members.length < typeBodyCorporate.maxMembers ? (
                <>
                  <div
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <TextField
                      id="name"
                      label="Nombre Completo *"
                      variant="outlined"
                      style={{ margin: "1rem", width: "60%", flexGrow: "1" }}
                      value={formik.values.name}
                      onChange={formik.handleChange}
                      error={formik.touched.name && Boolean(formik.errors.name)}
                      helperText="En caso de persona jurídica, indicar abreviatura de tipo de sociedad, p. ej. Sociedad S.A."
                    />

                    <FormControlLabel
                      value={formik.values.juridical}
                      style={{
                        margin: "1rem",
                        flexDirection: "column-reverse",
                        alignItems: "flex-start",
                        justifyContent: "flex-end",
                        width: "15%",
                      }}
                      className="check-box-label"
                      control={
                        <Switch
                          id="is-jurirical"
                          checked={formik.values.juridical}
                          onClick={() =>
                            formik.setFieldValue(
                              "juridical",
                              !formik.values.juridical
                            )
                          }
                        />
                      }
                      label="Persona Juridica"
                    />

                    <TextField
                      id="registered_date"
                      label="Fecha de registro *"
                      InputLabelProps={{ shrink: true }}
                      style={{ margin: "1rem", width: "30%" }}
                      variant="outlined"
                      type={"date"}
                      value={formik.values.registered_date}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.registered_date &&
                        Boolean(formik.errors.registered_date)
                      }
                      helperText={
                        formik.touched.registered_date &&
                        formik.errors.registered_date
                      }
                    />

                    {typeBodyCorporate.position !== "Administrador" ? (
                      <Autocomplete
                        id="position"
                        className="autocomplete coporate"
                        options={peoplePositions.filter(
                          (option: any) => option !== "Administrador"
                        )}
                        onChange={(e, value) => {
                          formik.setFieldValue("position", value);
                        }}
                        style={{
                          width: "40%",
                          display: "flex",
                          justifyContent: "flex-end",
                          margin: "1rem",
                        }}
                        value={formik.values.position}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Cargo"
                            style={{ margin: "0", width: "100%" }}
                            variant="outlined"
                            error={
                              formik.touched.position &&
                              Boolean(formik.errors.position)
                            }
                            helperText={
                              formik.touched.position && formik.errors.position
                            }
                          />
                        )}
                      />
                    ) : (
                      <></>
                    )}
                  </div>
                  <div
                    className={
                      formik.values.juridical
                        ? "juridical-container"
                        : "natural-container"
                    }
                  >
                    <div className="juridical-inputs">
                      <TextField
                        id="cif"
                        label="NIF *"
                        variant="outlined"
                        style={{ margin: "1rem", width: "50%" }}
                        value={formik.values.cif}
                        onChange={formik.handleChange}
                        error={formik.touched.cif && Boolean(formik.errors.cif)}
                        helperText="Formato A12345678, sin espacios, ni guiones"
                      />
                      <TextField
                        id="legal_representative"
                        label="Representante *"
                        variant="outlined"
                        style={{ margin: "1rem", width: "50%" }}
                        value={formik.values.legal_representative}
                        onChange={formik.handleChange}
                        error={
                          formik.touched.legal_representative &&
                          Boolean(formik.errors.legal_representative)
                        }
                        helperText={
                          formik.touched.legal_representative &&
                          formik.errors.legal_representative
                        }
                      />
                    </div>
                    <div className="natural-inputs">
                      <div style={{ display: "flex" }}>
                        <TextField
                          id="nif"
                          label="NIF *"
                          variant="outlined"
                          style={{ margin: "1rem", width: "50%" }}
                          value={formik.values.nif}
                          onChange={formik.handleChange}
                          error={
                            formik.touched.nif && Boolean(formik.errors.nif)
                          }
                          helperText="Formato A12345678, sin espacios, ni guiones"
                        />
                        <TextField
                          id="nationality"
                          label="Nacionalidad *"
                          variant="outlined"
                          style={{ margin: "1rem", width: "50%" }}
                          value={formik.values.nationality}
                          onChange={formik.handleChange}
                          error={
                            formik.touched.nationality &&
                            Boolean(formik.errors.nationality)
                          }
                          helperText={
                            formik.touched.nationality &&
                            formik.errors.nationality
                          }
                        />
                      </div>
                    </div>
                  </div>
                </>
              ) : (
                <></>
              )}

              <div style={{ textAlign: "end", marginRight: "1rem" }}>
                {members.length < typeBodyCorporate.maxMembers ? (
                  <Button
                    type="submit"
                    color="primary"
                    className="button blue-button"
                  >
                    Añadir Miembro
                  </Button>
                ) : (
                  <></>
                )}
              </div>
            </Box>

            {members.length < typeBodyCorporate.maxMembers ? (
              <hr
                style={{
                  width: "50%",
                  margin: "0 auto 3rem",
                  color: "#a8a8a8",
                }}
              />
            ) : (
              <></>
            )}

            {members.length > 0 ? (
              <DataGridEditabled
                onlyEdit={false}
                edit={true}
                deleteIcon={true}
                singleIcon={false}
                showRemoveIcon={true}
                showHeader={true}
                rows={members}
                columns={columns}
                handleSave={handleSave}
                getRowId={(row: any) => row.id_member}
                onCellClick={(params: any, e: any) => onCellClick(params, e)}
              ></DataGridEditabled>
            ) : (
              <></>
            )}

            {members.length >= typeBodyCorporate.minMembers ? (
              <div style={{ textAlign: "end", marginRight: "1rem" }}>
                {/* { newBodyCorporateCreated != null ? 
                    <Button
                      color="primary"
                      className="button grey-button"
                      onClick={() => history.push(`/alta-organo-societario/${id}`)}
                    >
                      Volver
                    </Button> : <></> } */}

                <LoadingButton
                  onClick={handleCustomSubmit}
                  color="primary"
                  loading={isLoadingButton}
                  loadingPosition="start"
                  variant="contained"
                  className={`button blue-button ${
                    isLoadingButton ? "is-loading" : ""
                  }`}
                >
                  {isLoadingButton
                    ? "Enviando…"
                    : !isLoadingButton && newBodyCorporateCreated != null
                    ? "Volver a sociedades"
                    : "Anadir organo de administración"}
                </LoadingButton>

                {!isLoadingButton && newBodyCorporateCreated != null ? (
                  <Button
                    className="button blue-button"
                    onClick={handleNextStep}
                  >
                    Siguiente
                  </Button>
                ) : (
                  <></>
                )}
              </div>
            ) : (
              <></>
            )}
          </div>
        </div>
      )}
    </>
  );
};
