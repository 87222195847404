import axios from "axios";
import { toast } from "react-toastify";
import { getDefaultConfigHeader } from "../../Utils/headerDefaultConfigPetition";


export const getAllCFAs = () => {
    return axios.get(
      process.env.REACT_APP_API_BACKEND + "/cfa/",
      getDefaultConfigHeader()
    );
};

export const getCFAById = (params: any) => {
  // if ( !params.id_cfa ) return;
  return axios.get(
    process.env.REACT_APP_API_BACKEND + "/cfa/" 
    + parseInt(params.cfa_id),
    getDefaultConfigHeader()
  );
};


export const getCFAsByCorporateId = (params: any) => {  
    return axios.get(
      process.env.REACT_APP_API_BACKEND + "/cfa/" 
      + parseInt(params.corporate_id),
      getDefaultConfigHeader()
    );
  };


  export const getHistoryByCFAId = (params: any) => {
    return axios.get(
      process.env.REACT_APP_API_BACKEND + "/cfa/history/"
      + parseInt(params.cfa_id),
      getDefaultConfigHeader()
    );
};


export const getHistoryByCorporateId = (params: any) => {
  return axios.get(
    process.env.REACT_APP_API_BACKEND + "/cfa/history/"
    + parseInt(params.corporate_id),
    getDefaultConfigHeader()
  );
};


  export const addCFA = (body: any) => {
    return axios.post(
        process.env.REACT_APP_API_BACKEND + "/cfa/",
        body,
        getDefaultConfigHeader()
      )
      .catch((error: any) => {
        if (error.response) {
          toast.error(error.response.data.detail);
        };
      })
  };

  
  export const updateCFA = (body: any) => {
    return axios.put(
      process.env.REACT_APP_API_BACKEND + "/cfa/" + body.id_cfa,
      body,
      getDefaultConfigHeader() 
    )
    .catch((error: any) => {
      if (error.response) {
        toast.error(error.response.data.detail);
      };
    })
  };

  
  export const removeCFA = (id_cfa: number, id_employee: number) => {
    return axios.delete(
      process.env.REACT_APP_API_BACKEND + "/cfa/" + id_cfa + "/" + id_employee,
      getDefaultConfigHeader()
    )
    .catch((error: any) => {
      if (error.response) {
        toast.error(error.response.data.detail);
      };
    })
  };