import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import moment from "moment";
import { useFormik } from "formik";
import * as yup from "yup";
import { useDispatch } from "react-redux";
import { resetCorporation } from "../../Redux/Corporate/CorporateAction";

import {
  GridColDef,
  GridRenderEditCellParams,
  GridValueGetterParams,
} from "@mui/x-data-grid";
import Box from "@mui/material/Box";
import {
  TextField,
  Autocomplete,
  Button,
  Switch,
  FormControlLabel,
} from "@mui/material";

import AedasLoading from "../../Components/Shared/AedasLoading/AedasLoading";
import DataGridEditabled from "../../Components/Shared/DataGridEditabled/DataGridEditabled";

import { InfoCFAs } from "./InfoCFAs";

import { useFetch, useIsLoading } from "../../Hooks/useFetch";
import { getAllCFAs } from "../../Services/CFAServices/CFAServices";
import "../../Components/Static/Corporate/CorporateMain.css";

export const CFAsList = () => {
  const [allCfas, isFetchingCfas, updateAllCfas] = useFetch(getAllCFAs);
  const isLoading = useIsLoading([isFetchingCfas]);

  const [cfas, setCfas] = useState<any[]>(allCfas);
  const [cfasFiltered, setCfasFiltered] = useState<any[]>(allCfas);
  const userIdRol = useSelector(
    (state: any) => state.userReducer.user.employee.id_rol
  );
  const isCfaEditor = userIdRol === 14 ? true : false;
  const isAdmin = userIdRol === 12 ? true : false;
  const isEditor = userIdRol === 11 ? true : false;

  const navigate = useNavigate();

  useEffect(() => {
    if (!isLoading) {
      setCfas(
        isAdmin ? allCfas : allCfas.filter((cfa: any) => cfa.activated === true)
      );
      setCfasFiltered(allCfas);
    }
  }, [isLoading]);

  const formik = useFormik({
    initialValues: {
      status: null,
      fromDate: "",
      untilDate: "",
      show_all_cfas: isAdmin ? true : false,
    },

    validationSchema: null,

    onSubmit: async (values: any) => {
      console.log(values);
      const inputDateFrom = new Date(values.fromDate);
      const inputDateTo = new Date(values.untilDate);
      let filteredCfas = [];

      if (
        values.fromDate != null &&
        values.fromDate != "" &&
        values.untilDate != null &&
        values.untilDate != ""
      ) {
        filteredCfas.push(
          ...allCfas.filter(
            (cfa: any) =>
              new Date(cfa.signature_date) >= inputDateFrom &&
              new Date(cfa.signature_date) <= inputDateTo
          )
        );
      } else if (values.untilDate != null && values.untilDate != "") {
        filteredCfas.push(
          ...allCfas.filter(
            (cfa: any) => new Date(cfa.signature_date) <= inputDateTo
          )
        );
      } else if (values.fromDate != null && values.fromDate != "") {
        filteredCfas.push(
          ...allCfas.filter(
            (cfa: any) => new Date(cfa.signature_date) >= inputDateFrom
          )
        );
      } else filteredCfas = allCfas;

      if (values.status != null && values.status != "") {
        filteredCfas = filteredCfas.filter(
          (cfa: any) => cfa.status === values.status.status
        );
      }

      console.log(filteredCfas);
      setCfas([...new Set(filteredCfas)]);
      setCfasFiltered([...new Set(filteredCfas)]);
    },
  });

  const handleShowDeleted = () => {
    formik.setFieldValue("show_all_cfas", !formik.values.show_all_cfas);

    if (!isLoading && cfas) {
      const cfaActivated = cfas.filter((cfa: any) => cfa.activated === true);

      if (formik.values.show_all_cfas) {
        setCfas(cfaActivated);
      } else setCfas(cfasFiltered);
    }
  };

  const onCellClick = (event: any) => {
    console.log(event.row);
  };

  const handleResetFilter = () => {
    formik.resetForm();
    setCfas(
      isAdmin ? allCfas : allCfas.filter((cfa: any) => cfa.activated === true)
    );
  };

  return (
    <>
      {isLoading ? (
        <AedasLoading></AedasLoading>
      ) : (
        <div className="h-100 m-4">
          <div className="main-container p-3">
            <h1 className="main-title">Acuerdos de Línea de Crédito</h1>
            <Box
              component="form"
              sx={{
                width: "90%",
                mx: "auto",
                my: 2,
                display: "flex",
                flexDirection: "column",
                alignItems: "stretch",
              }}
              noValidate
              autoComplete="on"
              className="text-center"
              onSubmit={formik.handleSubmit}
            >
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <Autocomplete
                  id="status"
                  className="autocomplete coporate"
                  options={
                    [
                      { status: true, name: "Activo" },
                      { status: false, name: "Terminado" },
                    ] ?? []
                  }
                  getOptionLabel={(option: any) => option.name}
                  onChange={(e, value) => {
                    formik.setFieldValue("status", value);
                  }}
                  style={{ width: "50%", margin: "1rem", flexGrow: "2" }}
                  value={formik.values.status}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Estado"
                      error={
                        formik.touched.status && Boolean(formik.errors.status)
                      }
                      helperText={formik.touched.status && formik.errors.status}
                    />
                  )}
                />

                <TextField
                  id="fromDate"
                  label="Desde"
                  InputLabelProps={{ shrink: true }}
                  variant="outlined"
                  style={{ width: "40%", margin: "1rem" }}
                  type={"date"}
                  value={formik.values.fromDate}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.fromDate && Boolean(formik.errors.fromDate)
                  }
                  helperText={formik.touched.fromDate && formik.errors.fromDate}
                />

                <TextField
                  id="untilDate"
                  label="Hasta"
                  variant="outlined"
                  style={{ width: "40%", margin: "1rem" }}
                  InputLabelProps={{ shrink: true }}
                  type={"date"}
                  value={formik.values.untilDate}
                  inputProps={{
                    min: formik.values.fromDate,
                  }}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.untilDate && Boolean(formik.errors.untilDate)
                  }
                  helperText={
                    formik.touched.untilDate && formik.errors.untilDate
                  }
                />

                {isAdmin ? (
                  <FormControlLabel
                    value={formik.values.show_all_cfas}
                    style={{
                      margin: "1rem",
                      flexDirection: "column-reverse",
                      alignItems: "center",
                      justifyContent: "flex-end",
                      width: "15%",
                    }}
                    className="check-box-label"
                    control={
                      <Switch
                        id="show-all-cfas"
                        checked={formik.values.show_all_cfas}
                        onClick={handleShowDeleted}
                      />
                    }
                    label="Incluir CFAs borrados"
                  />
                ) : (
                  <></>
                )}
              </div>

              <div
                id="filter-buttons"
                className="button-container text-center"
                style={{ margin: "0 auto", display: "block" }}
              >
                <Button
                  onClick={handleResetFilter}
                  color="primary"
                  className="button grey-button"
                >
                  Reestablecer
                </Button>

                <Button
                  type="submit"
                  color="primary"
                  className="button blue-button"
                >
                  Filtrar
                </Button>

                {isCfaEditor || isEditor || isAdmin ? (
                  <Button
                    color="primary"
                    className="button blue-button"
                    onClick={() => navigate(`/cfa/nuevo`)}
                  >
                    Nuevo CFA
                  </Button>
                ) : (
                  <div></div>
                )}
              </div>
            </Box>

            <InfoCFAs
              list={true}
              cfas={cfas}
              export={true}
              updateAllCfas={updateAllCfas}
              isFetchingCfas={isFetchingCfas}
            />
          </div>
        </div>
      )}
    </>
  );
};
