import React from 'react';
import moment from "moment";
import { toast } from 'react-toastify';

import { GridColDef, GridRenderEditCellParams, GridRenderCellParams } from "@mui/x-data-grid";
import Button from "@mui/material/Button";

import { TabListComponent } from '../../../../Shared/TabsListComponent/TabListComponent';
import { getCorporateBody, getDocumentByID } from '../../../../../Services/CorporateServices/CorporateServices';
import { useFetch, useIsLoading } from '../../../../../Hooks/useFetch';


import './../InfoCorporate.css';

export const CorporateBody = (props: any) => {
		const [corporateBody, isFetchingCorporateBody] = useFetch(getCorporateBody, props.corporate.id_corporation);

		const handleDownloadFile = (params: any, e: any) => {
			if (params.row.document_regulation) {
				getDocumentByID(params.row.document_regulation.id)
					.then(response => {
						let pdfWindow = window.open("");
						pdfWindow?.document.write(
							`<iframe
									title='${response.data[0].name}'
									width='100%'
									height='100%'
									src='data:application/pdf;base64, ${encodeURI(response.data[0].document)} '
							></iframe>`
						)  
					})
					.catch(() => toast.error("No se ha podido cargar el documento."))     
			}   
		}

		const columns: GridColDef[] = [
			{
				field: "id_corporate_body",
				headerName: "ID",
				width: 9,
				hide: true,
				editable: false,
			},
			{
				field: "type",
				headerName: "Nombre",
				type: "string",
				editable: false,
				flex: 1,
			},
			{
				field: "registered_date",
				headerName: "Fecha Alta",
				type: "string",
				renderCell: (params: GridRenderEditCellParams) => {
						return <div>{ moment(params.row.registered_date).format("DD/MM/YYYY") }</div>;
				},
				editable: false,
				flex: 1,
			},
			{
				field: "deregistered_date",
				headerName: "Fecha Baja",
				type: "string",
				renderCell: (params: GridRenderEditCellParams) => {
					return <div>{ params.row.deregistered_date ? moment(params.row.deregistered_date).format("DD/MM/YYYY") : '-'}</div>;
				},
				editable: false,
				flex: 1,
			},
			{
				field: "document_regulation",
				headerName: "Reglamentos internos",
				flex: 1,
				renderCell: (params: GridRenderCellParams) => {
					return (
						<> { params.row.document_regulation != null ? (
							<Button
								onClick={(e: any) => handleDownloadFile(params, e)}
								color="primary"
								className="button grey-button"
								id={`button-file-${params.row.id_corporate_body}`}
								key={params.row.id_corporate_body}
							>
								Ver
							</Button> 
							) : <span> - </span> }
						</> )             
				}
			},
			{
				field: "administration_body",
				headerName: "Órgano Administración",
				type: "text",
      	renderCell: (params: GridRenderEditCellParams) => {
        	return <>{ params.row.administration_body == true ? "Si" : "No" }</>;
      	},
				editable: false,
				flex: 1,
			},
		]
	
    return (
        <TabListComponent 
        	corporate={props.corporate}
        	title={'Órgano de Administración'} 
        	columns={columns}
        	rows={corporateBody}
			getRowId={(row: any) => row.id_corporate_body}

        />
    )
}
