import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import moment from "moment";
import { useFormik } from "formik";
import * as yup from "yup";
import { useSelector, useDispatch, useStore } from "react-redux";
import { addBodyCorporate } from "../../../../Redux/Corporate/CorporateAction";

import {
  GridColDef,
  GridRenderEditCellParams,
  GridValueGetterParams,
} from "@mui/x-data-grid";
import Box from "@mui/material/Box";
import {
  TextField,
  Autocomplete,
  MenuItem,
  Select,
  FormControl,
} from "@mui/material";
import Button from "@mui/material/Button";

import AedasLoading from "../../../Shared/AedasLoading/AedasLoading";
import DataGridEditabled from "../../../Shared/DataGridEditabled/DataGridEditabled";

import { useFetch, useIsLoading } from "../../../../Hooks/useFetch";
import {
  getCorporateBody,
  getCorporateBodytypes,
  addNewCorporateBody,
} from "../../../../Services/CorporateServices/CorporateServices";

const validationSchema = yup.object({
  registered_date: yup.string().required("Campo obligatorio"),
});

export const NewBodyCorporation = (props: any) => {
  const navigate = useNavigate();
  const params: any = useParams();
  const store = useStore();

  const id = params ? params.corporate_id : "nueva";
  const corporate = useSelector((state: any) => state.corporateReducer);
  const dispatch = useDispatch();

  // const [corporateBody, isFetchingCorporateBody] = useFetch(getCorporateBody, params.corporate_id);
  const storeCorporateBodyData =
    store.getState().corporateReducer.bodyCorporate;
  const [corpBodyTypes, isFetchingCorpBodyTypes] = useFetch(
    getCorporateBodytypes
  );

  const isLoading = useIsLoading([
    isFetchingCorpBodyTypes,
    // isFetchingCorporateBody,
  ]);

  const [files, setFiles] = useState<any[]>([]);

  const handleUploadFiles = (files: any, event: any) => {
    let filesUploadedList = event.currentTarget.files;

    let fileListBase64: any[] = [];

    Object.values(filesUploadedList).forEach((file: any) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        let fileBase64 = reader.result?.toString() || "";
        fileListBase64.push(fileBase64);
      };
    });

    formik.handleChange(event);
    setFiles(fileListBase64);
  };

  const formik = useFormik({
    initialValues: {
      type: storeCorporateBodyData.type,
      registered_date: storeCorporateBodyData.registered_date,
      deregistered_date: storeCorporateBodyData.deregistered_date,
      comments: storeCorporateBodyData.comments,
      files: files,
    },

    validationSchema: validationSchema,

    onSubmit: async (values: any) => {
      const filesToSend = files.map((file64: string) => {
        return file64.replace("data:", "").replace(/^.+,/, "");
      });

      let documentObject = null;

      if (values.files.length > 0) {
        documentObject = {
          //Remove "C:\\fakepath\\" and extension from file name
          name: values.files.slice(12, -4),
          document: filesToSend,
        };
      }

      dispatch(
        addBodyCorporate({
          ...values,
          id_corporation: id,
          document_regulation: documentObject,
        })
      );

      // addNewCorporateBody(values,  params.corporate_id);
      navigate(`/agregar-miembros/${id}`);
      // addNewCorporateBody(values, filesToSend, params.corporate_id).then((response: any) =>  history.push(`/agregar-miembros/${params.corporate_id}/${response.data.id_corporate_body}/`));
    },
  });

  // console.log(corporateBody);

  const columns: GridColDef[] = [
    {
      field: "id_corporation",
      headerName: "ID",
      width: 9,
      hide: true,
      editable: false,
    },
    {
      field: "type",
      headerName: "Nombre",
      type: "string",
      // width: 50,
      editable: false,
      flex: 1,
    },
    {
      field: "comments",
      headerName: "Representante",
      type: "string",
      // width: 50,
      editable: false,
      flex: 1,
    },
    {
      field: "administration_body",
      headerName: "Cargo",
      type: "string",
      // width: 50,
      editable: false,
      flex: 1,
    },
    {
      field: "registered_date",
      headerName: "Desde",
      type: "string",
      renderCell: (params: GridRenderEditCellParams) => {
        return (
          <div>{moment(params.row.creation_date).format("YYYY/MM/DD")}</div>
        );
      },
      // width: 50,
      editable: false,
      flex: 1,
    },
    {
      field: "unregistered_date",
      headerName: "Hasta",
      type: "string",
      renderCell: (params: GridRenderEditCellParams) => {
        return (
          <div>
            {params.row.end_date
              ? moment(params.row.end_date).format("YYYY/MM/DD")
              : "-"}
          </div>
        );
      },
      // width: 50,
      editable: false,
      flex: 1,
    },
  ];

  return (
    <>
      {isLoading ? (
        <AedasLoading></AedasLoading>
      ) : (
        <div className="h-100 m-4">
          {/* <a onClick={() => history.push(`/constituir-sociedad/${id}`)}>volver</a> */}
          {/* <a onClick={() => history.push(`/agregar-miembros/${id}`)}>crear</a> */}

          <div className="main-container p-3">
            <h1 className="main-title">Alta órgano de administración</h1>
            <Box
              component="form"
              sx={{
                "& .MuiTextField-root": {
                  my: 2,
                  mx: 4,
                  width: "40%",
                  background: "#fff",
                },
                width: "100%",
                mx: "auto",
                my: 2,
                textAlign: "left !important",
                // backgroundColor: "#fff"
              }}
              noValidate
              autoComplete="on"
              className="text-center"
              onSubmit={formik.handleSubmit}
            >
              <Autocomplete
                id="type"
                className="autocomplete coporate"
                options={corpBodyTypes}
                // getOptionLabel={(option: any) => option.registered_name}
                onChange={(e, value) => {
                  formik.setFieldValue("type", value);
                }}
                value={formik.values.type}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Nombre órgano *"
                    style={{ margin: "1rem 2rem", width: "40%" }}
                    error={formik.touched.type && Boolean(formik.errors.type)}
                    helperText={formik.touched.type && formik.errors.type}
                  />
                )}
              />
              <TextField
                id="registered_date"
                label="Fecha de alta *"
                InputLabelProps={{ shrink: true }}
                variant="outlined"
                type={"date"}
                value={formik.values.registered_date}
                onChange={formik.handleChange}
                error={
                  formik.touched.registered_date &&
                  Boolean(formik.errors.registered_date)
                }
                helperText={
                  formik.touched.registered_date &&
                  formik.errors.registered_date
                }
              />

              <TextField
                id="deregistered_date"
                label="Fecha de baja"
                variant="outlined"
                style={{ marginLeft: "0 important!" }}
                InputLabelProps={{ shrink: true }}
                type={"date"}
                value={formik.values.deregistered_date}
                inputProps={{
                  min: formik.values.registered_date,
                }}
                onChange={formik.handleChange}
                error={
                  formik.touched.deregistered_date &&
                  Boolean(formik.errors.deregistered_date)
                }
                helperText={
                  formik.touched.deregistered_date &&
                  formik.errors.deregistered_date
                }
              />

              <TextField
                id="files"
                label="Reglamento interno"
                variant="outlined"
                inputProps={{
                  multiple: true,
                  accept: "application/pdf",
                }}
                style={{ marginLeft: "0 important!" }}
                InputLabelProps={{ shrink: true }}
                type={"file"}
                onChange={(event: any) => {
                  handleUploadFiles(event.currentTarget.files[0], event);
                }}
                disabled={
                  formik.values.type != "Consejo de administración"
                    ? true
                    : false
                }
                // value={formik.values.files}
                // onChange={formik.handleChange}
                error={formik.touched.files && Boolean(formik.errors.files)}
                helperText={formik.touched.files && formik.errors.files}
              />

              <TextField
                id="comments"
                label="Anotaciones"
                variant="outlined"
                style={{ marginLeft: "0 important!" }}
                value={formik.values.comments}
                onChange={formik.handleChange}
                error={
                  formik.touched.comments && Boolean(formik.errors.comments)
                }
                helperText={formik.touched.comments && formik.errors.comments}
              />
              <div
                className="mx-5 my-3"
                style={{
                  display: "inline-flex",
                  width: "40%",
                  margin: "1rem 2rem !important",
                }}
              >
                {/* <Button
          color="primary"
          className="button grey-button"
          onClick={() => history.push(`/constituir-sociedad/${id}`)}
        >
          Volver
        </Button> */}
                <div style={{ width: "100%", textAlign: "center" }}>
                  <Button
                    type="submit"
                    color="primary"
                    style={{ marginRight: "0px !important" }}
                    className="button blue-button"
                  >
                    {props.edit ? "Editar" : "Siguiente"}
                  </Button>
                </div>
              </div>
            </Box>
          </div>
        </div>
      )}
    </>
  );
};
