import axios from "axios";
import { toast } from "react-toastify";

import { suffixRemover } from './../../Utils/stringTransform';
import { getDefaultConfigHeader } from "../../Utils/headerDefaultConfigPetition";

const suffixNameDict: any = {
  "Limitada": "S.L.",
  "Anónima": "S.A.",
  "Cooperativa": "S.Coop.",
  "Comanditaria": "S.Com."
} 

export const getAllCorporates = () => {
    return axios.get(
      process.env.REACT_APP_API_BACKEND + "/corporation/",
      getDefaultConfigHeader()
    );
};
  
export const getCorporateById = (params: any) => {
  return axios.get(
    process.env.REACT_APP_API_BACKEND + "/corporation/" 
    + parseInt(params.corporate_id),
    getDefaultConfigHeader()
  );
};

export const getCorporatesTypes = () => {
  return axios.get(
    process.env.REACT_APP_API_BACKEND + "/corporation/types/",
    getDefaultConfigHeader()
  );
};

export const addNewCorporate = (data: any) => {
  const creationDate = data.creation_date ? data.creation_date + "T00:00" : null;
  const dateProtocol =  data.date_protocol ? data.date_protocol + "T00:00" : null; 
  const endAudit =  data.audited.end_date ? data.audited.end_date + "T00:00" : null; 
    
  const body = {
    active: true,
    registered_name: `${data.registered_name.trim()} ${suffixNameDict[data.type]}`,
    type: data.type,
    cif: data.cif,
    controlled: data.controlled,
    group: data.group,
    audited: data.audited.value,
    audited_person: [{
      name: data.audited.person[0].name,
      juridical: data.audited.person[0].juridical,
			value: data.audited.value,
      cif_nif: data.audited.person[0].cif_nif,
      date_end: endAudit
		}],
    creation_date: creationDate,
    protocol_number: data.protocol_number,
    date_protocol: dateProtocol,
    notary_name: data.notary_name,
    registration_info: data.registration_info,
    corporate_purpose: data.corporate_purpose,
    full_address: data.full_address,
    postal_code: data.postal_code,
    country: data.country,
    province: data.province,
    town: data.town,
    comments: data.comments,
    document_creation: {
      name: data.document_creation.name,
      document: data.document_creation.document[0],
      date_registration: data.document_creation.date_registration,
    },
  }

  return axios.post(
    process.env.REACT_APP_API_BACKEND + "/corporation/",
    body,
    getDefaultConfigHeader()
  );
};


export const updateCorporateService = (data:any, id_corporation: number) => {
  const creationDate = data.creation_date && data.creation_date !== "Invalid date" ? data.creation_date + "T00:00" : null;
  const endDate = data.end_date && data.end_date !== "Invalid date" ? data.end_date + "T00:00" : null;
  const newRegisteredName = suffixRemover(data.registered_name);
  
  //will be remove when put endpoint be fixed
  const endAudit =  data.audited.end_date ? data.audited.end_date : null; 
  const dateProtocol =  data.date_protocol && data.date_protocol !== "Invalid date" ? data.date_protocol + "T00:00" : null; 

  const body = {
    active: true,
    id_corporation: id_corporation,
    registered_name: `${newRegisteredName} ${suffixNameDict[data.type]}`,
    type: data.type,
    creation_date: creationDate,
    end_date: endDate,
    corporate_purpose: data.corporate_purpose,
    full_address: data.full_address,
    postal_code: data.postal_code,
    country: data.country,
    province: data.province,
    town: data.town,
    comments: data.comments,
 
    document_registered_name: data.document_registered_name ? {
      name: data.document_registered_name.name,
      document: data.document_registered_name.document[0],
      date_registration: data.document_registered_name.date_registration,
    } : null,
    document_type: data.document_type ? {
      name: data.document_type.name,
      document: data.document_type.document[0],
      date_registration: data.document_type.date_registration,
    } : null,
    document_end: data.document_end ? {
      name: data.document_end.name,
      document: data.document_end.document[0],
      date_registration: data.document_end.date_registration,
    } : null,
    document_corporate_purpose: data.document_corporate_purpose ? {
      name:data.document_corporate_purpose.name,
      document:data.document_corporate_purpose.document[0],
      date_registration: data.document_corporate_purpose.date_registration,
    } : null,
    document_address: data.document_address ? {
      name: data.document_address.name,
      document: data.document_address.document[0],
      date_registration: data.document_address.date_registration,
    } : null,
    document_address_international: data.document_address_international ? {
      name: data.document_address_international.name,
      document: data.document_address_international.document[0],
      date_registration: data.document_address_international.date_registration,
    } : null,
    
    //will be remove when put endpoint be fixed
    cif: data.cif,
    controlled: data.controlled,
    group: data.group,
    audited: data.audited.value,
    audited_person: [{
      name: data.audited.person[0].name,
      juridical: data.audited.person[0].juridical,
			value: data.audited.value,
      cif_nif: data.audited.person[0].cif_nif,
      date_end: endAudit
		}],
    protocol_number: data.protocol_number,
    date_protocol: dateProtocol,
    notary_name: data.notary_name,
    registration_info: data.registration_info,
  }

  return axios.put(
    process.env.REACT_APP_API_BACKEND + "/corporation/" + id_corporation,
    body,
    getDefaultConfigHeader()
  );
};


export const getCorporateBody = (corporate_id: number) => {
  return axios.get(
    process.env.REACT_APP_API_BACKEND + "/corporate_body/" + corporate_id,
    getDefaultConfigHeader()
  ).catch((error: any) => {
    if (error.response.status == 404) {
      return error.response.status;
    }
  });
};

export const getCorporateBodytypes = () => {
  return axios.get(
    process.env.REACT_APP_API_BACKEND + "/corporate_body/types/",
    getDefaultConfigHeader()
  );
};

export const addNewCorporateBody = (data: any, members: any, corporate_id: number) => {
  const registeredDate = data.registered_date ? data.registered_date + "T00:00" : null;
  const deregisteredDate = data.deregistered_date && data.deregistered_date !== ' ' ? data.deregistered_date + "T00:00" : null;
  
  const body = {
    type: data.type,
    registered_date: registeredDate,
    deregistered_date: deregisteredDate,
    comments: data.comments,
    administration_body: true,
    document_regulation: data.document_regulation ? {
      name: data.document_regulation.name,
      document: data.document_regulation.document[0]
    } : null,
    person: members, 
  }

  return axios.post(
    process.env.REACT_APP_API_BACKEND + "/corporate_body/" + corporate_id,
    body,
    getDefaultConfigHeader()
  );
};


export const getCorporateBodyById = (params: any) => {
  return axios.get(
    process.env.REACT_APP_API_BACKEND + "/corporate_body/" 
    + parseInt(params.corporate_id) 
    + '/' + parseInt(params.corporate_body_id),
    getDefaultConfigHeader()
  );
};

export const getPeople = (params: any) => {
  return axios.get(
    process.env.REACT_APP_API_BACKEND + 
    "/person/" + parseInt(params.corporate_id) 
    + '/' + parseInt(params.corporate_body_id),
    getDefaultConfigHeader()
  );
};

export const getPeoplePositions = () => {
  return axios.get(
    process.env.REACT_APP_API_BACKEND + "/person/positions/",
    getDefaultConfigHeader()
  );
};


export const addMember = (data: any, corporate_id: number, corporate_body_id: number) => {
  const registeredDate = data.registered_date ? data.registered_date + "T00:00" : null;

  const body = {
    name: data.name,
    juridical: data.juridical,
    legal_representative: data.representative,
    date_registration: registeredDate,
  }

  return axios.post(
    process.env.REACT_APP_API_BACKEND + 
    "/person/" + corporate_id 
    + '/' + corporate_body_id,
    body,
    getDefaultConfigHeader()
  );
};


export const addCapital = (data: any, corporate_id: number) => {
  const fromDate = data.date_from ? data.date_from + "T00:00" : null;
  const untilDate = data.date_to ? data.date_to + "T00:00" : null;

  const body = {
    description: data.description,
    nominal_value: data.nominal_value,
    currency: data.currency,
    shares_issued: data.shares_issued,
    total_capital_issued: data.total_capital,
    shares_partially_paid: data.shares_partially_paid,
    percentage_paid: data.percentage_paid,
    total_partially_paid: data.total_partially_paid,
    political_rights: data.political_rights,
    comments: data.comments,
    shares_comments: data.shares_comments,
    date_from: fromDate,
    date_to: untilDate,
    id_corporation: corporate_id,
    person: data.person,
  }

  return axios.post(
    process.env.REACT_APP_API_BACKEND + 
    "/capital/" + corporate_id, 
    body,
    getDefaultConfigHeader()
  );
};

export const getCurrency = () => {
  return axios.get(
    process.env.REACT_APP_API_BACKEND + "/currencies/",
    getDefaultConfigHeader()
  );
};

export const getMilestonesTypes = () => {
  return axios.get(
    process.env.REACT_APP_API_BACKEND + "/milestone/types/",
    getDefaultConfigHeader(),
  );
};

export const getCapitalByCorpID = (corporate_id: number) => {
  return axios.get(
    process.env.REACT_APP_API_BACKEND + "/capital/" + corporate_id,
    getDefaultConfigHeader()
  ).catch((error: any) => {
    if (error.response.status == 404) {
      return error.response.status;
    }
  });
};


export const getMilestonesByCorpID = (corporate_id: number) => {
  return axios.get(
    process.env.REACT_APP_API_BACKEND + "/milestone/" + corporate_id,
    getDefaultConfigHeader()
  );
};

export const getDocumentByID = (document_id: number) => {
  return axios.get(
    process.env.REACT_APP_API_BACKEND + "/document/" + document_id,
    getDefaultConfigHeader()
  );
};

export const getReportByCorporationID = (params: any) => {
  return axios.get(
    process.env.REACT_APP_API_BACKEND + "/report/" + parseInt(params.corporate_id),
    getDefaultConfigHeader()
  );
};

export const removeCorporation = (id_corporation: number) => {
  return axios.delete(
    process.env.REACT_APP_API_BACKEND + "/corporation/" + id_corporation,
    getDefaultConfigHeader()
  )
  .catch((error: any) => {
    if (error.response) {
      toast.error(error.response.data.detail);
    };
  })
};