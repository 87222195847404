import { getDefaultConfigHeader } from "../../Utils/headerDefaultConfigPetition";
import axios, { AxiosRequestConfig } from "axios";
export const keycloak = axios.create({
  baseURL: `${process.env.REACT_APP_KEYCLOAK}`,
});
export const ms = axios.create({
  baseURL: "https://graph.microsoft.com",
});
export const getAzureToken = (token: any) => {
  return keycloak
    .get("/realms/aedas-litigios/broker/oidc-aedas-litigios/token", {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((res: any) => res.data);
};

export const getUserPhoto = (token: any) => {
  const config: AxiosRequestConfig<any> = {
    headers: { Authorization: `Bearer ${token}` },
    responseType: "blob",
  };

  return ms.get("/v1.0/me/photo/$value/", config).then((res: any) => res.data);
};
