import React, { useState } from 'react';

import Box from "@mui/material/Box";
import { TextField } from "@mui/material";

import DataGridEditabled from '../DataGridEditabled/DataGridEditabled';
import './../../Static/Corporate/CorporateMain.css'


export const TabListComponent = (props: any) => {
  // console.log(props);
  
  const onCellClick = (event: any) => {
    if ( event.row.id_capital ) {
      const memberTab = document.querySelector('.react-tabs__tab.members-tab') as HTMLElement | null;
      memberTab?.click()
    } else {
      props.onCellClick(event);
    }
  };

  return (
    <div className="h-100" style={{ width: "100%", maxWidth: "100%"}}>
      <div style={{ width: "100%", paddingBottom: "2rem"}}>
        { props.capital ? (

          <h4 style={{
              fontWeight: "600", 
              fontSize: "1.1rem",          
              margin: "0 auto 3rem",
              outline: "0.5px solid #092e3b",
              width: "fit-content",
              padding: "0.5rem 2rem",
              borderRadius: "4px"
            }}
            >
              <span style={{fontWeight: "400",}} >Capital Social: </span>
              { props.rows[0].currency === "EUR" 
                ? new Intl.NumberFormat('es-ES', { style: 'currency', currency: 'EUR', useGrouping: true }).format(props.capital) 
                : `${props.capital} ${props.rows[0].currency}` }
          </h4>

        ) : <></> }


        <h4 style={{fontWeight: "600", fontSize: "1.2rem", textAlign: "center", marginBottom: "1rem"}}>{props.title}</h4>

        <DataGridEditabled
          onlyEdit={false}
          showHeader={false}
          rows={props.rows ?? []}
          columns={props.columns}
          onCellClick={onCellClick}
          getRowId={props.getRowId}
          exportIcon={props.exportIcon}
          initialState={props.initialState}
					disableSelectionOnClick={props.disableSelectionOnClick}
        />
      </div>
    </div>
  )
}

