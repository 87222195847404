import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import { useFormik } from "formik";
import * as yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { resetCorporation } from "./../../../Redux/Corporate/CorporateAction";

import {
  GridColDef,
  GridRenderEditCellParams,
  GridValueGetterParams,
} from "@mui/x-data-grid";
import { FormControlLabel, Switch } from "@mui/material";

import AedasLoading from "../../Shared/AedasLoading/AedasLoading";
import DataGridEditabled from "../../Shared/DataGridEditabled/DataGridEditabled";

import { useFetch, useIsLoading } from "../../../Hooks/useFetch";
import { getAllCorporates } from "./../../../Services/CorporateServices/CorporateServices";

import "./CorporateMain.css";

export const CorporateMain = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [allCorporates, isFetchingCorporates] = useFetch(getAllCorporates);
  const isLoading = useIsLoading([isFetchingCorporates]);

  const [corporates, setCorporates] = useState<any[]>([]);
  const userRol = useSelector(
    (state: any) => state.userReducer.user.employee.id_rol
  );
  const isAdmin = userRol === 12 ? true : false;
  const [showDeletedCorp, setShowDeletedCorp] = useState(isAdmin);

  useEffect(() => {
    if (!isLoading) {
      setCorporates(
        isAdmin
          ? allCorporates
          : allCorporates.filter(
              (corporate: any) => corporate.activated === true
            )
      );
    }
  }, [isLoading]);

  useEffect(() => {
    if (!isLoading) {
      if (showDeletedCorp) {
        setCorporates(allCorporates);
      } else
        setCorporates(
          allCorporates.filter((corporate: any) => corporate.activated === true)
        );
    }
  }, [showDeletedCorp]);

  const onCellClick = (event: any) => {
    console.log(event.row);
  };

  const columns: GridColDef[] = [
    {
      field: "id_corporation",
      headerName: "ID",
      width: 9,
      hide: true,
      editable: false,
    },
    {
      field: "registered_name",
      headerName: "Sociedad",
      type: "string",
      editable: false,
      flex: 1,
    },
    {
      field: "cif",
      headerName: "NIF",
      type: "string",
      editable: false,
      flex: 1,
    },
    {
      field: "country",
      headerName: "País",
      type: "string",
      editable: false,
      flex: 1,
    },
    {
      field: "creation_date",
      headerName: "F. constitución",
      type: "string",
      renderCell: (params: GridRenderEditCellParams) => {
        return (
          <div>{moment(params.row.creation_date).format("YYYY/MM/DD")}</div>
        );
      },
      editable: false,
      flex: 1,
    },
    {
      field: "end_date",
      headerName: "Hasta",
      type: "string",
      renderCell: (params: GridRenderEditCellParams) => {
        return (
          <div>
            {params.row.end_date
              ? moment(params.row.end_date).format("YYYY/MM/DD")
              : "-"}
          </div>
        );
      },
      editable: false,
      flex: 1,
    },
  ];

  return (
    <>
      {isLoading ? (
        <AedasLoading></AedasLoading>
      ) : (
        <div className="h-100 m-4">
          <div className="main-container p-3">
            <h1 className={`main-title ${isAdmin ? "mb-2" : "mb-5"}`}>
              Sociedades
            </h1>

            {isAdmin ? (
              <FormControlLabel
                value={showDeletedCorp}
                style={{
                  margin: "0 auto 1rem",
                  flexDirection: "row-reverse",
                  alignItems: "center",
                  justifyContent: "center",
                }}
                className="check-box-label"
                control={
                  <Switch
                    id="is-jurirical"
                    checked={showDeletedCorp}
                    onClick={() => setShowDeletedCorp(!showDeletedCorp)}
                  />
                }
                label="Incluir Sociedades Borradas"
              />
            ) : (
              <></>
            )}

            <DataGridEditabled
              onlyEdit={false}
              showHeader={false}
              rows={corporates ?? []}
              columns={columns}
              onCellClick={(params: any) =>
                navigate(`/sociedad/${params.row.id_corporation}`)
              }
              getRowId={(row: any) => row.id_corporation}
            ></DataGridEditabled>
          </div>
        </div>
      )}
    </>
  );
};
