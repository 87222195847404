export const validatePermission = (user: any, id_rol_array: any) => {
  return user
    ? id_rol_array.find((rol: any) => rol === user.employee.id_rol)
      ? true
      : false
    : false;
};

export const goToURL = (url: string) => {
  window.location.href = url;
  return null;
};
