import React, { useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";

import { Tab, TabList, Tabs, TabPanel } from "react-tabs";
import { Button } from "@mui/material";

import AedasLoading from "../../../Shared/AedasLoading/AedasLoading";

import { useFetch, useIsLoading } from "../../../../Hooks/useFetch";
import {
  getCorporateById,
  getReportByCorporationID,
} from "../../../../Services/CorporateServices/CorporateServices";
import { getAllCFAs } from "../../../../Services/CFAServices/CFAServices";

import { GeneralInfo } from "./GeneralInfo/GeneralInfo";
import { InfoCFAs } from "../../../../Pages/CFAs/InfoCFAs";
import { Capital } from "./Capital/Capital";
import { Members } from "./Members/Members";
import { CorporateBody } from "./CorporateBody/CorporateBody";
import { Milestones } from "./Milestones/Milestones";
import { Licences } from "./Licences/Licences";
import { Branches } from "./Branches/Branches";
import { NewCorporate } from "../NewCorporate/NewCorporate";
import { Corporate } from "../../../Shared/SharedForms/Corporate";

import "react-tabs/style/react-tabs.css";
import "./InfoCorporate.css";

export const InfoCorporate = () => {
  const params: any = useParams();
  const [corporate, isFetchingCorporate, updateCorporate] = useFetch(
    getCorporateById,
    params
  );
  const [report, isFetchingReport] = useFetch(getReportByCorporationID, params);
  const [cfas, isFetchingCFAs, updateCfas] = useFetch(getAllCFAs);

  const navigate = useNavigate();

  const isLoading = useIsLoading([
    isFetchingCorporate,
    isFetchingReport,
    isFetchingCFAs,
  ]);

  return (
    <>
      {isLoading ? (
        <AedasLoading></AedasLoading>
      ) : (
        <div className="h-100 m-4">
          <div className="main-container p-3">
            <div>
              <h4 className="main-title" style={{ marginBottom: "1rem" }}>
                Información Sociedad: {corporate.registered_name}
              </h4>
            </div>

            <Tabs id="info-corporate">
              <TabList>
                <Tab onClick={updateCorporate}>Información General</Tab>
                <Tab>CFAs</Tab>
                {report.capital ? <Tab>Capital Social</Tab> : <></>}
                {report.capital ? (
                  <Tab className="react-tabs__tab members-tab" id="members-tab">
                    Participaciones
                  </Tab>
                ) : (
                  <></>
                )}
                {report.corporate_body ? (
                  <Tab>Órgano de administración</Tab>
                ) : (
                  <></>
                )}
                <Tab>Hitos societarios</Tab>
                {/* <Tab>Poderes</Tab> */}
              </TabList>

              <TabPanel>
                <div className="general-info-corporate">
                  <Corporate corporate={corporate} edit={true} />
                  <div style={{ textAlign: "center" }}>
                    <Button
                      color="primary"
                      className="button blue-button"
                      onClick={() =>
                        navigate(`/informe/${corporate.id_corporation}`)
                      }
                    >
                      Ver Informe de sociedad
                    </Button>
                  </div>
                </div>
              </TabPanel>

              <TabPanel>
                <InfoCFAs
                  cfas={cfas.filter((cfa: any) => cfa.activated === true)}
                  list={false}
                  export={false}
                  updateAllCfas={updateCfas}
                  isFetchingCfas={isFetchingCFAs}
                />
              </TabPanel>
              {report.capital ? (
                <>
                  <TabPanel>
                    <Capital corporate={corporate} />
                  </TabPanel>

                  <TabPanel>
                    <Members corporate={corporate} />
                  </TabPanel>
                </>
              ) : (
                <></>
              )}

              {report.corporate_body ? (
                <TabPanel>
                  <CorporateBody corporate={corporate} />
                </TabPanel>
              ) : (
                <></>
              )}

              <TabPanel>
                <Milestones corporate={corporate} />
              </TabPanel>

              {/* <TabPanel>
                <Licences corporate={corporate} />
              </TabPanel> */}
            </Tabs>
          </div>
        </div>
      )}
    </>
  );
};
