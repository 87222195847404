import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import moment from 'moment';
import { useFetch, useIsLoading } from '../../Hooks/useFetch';
import { toast } from 'react-toastify';

import { GridColDef, GridRenderCellParams } from "@mui/x-data-grid";
import Button from "@mui/material/Button";

import AedasLoading from '../../Components/Shared/AedasLoading/AedasLoading';

import { TabListComponent } from '../../Components/Shared/TabsListComponent/TabListComponent';
import { InfoCFAs } from './InfoCFAs';
import { getHistoryByCFAId, getHistoryByCorporateId } from '../../Services/CFAServices/CFAServices'
import { getAllCorporates, getDocumentByID } from '../../Services/CorporateServices/CorporateServices';
import { getAllEmployees } from '../../Services/UserService/UserService';

export const HistoricCFA = (props: any) => {
  const params: any = useParams();
	const historicServices = props.listByCorporate ? getHistoryByCorporateId : getHistoryByCFAId;

	  	
  const [ historyCfa, isFetchingHistoryCfa] = useFetch(historicServices, params);
	const [ corporates, isFetchingCorporates ] = useFetch(getAllCorporates);
	const [ employees, isFetchingEmployees ] = useFetch(getAllEmployees);

  const isLoading = useIsLoading([isFetchingHistoryCfa, isFetchingCorporates, isFetchingEmployees]);
  const [ isLoadingProcessData, setisLoadingProcessData ] = useState(true);
	const [ rowsData, setRowsData ] = useState([]);

	const rolIDDict: any = {
    10: 'Lector Completo',
    11: 'Editor Completo',
    12: 'Administrador',
    13: 'Lector CFA',
    14: 'Editor CFA',
    15: 'Contable',
	}

	const fieldDict: any = {
		"modified_date":  "Actualización",
		"id_employee":  "Usuario",
		"status":  "Estado",
		"termination_date":  "F. Finalización",
		"creditor":  "Acreedor",
		"debtor":  "Deudor",
		"credit_facility_disposals":  "Importe Dispuesto",
		"credit_facility_available_amount":  "Importe Disponible",
		"credit_facility_maximum_amount":  "Importe Máximo",
		"rate":  "Interés Aplicado",
		"rate_type":  "Tipo Interés",
		"period_interests":  "Intereses Período PG",
		"earned_interests":  "Intereses Devengados No Pagados",
		"signature_date":  "F. Firma",
		"effective_date":  "F. Efectiva",
		"repayment_date":  "F. Reembolso",
		"comments":  "Comentarios",
		"activated": "CFA Borrado"
	}

	const fieldFormatter = (field: string, value: any) => {
		const formatter: any = {
			"status": value ? "Activo" : "Terminado",
			"creditor": value ? corporates.find((corporate: any) => corporate.id_corporation === value)?.registered_name : "-",
			"debtor": value ? corporates.find((corporate: any) => corporate.id_corporation === value)?.registered_name : "-",
			"signature_date": value ? moment(value).format("DD/MM/YYYY") : "-",
			"effective_date": value ? moment(value).format("DD/MM/YYYY") : "-",
			"repayment_date": value ? moment(value).format("DD/MM/YYYY") : "-",
			"termination_date": value ? moment(value).format("DD/MM/YYYY") : "-",
			"credit_facility_maximum_amount": value ? new Intl.NumberFormat('es-ES', { style: 'currency', currency: 'EUR' }).format(value) : "-",
			"credit_facility_disposals": value ? new Intl.NumberFormat('es-ES', { style: 'currency', currency: 'EUR' }).format(value) : "-",
			"credit_facility_available_amount": value ? new Intl.NumberFormat('es-ES', { style: 'currency', currency: 'EUR' }).format(value) : "-",
			"rate": value ? `${value}%` : "-",
			"rate_type": value,
			"period_interests": value ? new Intl.NumberFormat('es-ES', { style: 'currency', currency: 'EUR' }).format(value) : "-",
			"earned_interests": value ? new Intl.NumberFormat('es-ES', { style: 'currency', currency: 'EUR' }).format(value) : "-",
			"comments": value,
		}

		return formatter[field]
	}

	const processData = () => {
		let diferences: any = [];
		
		historyCfa.forEach((element: any, index: number) => {
			
			if ( index - 1 >= 0 ) {
				let prevElement: any = historyCfa[index - 1];
				
				Object.keys(element).map((key: string) => {
					if (element[key] != prevElement[key] && key != "id_employee" && key != "id_cfa_history" && key != "created_at") {

						let difItem: any = {}; 

						difItem.id = diferences.length + 1;
						difItem.id_employee = element.id_employee;
						difItem.field = key;
						difItem.created_at = element.created_at;
						difItem.new_value = element[key];
						difItem.old_value = prevElement[key];
						
						diferences.push(difItem);
					}
				})
			}
		})

		let auxArray: any = diferences.map((item: any) => {
			const editor = employees.find((employee: any) => employee.id === item.id_employee)		

			return {
				id: item.id,
				modified_date: moment(item.created_at).format("DD/MM/YYYY"),
				id_employee: editor ? editor.display_name : "-",
				employee_rol: editor ? rolIDDict[editor.id_rol] : "_",
				field: fieldDict[item.field],
				new_value: fieldFormatter(item.field, item.new_value),
				old_value: fieldFormatter(item.field, item.old_value),
			}
		});

		setRowsData(auxArray);
    setisLoadingProcessData(false);
	}

	useEffect(() => {
    if (!isLoading) {
      processData();
    } else {
      setisLoadingProcessData(true);
    }
  }, [isLoading]);

	const columns: GridColDef[] = [
		{
			field: "id",
			headerName: "ID",
			width: 0,
		},
		{
			field: "modified_date",
			headerName: "Actualización",
			type: "string",
			width: 50,
      flex: 1,
		},
		{
			field: "id_employee",
			headerName: "Usuario",
			type: "string",
      flex: 1,
		},
		{
			field: "employee_rol",
			headerName: "Rol",
			type: "string",
      flex: 1,
		},
		{
			field: "field",
			headerName: "Campo",
			type: "string",
      flex: 1,
		},
		{
			field: "new_value",
			headerName: "Nuevo valor",
			type: "string",
      flex: 1,
		},

		{
			field: "old_value",
			headerName: "Valor anterior",
			type: "string",
			width: 50,
			flex: 1,
		}
  ]

	return (
    <>
			{ isLoading || isLoadingProcessData
				? <AedasLoading/>
				: (
					<div className={`${!props.listByCorporate ? "h-100 m-4" : ""}`}>
						<div className={`${!props.listByCorporate ? "main-container p-3" : ""}`}>
							{ !props.listByCorporate 
								?	<h1 className="main-title">Historial del Acuerdo de Línea de Crédito {params?.cfa_id}</h1> 
								// : <h4 >Historial de CFAs {corporates.find((corporate: any) =>  corporate.id_corporation == params?.corporate_id)?.registered_name}</h4> }
								: <></> }

							{	rowsData.length > 0 ? 
								<TabListComponent
									columns={columns}
									rows={rowsData || []}
									exportIcon={props.export ?? true}
									initialState={{
										columns: {
											columnVisibilityModel: {
												id: false,
												repayment_date: false,
												effective_date: false,
												credit_facility_maximum_amount: false,
												credit_facility_available_amount: false,
											},
										},
									}}
									disableSelectionOnClick={true}
								/> : <p className='text-center m-4'>No se registra historial para este CFA</p> }
						</div>
					</div> 
				) 
			}
    </>
  )
}
