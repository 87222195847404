export const suffixRemover = (corporation_name: string) => {
  let corporationNameUpdated = corporation_name;

  interface Dictionary {
    [key: string]: string;
  }

  const suffixNameDict: Dictionary = {
    Limitada: "S.L.",
    Anónima: "S.A.",
    Cooperativa: "S.Coop.",
    Comanditaria: "S.Com.",
  };

  for (const [type, suffix] of Object.entries(suffixNameDict)) {
    if (corporation_name.split(suffix).length > 1) {
      corporationNameUpdated = corporation_name.split(suffix)[0].trim();
    }
  }

  return corporationNameUpdated;
};

export const userIDRolDict: any = {
  lector: 10,
  editor: 11,
  admin: 12,
  lectorCFA: 13,
  editorCFA: 14,
  contable: 15,
};

export const everyRol = Object.values(userIDRolDict);
