import React, { useRef, MutableRefObject, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

import moment from "moment";

import { Box, TextField, Button } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import FileDownloadIcon from "@mui/icons-material/FileDownload";

import {
  GridColDef,
  GridRenderEditCellParams,
  GridValueGetterParams,
} from "@mui/x-data-grid";

import html2canvas from "html2canvas";
import { jsPDF } from "jspdf";

import AedasLoading from "../../Shared/AedasLoading/AedasLoading";

import { useFetch, useIsLoading } from "../../../Hooks/useFetch";
import {
  getReportByCorporationID,
  getCorporateById,
  getMilestonesByCorpID,
  getCorporateBody,
  getCapitalByCorpID,
} from "../../../Services/CorporateServices/CorporateServices";

import { GeneralInfo } from "../Corporate/InfoCorporate/GeneralInfo/GeneralInfo";
import { Capital } from "../Corporate/InfoCorporate/Capital/Capital";
import { Members } from "../Corporate/InfoCorporate/Members/Members";
import { CorporateBody } from "../Corporate/InfoCorporate/CorporateBody/CorporateBody";
import { Milestones } from "../Corporate/InfoCorporate/Milestones/Milestones";
import { Licences } from "../Corporate/InfoCorporate/Licences/Licences";
import { Branches } from "../Corporate/InfoCorporate/Branches/Branches";
import { Corporate } from "../../Shared/SharedForms/Corporate";

import { TabListComponent } from "../../Shared/TabsListComponent/TabListComponent";
import DataGridEditabled from "../../Shared/DataGridEditabled/DataGridEditabled";

import "./Report.css";

export const Report = () => {
  const params: any = useParams();
  const navigate = useNavigate();
  const printRef = useRef() as React.MutableRefObject<HTMLInputElement>;

  const [reportData, isFetchingReport] = useFetch(
    getReportByCorporationID,
    params
  );
  const [corporate, isFetchingCorporate] = useFetch(getCorporateById, params);
  const [milestones, isFetchingMilestones] = useFetch(
    getMilestonesByCorpID,
    params.corporate_id
  );
  const [corporateBody, isFetchingCorporateBody] = useFetch(
    getCorporateBody,
    params.corporate_id
  );
  const [capital, isFetchingCapital] = useFetch(
    getCapitalByCorpID,
    params.corporate_id
  );
  const user = useSelector((state: any) => state.userReducer.user);

  const isLoading = useIsLoading([
    isFetchingReport,
    isFetchingMilestones,
    isFetchingCorporate,
    isFetchingCorporateBody,
    isFetchingCapital,
  ]);

  const [isDownloading, setIsDownloading] = useState(false);

  const membersColumns: GridColDef[] = [
    {
      field: "id_person",
      headerName: "ID",
      width: 9,
      hide: true,
      editable: false,
    },
    {
      field: "name",
      headerName: "Nombre",
      type: "string",
      // width: 50,
      editable: false,
      flex: 1,
    },
    {
      field: "juridical",
      headerName: "Es persona jurídica",
      type: "string",
      renderCell: (params: GridRenderEditCellParams) => {
        return <div>{params.row.juridical ? "Si" : "No"}</div>;
      },
      // width: 50,
      editable: false,
      flex: 1,
    },
    {
      field: "legal_representative",
      headerName: "Representante",
      type: "string",
      // width: 50,
      editable: false,
      flex: 1,
    },
    {
      field: "position",
      headerName: "Cargo",
      type: "string",
      // width: 50,
      editable: false,
      flex: 1,
    },
    {
      field: "date_start",
      headerName: "En el cargo desde",
      type: "string",
      renderCell: (params: GridRenderEditCellParams) => {
        return (
          <div>
            {params.row.date_start
              ? moment(params.row.date_start).format("DD/MM/YYYY")
              : "-"}
          </div>
        );
      },
      // width: 50,
      editable: false,
      flex: 1,
    },
    {
      field: "date_end",
      headerName: "En el cargo hasta",
      type: "string",
      renderCell: (params: GridRenderEditCellParams) => {
        return (
          <div>
            {params.row.date_end
              ? moment(params.row.date_end).format("DD/MM/YYYY")
              : "-"}
          </div>
        );
      },
      // width: 50,
      editable: false,
      flex: 1,
    },
  ];

  const partnersColumns: GridColDef[] = [
    {
      field: "id_person",
      headerName: "ID",
      width: 9,
      hide: true,
      editable: false,
    },
    {
      field: "name",
      headerName: "Nombre",
      type: "string",
      editable: false,
      flex: 1,
    },
    {
      field: "number_shares",
      headerName: "Nº Títulos",
      type: "string",
      editable: false,
      flex: 1,
    },
    {
      field: "percentage",
      headerName: "%",
      type: "string",
      editable: false,
      flex: 1,
    },
    {
      field: "interval",
      headerName: "Participaciones",
      type: "string",
      renderCell: (params: GridRenderEditCellParams) => {
        let intervalList: any[] = [];

        params.row.interval.map((range: any) => {
          intervalList.push(
            `${range.start ? range.start : "-"} al ${
              range.end ? range.end : "-"
            }`
          );
        });

        const intervalDisplayed = intervalList.join(" | ");
        return <div> {intervalDisplayed} </div>;
      },
      editable: false,
      flex: 1,
    },
  ];

  const milestonesColumns: GridColDef[] = [
    {
      field: "id_milestone",
      headerName: "ID",
      width: 9,
      hide: true,
      editable: false,
    },
    {
      field: "type",
      headerName: "Hito",
      type: "string",
      editable: false,
      flex: 1,
    },
    {
      field: "date",
      headerName: "Fecha",
      type: "string",
      renderCell: (params: GridRenderEditCellParams) => {
        return (
          <div>
            {moment(params.row.document.date_registration).format("DD/MM/YYYY")}
          </div>
        );
      },
      editable: false,
      flex: 1,
    },
  ];

  const onCellClick = (event: any) => {
    console.log(event.row);
  };

  const handleDownloadPDF = async () => {
    setIsDownloading(true);
    const element = printRef.current;

    html2canvas(element!, { scale: 5 }).then(async (canvas: any) => {
      const imgWidth = 190; //210mm - 10mm - 10mm
      const pageHeight = 277; //297mm - 10mm - 10mm
      const fullReportHeight = (canvas.height * imgWidth) / canvas.width;

      let totalPages = 0;

      const reportHeader = document.getElementById("report-header");
      const generalInfo = document.getElementById("general-info");
      const corporationBody = document.getElementById("corporation-body");
      const capital = document.getElementById("capital");
      const milestones = document.getElementById("milestones");

      let imagesPromises: any[] = [];

      if (reportHeader != null) {
        imagesPromises.push(await html2canvas(reportHeader, { scale: 5 })!);
      }

      if (generalInfo != null) {
        imagesPromises.push(await html2canvas(generalInfo, { scale: 5 })!);
      }

      if (milestones != null) {
        imagesPromises.push(await html2canvas(milestones, { scale: 5 })!);
      }

      if (corporationBody != null) {
        imagesPromises.push(await html2canvas(corporationBody, { scale: 5 })!);
      }

      if (capital != null) {
        imagesPromises.push(await html2canvas(capital, { scale: 5 })!);
      }

      let heightLeft = fullReportHeight;
      let position = 15;
      heightLeft -= pageHeight - position;

      const doc = new jsPDF("p", "mm");

      if (fullReportHeight > pageHeight) {
        totalPages = Math.ceil(fullReportHeight / pageHeight);
      } else {
        totalPages = 1;
      }

      Promise.all(imagesPromises)
        .then(
          ([
            canvasReportHeader,
            canvasGeneralInfo,
            canvasMilestones,
            canvasCorporationBody,
            canvasCapital,
          ]) => {
            const generalInfoHeight =
              (canvasGeneralInfo.height * imgWidth) / canvasGeneralInfo.width;
            const reportHeaderHeight =
              (canvasReportHeader.height * imgWidth) / canvasReportHeader.width;
            const corporationBodyHeight = canvasCorporationBody
              ? (canvasCorporationBody.height * imgWidth) /
                canvasCorporationBody.width
              : 0;
            const capitalHeight = canvasCapital
              ? (canvasCapital.height * imgWidth) / canvasCapital.width
              : 0;
            const milestonesHeight =
              (canvasMilestones.height * imgWidth) / canvasMilestones.width;

            doc.addPage();
            doc.setPage(1);
            doc.setFont("roboto");
            doc.setFontSize(8);
            doc.text(
              `Informe descargado por ${user.employee.display_name} - Cargo: ${
                user.employee.azure_rol
              }, el día ${moment().format("DD/MM/YYYY HH:mm")}`,
              200,
              10,
              { align: "right" }
            );
            doc.addImage(
              canvasReportHeader,
              "PNG",
              10,
              20,
              imgWidth,
              reportHeaderHeight,
              "",
              "FAST"
            );
            doc.addImage(
              canvasGeneralInfo,
              "PNG",
              10,
              30 + reportHeaderHeight,
              imgWidth,
              generalInfoHeight,
              "",
              "FAST"
            );

            if (
              corporationBodyHeight > 0 &&
              corporationBodyHeight >
                pageHeight - reportHeaderHeight - generalInfoHeight - 30
            ) {
              //corporationBody entra en la pag1?
              doc.text(`Pagina 1 de ${totalPages}`, 0, 290, {
                align: "center",
              });
              doc.addPage();
              doc.setPage(2);
              doc.text(
                `Informe descargado por ${
                  user.employee.display_name
                } - Cargo: ${user.employee.azure_rol}, el día ${moment().format(
                  "DD/MM/YYYY HH:mm"
                )}`,
                200,
                10,
                { align: "right" }
              );
              doc.addImage(
                canvasReportHeader,
                "PNG",
                10,
                20,
                imgWidth,
                reportHeaderHeight,
                "",
                "FAST"
              );
              doc.addImage(
                canvasCorporationBody,
                "PNG",
                10,
                30 + reportHeaderHeight,
                imgWidth,
                corporationBodyHeight,
                "",
                "FAST"
              );
            } else if (corporationBodyHeight > 0) {
              doc.addImage(
                canvasCorporationBody,
                "PNG",
                10,
                40 + generalInfoHeight + reportHeaderHeight,
                imgWidth,
                corporationBodyHeight,
                "",
                "FAST"
              );
              doc.text(`Documento interno`, 10, 290, {
                align: "left",
              });
              doc.text(`CONFIDENCIAL`, 100, 290, {
                align: "center",
              });
              doc.text(`Pagina 1 de ${totalPages}`, 200, 290, {
                align: "right",
              });
            }

            if (
              reportHeaderHeight +
                generalInfoHeight +
                corporationBodyHeight +
                30 <
              pageHeight
            ) {
              //corporationBody esta en la pag1
              doc.setPage(2);
              doc.text(
                `Informe descargado por ${
                  user.employee.display_name
                } - Cargo: ${user.employee.azure_rol}, el día ${moment().format(
                  "DD/MM/YYYY HH:mm"
                )}`,
                200,
                10,
                { align: "right" }
              );
              doc.addImage(
                canvasReportHeader,
                "PNG",
                10,
                20,
                imgWidth,
                reportHeaderHeight,
                "",
                "FAST"
              );
              if (capitalHeight > 0) {
                doc.addImage(
                  canvasCapital,
                  "PNG",
                  10,
                  30 + reportHeaderHeight,
                  imgWidth,
                  capitalHeight,
                  "",
                  "FAST"
                );
              }

              if (
                reportHeaderHeight + capitalHeight + milestonesHeight + 30 <
                pageHeight
              ) {
                doc.addImage(
                  canvasMilestones,
                  "PNG",
                  10,
                  40 + reportHeaderHeight + capitalHeight,
                  imgWidth,
                  milestonesHeight,
                  "",
                  "FAST"
                );
                doc.text(`Documento interno`, 10, 290, {
                  align: "left",
                });
                doc.text(`CONFIDENCIAL`, 100, 290, {
                  align: "center",
                });
                doc.text(`Pagina 2 de ${totalPages}`, 200, 290, {
                  align: "right",
                });
              }
            } else {
              //corporationBody esta en la pag2 tengo que ver si entra capital
              if (
                capitalHeight > 0 &&
                capitalHeight >
                  pageHeight - reportHeaderHeight - corporationBodyHeight - 30
              ) {
                //si la altura de capital es mayor al espacio disponible
                doc.text(`Documento interno`, 10, 290, {
                  align: "left",
                });
                doc.text(`CONFIDENCIAL`, 100, 290, {
                  align: "center",
                });
                doc.text(`Pagina 2 de ${totalPages}`, 200, 290, {
                  align: "right",
                });
                //empiezo la pagina 3
                doc.addPage();
                doc.setPage(3);
                doc.text(
                  `Informe descargado por ${
                    user.employee.display_name
                  } - Cargo: ${
                    user.employee.azure_rol
                  }, el día ${moment().format("DD/MM/YYYY HH:mm")}`,
                  200,
                  10,
                  { align: "right" }
                );
                doc.addImage(
                  canvasReportHeader,
                  "PNG",
                  10,
                  20,
                  imgWidth,
                  reportHeaderHeight,
                  "",
                  "FAST"
                );
                doc.addImage(
                  canvasCapital,
                  "PNG",
                  10,
                  30 + reportHeaderHeight,
                  imgWidth,
                  capitalHeight,
                  "",
                  "FAST"
                );

                if (
                  reportHeaderHeight + capitalHeight + milestonesHeight + 30 <
                  pageHeight
                ) {
                  doc.addImage(
                    canvasMilestones,
                    "PNG",
                    10,
                    40 + reportHeaderHeight + capitalHeight,
                    imgWidth,
                    milestonesHeight,
                    "",
                    "FAST"
                  );
                  doc.text(`Documento interno`, 10, 290, {
                    align: "left",
                  });
                  doc.text(`CONFIDENCIAL`, 100, 290, {
                    align: "center",
                  });
                  doc.text(`Pagina 3 de ${totalPages}`, 200, 290, {
                    align: "right",
                  });
                } else {
                  //empiezo la pagina 4
                  doc.addPage();
                  doc.setPage(4);
                  doc.text(
                    `Informe descargado por ${
                      user.employee.display_name
                    } - Cargo: ${
                      user.employee.azure_rol
                    }, el día ${moment().format("DD/MM/YYYY HH:mm")}`,
                    200,
                    10,
                    { align: "right" }
                  );
                  doc.addImage(
                    canvasReportHeader,
                    "PNG",
                    10,
                    20,
                    imgWidth,
                    reportHeaderHeight,
                    "",
                    "FAST"
                  );
                  doc.addImage(
                    canvasMilestones,
                    "PNG",
                    10,
                    30 + reportHeaderHeight,
                    imgWidth,
                    milestonesHeight,
                    "",
                    "FAST"
                  );
                  doc.text(`Documento interno`, 10, 290, {
                    align: "left",
                  });
                  doc.text(`CONFIDENCIAL`, 100, 290, {
                    align: "center",
                  });
                  doc.text(`Pagina 4 de ${totalPages}`, 200, 290, {
                    align: "right",
                  });
                }
              } else if (capitalHeight > 0) {
                //agrego capital despues de corporate body
                doc.addImage(
                  canvasCapital,
                  "PNG",
                  10,
                  35 + corporationBodyHeight,
                  imgWidth,
                  capitalHeight,
                  "",
                  "FAST"
                );
                doc.text(`Documento interno`, 10, 290, {
                  align: "left",
                });
                doc.text(`CONFIDENCIAL`, 100, 290, {
                  align: "center",
                });
                doc.text(`Pagina 2 de ${totalPages}`, 200, 290, {
                  align: "right",
                });
              }
            }

            doc.save(
              `Informe sociedad ${reportData.corporation.registered_name}.pdf`
            );

            setIsDownloading(false);
          }
        )
        .catch((error) => console.log(error));
    });
  };

  return (
    <>
      {isLoading ? (
        <AedasLoading></AedasLoading>
      ) : (
        <div className="report-container">
          <div ref={printRef} style={{ width: "100%" }}>
            <div
              id="report-header"
              style={{
                display: "flex",
                width: "90%",
                margin: "1rem auto 2rem",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  width: "150px",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <img
                  src="/Assets/img/AF_AEDAS_POSITIVO_RGB.png"
                  style={{ width: "100%" }}
                />
              </div>
              <div style={{ width: "100%" }}>
                <h3 className="report-title">
                  Informe de Sociedad {reportData.corporation.registered_name}
                </h3>
              </div>
            </div>
            {/* <Box
              component="div"
              id="report"
              // className={ `text-center ${props.edit ? "milestone-edit" : ""}`}
              sx={{
                width: "100%",
                mx: "auto",
                my: 2,
              }}
          > */}
            <div id="report">
              <div className="fields-group" id="general-info">
                <div>
                  <p>Información General</p>
                </div>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <div style={{ display: "flex" }}>
                    <TextField
                      label="Razón Social Actual"
                      variant={"standard"}
                      disabled
                      style={{
                        margin: "1rem",
                        marginBottom: 0,
                        maxWidth: "100%",
                        width: "70%",
                        flexGrow: "1",
                      }}
                      value={reportData.corporation.registered_name}
                    />
                    <TextField
                      label="Forma Jurídica"
                      variant={"standard"}
                      disabled
                      style={{
                        margin: "1rem",
                        marginBottom: 0,
                        maxWidth: "100%",
                        width: "30%",
                      }}
                      value={`Sociedad ${reportData.corporation.type}`}
                    />
                  </div>
                  <div style={{ display: "flex" }}>
                    <TextField
                      label="NIF"
                      variant={"standard"}
                      disabled
                      style={{
                        margin: "1rem",
                        marginBottom: 0,
                        width: "50%",
                        maxWidth: "100%",
                      }}
                      value={reportData.corporation.cif}
                    />
                    <TextField
                      label="Fecha de constitución"
                      variant={"standard"}
                      disabled
                      style={{
                        margin: "1rem",
                        marginBottom: 0,
                        width: "50%",
                        maxWidth: "100%",
                      }}
                      value={moment(
                        reportData.corporation.creation_date
                      ).format("MM/DD/YYYY")}
                    />
                  </div>
                  <div style={{ display: "flex" }}>
                    <TextField
                      label="Domicilio Social"
                      variant={"standard"}
                      disabled
                      style={{
                        margin: "1rem",
                        marginBottom: 0,
                        width: "100%",
                        maxWidth: "100%",
                      }}
                      value={`${reportData.corporation.full_address}, ${reportData.corporation.town}, CP: ${reportData.corporation.postal_code}`}
                    />
                  </div>
                  <div style={{ display: "flex" }}>
                    <TextField
                      label="Provincia, País"
                      variant={"standard"}
                      disabled
                      style={{
                        margin: "1rem",
                        marginBottom: 0,
                        width: "100%",
                        maxWidth: "100%",
                      }}
                      value={`${reportData.corporation.province}, ${reportData.corporation.country}`}
                    />
                  </div>
                  <div style={{ display: "flex", paddingBottom: "2rem" }}>
                    <TextField
                      label="Controlada"
                      variant={"standard"}
                      disabled
                      style={{
                        margin: "1rem",
                        marginBottom: 0,
                        maxWidth: "100%",
                      }}
                      value={reportData.corporation.controlled ? "Si" : "No"}
                    />
                    <TextField
                      label="Grupo AEDAS Homes"
                      variant={"standard"}
                      disabled
                      style={{
                        margin: "1rem",
                        marginBottom: 0,
                        maxWidth: "25%",
                        flexGrow: "2",
                      }}
                      value={reportData.corporation.group ? "Si" : "No"}
                    />
                    <TextField
                      label="Auditada"
                      variant={"standard"}
                      disabled
                      style={{
                        margin: "1rem",
                        marginBottom: 0,
                        maxWidth: "100%",
                      }}
                      value={reportData.corporation.audited ? "Si" : "No"}
                    />
                    {reportData.corporation.audited ? (
                      <>
                        <TextField
                          label="Auditor"
                          variant={"standard"}
                          disabled
                          style={{
                            margin: "1rem",
                            marginBottom: 0,
                            maxWidth: "100%",
                            flexGrow: "2",
                          }}
                          value={reportData.corporation.audited_person[0].name}
                        />
                        <TextField
                          label="NIF del auditor"
                          variant={"standard"}
                          disabled
                          style={{
                            margin: "1rem",
                            marginBottom: 0,
                            maxWidth: "100%",
                          }}
                          value={
                            reportData.corporation.audited_person[0].cif_nif
                          }
                        />
                        <TextField
                          label="Vigencia hasta"
                          variant={"standard"}
                          disabled
                          style={{
                            margin: "1rem",
                            marginBottom: 0,
                            maxWidth: "100%",
                          }}
                          value={
                            reportData.corporation.audited_person[0].date_end !=
                            null
                              ? moment(
                                  reportData.corporation.audited_person[0]
                                    .date_end
                                ).format("MM/DD/YYYY")
                              : "Indefinida"
                          }
                        />
                      </>
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
              </div>

              {corporateBody.length > 0 ? (
                <div className="fields-group" id="corporation-body">
                  <div>
                    <p>Órgano de Administración</p>
                  </div>
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <div style={{ display: "flex" }}>
                      <TextField
                        label="Tipo de órgano de administración"
                        variant={"standard"}
                        disabled
                        style={{
                          margin: "1rem",
                          marginBottom: 0,
                          maxWidth: "100%",
                          width: "70%",
                          flexGrow: "1",
                        }}
                        value={reportData.corporate_body.type}
                      />
                      <TextField
                        label="Fecha alta"
                        variant={"standard"}
                        disabled
                        style={{
                          margin: "1rem",
                          marginBottom: 0,
                          maxWidth: "100%",
                        }}
                        value={
                          reportData.corporate_body.registered_date != null
                            ? moment(
                                reportData.corporate_body.registered_date
                              ).format("MM/DD/YYYY")
                            : "-"
                        }
                      />
                    </div>
                    <p>Miembros del órgano de administración:</p>
                    <div
                      id="members-table-box"
                      style={{ display: "flex", flexDirection: "column" }}
                    >
                      <DataGridEditabled
                        onlyEdit={false}
                        showHeader={false}
                        rows={corporateBody[0].person ?? []}
                        columns={membersColumns}
                        onCellClick={onCellClick}
                        getRowId={(row: any) => row.id_person}
                        disableSelectionOnClick={true}
                        hideFooterPagination={true}
                      />
                    </div>
                  </div>
                </div>
              ) : (
                <></>
              )}

              {capital.length > 0 ? (
                <div className="fields-group" id="capital">
                  <div>
                    <p>Capital Social</p>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      paddingBottom: "2rem",
                    }}
                  >
                    <div style={{ display: "flex" }}>
                      <TextField
                        label="Capital social"
                        variant={"standard"}
                        disabled
                        style={{
                          margin: "1rem",
                          marginBottom: 0,
                          maxWidth: "100%",
                          width: "30%",
                        }}
                        value={
                          reportData.capital.currency === "EUR"
                            ? new Intl.NumberFormat("es-ES", {
                                style: "currency",
                                currency: "EUR",
                                useGrouping: true,
                              }).format(reportData.capital.total_capital_issued)
                            : `${reportData.capital.total_capital_issued} ${reportData.capital.currency}`
                        }
                      />
                      <TextField
                        label="Tipo títulos"
                        variant={"standard"}
                        disabled
                        style={{
                          margin: "1rem",
                          marginBottom: 0,
                          maxWidth: "100%",
                          width: "70%",
                          flexGrow: "1",
                        }}
                        value={reportData.capital.description}
                      />
                      <TextField
                        label="Nº títulos emitidos"
                        variant={"standard"}
                        disabled
                        style={{
                          margin: "1rem",
                          marginBottom: 0,
                          maxWidth: "100%",
                          width: "30%",
                        }}
                        value={reportData.capital.shares_issued}
                      />
                      <TextField
                        label="Valor Nominal"
                        variant={"standard"}
                        disabled
                        style={{
                          margin: "1rem",
                          marginBottom: 0,
                          maxWidth: "100%",
                          width: "30%",
                        }}
                        value={
                          reportData.capital.currency === "EUR"
                            ? new Intl.NumberFormat("es-ES", {
                                style: "currency",
                                currency: "EUR",
                                useGrouping: true,
                              }).format(reportData.capital.nominal_value)
                            : `${reportData.capital.nominal_value} ${reportData.capital.currency}`
                        }
                      />
                    </div>
                    <p>Junta de socios constituida por:</p>
                    <div
                      id="partners-table-box"
                      style={{ display: "flex", flexDirection: "column" }}
                    >
                      <DataGridEditabled
                        onlyEdit={false}
                        showHeader={false}
                        rows={capital[0].person ?? [] ?? []}
                        columns={partnersColumns}
                        onCellClick={onCellClick}
                        getRowId={(row: any) => row.id_person}
                        disableSelectionOnClick={true}
                        hideFooterPagination={true}
                      />
                    </div>
                  </div>
                </div>
              ) : (
                <></>
              )}

              <div className="fields-group" id="milestones">
                <div>
                  <p>Histórico de Hitos societarios</p>
                </div>
                <div
                  id="milestone-table-box"
                  style={{ display: "flex", flexDirection: "column" }}
                >
                  <DataGridEditabled
                    onlyEdit={false}
                    showHeader={false}
                    rows={milestones ?? []}
                    columns={milestonesColumns}
                    onCellClick={onCellClick}
                    getRowId={(row: any) => row.id_milestone}
                    disableSelectionOnClick={true}
                    hideFooterPagination={true}
                  />
                </div>
              </div>
            </div>
            {/* </Box> */}
          </div>

          <Button
            color="primary"
            className="button grey-button print"
            onClick={() => navigate(`/sociedad/${params.corporate_id}`)}
          >
            Volver
          </Button>

          {/* <Button
            color="primary"
            className="button blue-button print"
            onClick={handleDownloadPDF}
          >
            Descargar
          </Button> */}

          <LoadingButton
            size="small"
            color="primary"
            className="button blue-button print"
            onClick={handleDownloadPDF}
            endIcon={<FileDownloadIcon style={{ fill: "#fff !important;" }} />}
            loading={isDownloading}
            loadingPosition="end"
            variant="contained"
          >
            {isDownloading ? "Descargando" : "Descargar"}
          </LoadingButton>
        </div>
      )}
    </>
  );
};
