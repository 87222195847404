import React from 'react';
import moment from "moment";

import { GridColDef, GridRenderEditCellParams, GridValueGetterParams } from "@mui/x-data-grid";

import { TabListComponent } from '../../../../Shared/TabsListComponent/TabListComponent';
import { useFetch, useIsLoading } from '../../../../../Hooks/useFetch';
import { getCapitalByCorpID } from '../../../../../Services/CorporateServices/CorporateServices';



import './../InfoCorporate.css';

export const Members = (props: any) => {
	const [capital, isFetchingCapital] = useFetch(getCapitalByCorpID, props.corporate.id_corporation);

    console.log(props);

    const columns: GridColDef[] = [
        {
            field: "id_person",
            headerName: "ID",
            width: 9,
            hide: true,
            editable: false,
        },
        {
            field: "name",
            headerName: "Nombre",
            type: "string",
            editable: false,
            flex: 1,
        },
        {
            field: "description",
            headerName: "Tipo Título",
            type: "string",
            renderCell: (params: GridRenderEditCellParams) => {
                    return <div> { params.row ? capital[0].description : "" } </div>;
            },
            editable: false,
            flex: 1,
        },
        {
            field: "number_shares",
            headerName: "Nº Títulos",
            type: "string",
            editable: false,
            flex: 1,
        },
        {
            field: "percentage",
            headerName: "%",
            type: "string",
            editable: false,
            flex: 1,
        },
        {
            field: "interval",
            headerName: "Participaciones",
            type: "string",
            renderCell: (params: GridRenderEditCellParams) => {
                let intervalList:any[] = [];

                params.row.interval.map((range: any) => {
                    intervalList.push(`${range.start ? range.start : '-'} al ${range.end ? range.end : '-'}`);
                });

                const intervalDisplayed = intervalList.join(' | ')
                return <div> { intervalDisplayed } </div>;
            },
            editable: false,
            flex: 1,
        },
    ]

    return (
        <>
            { isFetchingCapital ? <></> : (
                <TabListComponent 
                corporate={props.corporate}
                title={'Sociedad / Persona Tenedora'} 
                columns={columns}
                rows={capital[0].person}   
                getRowId={(row: any) => row.id_person}
            />
            )}
        </>
    )
}
