import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";

import {
  AuthenticationResult,
  EventMessage,
  EventType,
  PublicClientApplication,
} from "@azure/msal-browser";

import { msalConfig } from "./authConfig";
import { Provider } from "react-redux";

import { store } from "./Redux/Store";
import App from "./App";

import "./index.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { AuthProvider } from "react-oidc-context";

const oidcConfig = {
  authority: `${process.env.REACT_APP_KEYCLOAK}/realms/aedas-litigios`,
  client_id: "aedas-litigios",
  redirect_uri: process.env.REACT_APP_BASE_REDIRECT ?? "",
  post_logout_redirect_uri: process.env.REACT_APP_BASE_REDIRECT ?? "",
};

ReactDOM.render(
  <AuthProvider {...oidcConfig}>
    <Provider store={store}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </Provider>
  </AuthProvider>,
  document.getElementById("root")
);
