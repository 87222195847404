import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";

import * as yup from "yup";
import { useFormik } from "formik";
import {
  TextField,
  MenuItem,
  Autocomplete,
  Select,
  Button,
} from "@mui/material";

import AedasLoading from "../../../Shared/AedasLoading/AedasLoading";
import { useFetch } from "../../../../Hooks/useFetch";

import {
  addNewInternalUser,
  getAllRols,
  getAllEmployees,
  updateEmployees,
} from "../../../../Services/UserService/UserService";
import { log } from "console";

export const NewUserModal = (props: any) => {
  const [selected, setSelected] = useState<any>(null);
  const [employees, isFetchingEmployees] = useFetch(getAllEmployees);
  const { setOpenEditModalShow } = props;

  return (
    <>
      <FormNewUserInternal
        rolOptions={props.rolOptions}
        setOpenEditModalShow={setOpenEditModalShow}
        employees={employees}
        setIsLoading={props.setIsLoading}
      ></FormNewUserInternal>
    </>
  );
};

const FormNewUserInternal = (props: any) => {
  const { rolOptions, setOpenEditModalShow, dts, employees } = props;
  const formik = useFormik({
    initialValues: {
      email: null,
      rol: rolOptions[1],
    },

    validationSchema: null,

    onSubmit: (values: any) => {
      console.log(values);
      const value = {
        id: values.email.id,
        id_rol: values.rol.id,
        active: true,
      };

      updateEmployees(value)
        .then(() => {
          toast.success("Usuario añadido");
          setOpenEditModalShow(false);
        })
        .catch(() => toast.error("Ha habido un error"));
    },
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <div className="form_new_user_internal">
        <div className="w-50 p-4">
          <Autocomplete
            id="email"
            options={employees}
            onChange={(e, value) => {
              console.log(value);
              formik.setFieldValue("email", value);
            }}
            getOptionLabel={(option: any) => option.email}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            renderInput={(params) => (
              <TextField name="email" {...params} label="Email" />
            )}
          />
        </div>
        <div className="w-50 p-4">
          <Autocomplete
            id="rol"
            options={rolOptions}
            onChange={(e, value) => {
              formik.setFieldValue("rol", value);
            }}
            getOptionLabel={(option: any) => option.name}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            renderInput={(params) => (
              <TextField name="rol" {...params} label="Rol" />
            )}
          />
        </div>
      </div>
      <div style={{ width: "fit-content", margin: "0 auto" }}>
        <Button type="submit" className="button blue-button">
          Crear usuario
        </Button>
      </div>
    </form>
  );
};
