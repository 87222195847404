import axios from "axios";
import {
  getDefaultConfigHeader,
  getDefaultConfigHeaderLit,
} from "../../Utils/headerDefaultConfigPetition";

export const getAllUsers = () => {
  return axios.get(
    process.env.REACT_APP_API_BACKEND + "/user/",
    getDefaultConfigHeader()
  );
};

export const addNewInternalUser = (value: any) => {
  return axios.post(
    process.env.REACT_APP_API_BACKEND + "/internal_user/",
    value,
    getDefaultConfigHeader()
  );
};

export const getAllRols = (value: any) => {
  return axios.get(
    process.env.REACT_APP_API_BACKEND + "/rol/",
    getDefaultConfigHeader()
  );
};

export const updateUser = (value: any) => {
  return axios.put(
    process.env.REACT_APP_API_BACKEND + "/user/" + value.id,
    value,
    getDefaultConfigHeader()
  );
};

export const getAllEmployees = () => {
  return axios.get(
    process.env.REACT_APP_API_BACKEND + "/employee/",
    getDefaultConfigHeader()
  );
};

export const updateEmployees = (employee: any) => {
  return axios.put(
    process.env.REACT_APP_API_BACKEND + "/employee/" + employee.id,
    employee,
    getDefaultConfigHeader()
  );
};

export const getAllLitigiosCollaborators = () => {
  return axios
    .get(
      process.env.REACT_APP_API_BACKEND_LIT + "/collaborator/",
      getDefaultConfigHeaderLit()
    )
    .then((res: any) => res.data);
};
