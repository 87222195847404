import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import axios from "axios";

import { GridColDef, GridRenderEditCellParams } from "@mui/x-data-grid";
import { LoadingButton } from "@mui/lab";
import {
  Autocomplete,
  Button,
  Checkbox,
  FormControlLabel,
  TextField,
} from "@mui/material";

import AedasLoading from "../../Shared/AedasLoading/AedasLoading";
import DataGridEditabled from "../../Shared/DataGridEditabled/DataGridEditabled";
import CustomModal from "../../Shared/ModalCustom/CustomModal";

import { useFetch, useIsLoading } from "../../../Hooks/useFetch";
import {
  getAllEmployees,
  updateEmployees,
  getAllRols,
  getAllUsers,
  updateUser,
} from "../../../Services/UserService/UserService";

import { NewUserModal } from "./NewUserModal/NewUserModal";

import "./Users.css";
import { log } from "console";

export const Users = () => {
  const [employees, isFechingUsers, updateUsers] = useFetch(getAllEmployees);
  const [users, setUsers] = useState<any[]>([]);
  console.log(users);
  const rolOptions = [
    {
      id: 12,
      name: "Administrador",
    },
    {
      id: 11,
      name: "Editor Completo",
    },
    {
      id: 14,
      name: "Editor CFA",
    },
    {
      id: 15,
      name: "Contable",
    },
    {
      id: 10,
      name: "Lector Completo",
    },
    {
      id: 13,
      name: "Lector CFA",
    },
  ];
  const rolIds = rolOptions.map((rol: any) => rol.id);

  const [selectedRow, setSelectedRow] = useState<any>(null);
  const [selectedRol, setSelectedRol] = useState<any>(null);

  const isLoadingFecth = useIsLoading([isFechingUsers]);

  const [openEditModalShow, setOpenEditModalShow] = useState<boolean>(false);
  const [openModalShow, setOpenModalShow] = useState(false);

  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    if (selectedRow && selectedRow.state !== 1) {
      setOpenModalShow(true);
    }
  }, [selectedRow]);

  useEffect(() => {
    if (!openModalShow || !openEditModalShow) {
      setSelectedRol(false);
      updateUsers();
    }
  }, [openModalShow, openEditModalShow]);

  useEffect(() => {
    if (employees) {
      const users: any[] = [];
      rolIds.forEach((rol: any) =>
        users.push(
          ...employees.filter((employee: any) => employee.id_rol == rol)
        )
      );
      setUsers(users);
    }
  }, [employees]);

  if (isLoadingFecth) {
    return (
      <>
        <AedasLoading></AedasLoading>
      </>
    );
  }

  const columns: GridColDef[] = [
    {
      field: "id",
      headerName: "ID",
      width: 9,
      editable: false,
    },

    {
      field: "display_name",
      headerName: "Nombre y apellido",
      type: "string",
      width: 200,
      editable: false,
      flex: 1,
    },

    {
      field: "id_rol",
      headerName: "Rol",
      type: "string",
      renderCell: (params: GridRenderEditCellParams) => {
        const rol: any = rolOptions.find((r: any) => r.id === params.value);
        return <div>{rol ? rol.name : "Sin Rol asignado"}</div>;
      },
      width: 200,
      editable: true,
      flex: 1,
    },

    {
      field: "email",
      type: "string",
      headerName: "Email",
      editable: false,
      width: 200,
      flex: 1,
    },
    {
      field: "active",
      type: "string",
      headerName: "Estado",
      editable: false,
      width: 200,
      flex: 1,
      renderCell: (params: GridRenderEditCellParams) => {
        // if (params.value === 2) {
        //   return (
        //     <div
        //       style={{
        //         padding: "4px",
        //         backgroundColor: "var(--secondary-color)",
        //         color: "white",
        //         borderRadius: "10px",
        //         fontWeight: "bold",
        //       }}
        //     >
        //       Token Caducado
        //     </div>
        //   );
        // } else if (params.value === 1) {
        //   return (
        //     <div
        //       style={{
        //         padding: "4px",
        //         backgroundColor: "blue",
        //         color: "white",
        //         borderRadius: "10px",
        //         fontWeight: "bold",
        //       }}
        //     >
        //       Pendiente de registro
        //     </div>
        //   );
        // }

        const user = users.find((p: any) => p.id === params.id);
        if (user?.active) {
          return (
            <div
              style={{
                padding: "4px 12px",
                backgroundColor: "var(--aedas-green)",
                color: "white",
                borderRadius: "10px",
                fontWeight: "bold",
              }}
            >
              Activado
            </div>
          );
        } else {
          return (
            <div
              style={{
                padding: "4px 12px",
                backgroundColor: "var(--aedas-red)",
                color: "white",
                borderRadius: "10px",
                fontWeight: "bold",
              }}
            >
              Desactivado
            </div>
          );
        }
      },
    },
  ];

  const onCellClick = (e: any) => {
    if (users && users.length > 0) {
      setSelectedRow(users?.find((p: any) => p.id === e.id));
    }
  };

  const handleAdd = (e: any) => {
    setOpenEditModalShow(!openEditModalShow);
  };

  return (
    <div className="h-100 m-4">
      <div className="main-container p-3">
        <h1 className="main-title mb-2">Gestión de usuarios</h1>
        <p className="text-center">
          Desde aquí se podrían crear/editar los usuarios para el uso de esta
          app.
        </p>

        <CustomModal
          size="xl"
          show={openEditModalShow}
          onChange={() => {
            setOpenEditModalShow(!openEditModalShow);
          }}
          title="Nuevo Usuario"
          body={
            <NewUserModal
              selectedRow={selectedRow}
              selectedRol={selectedRol}
              setOpenEditModalShow={setOpenEditModalShow}
              rolOptions={rolOptions}
              show={() => setOpenEditModalShow(!openEditModalShow)}
              setIsLoading={() => setIsLoading(!isLoading)}
            />
          }
        />

        <CustomModal
          size="xl"
          show={openModalShow}
          onChange={() => {
            setOpenModalShow(!openModalShow);
          }}
          title="Editar Usuario"
          body={
            <EditUserModal
              selectedRow={selectedRow}
              setOpenModalShow={setOpenModalShow}
              rolOptions={rolOptions}
              show={() => setOpenModalShow(!openModalShow)}
            />
          }
        ></CustomModal>

        <DataGridEditabled
          showHeader={true}
          rows={users ?? []}
          columns={columns}
          onCellClick={(event: any) => onCellClick(event)}
          handleAdd={(event: any) => handleAdd(event)}
          singleIcon={true}
          addIcon={true}
          initialState={{
            columns: {
              columnVisibilityModel: {
                id: false,
              },
            },
          }}
        />
      </div>
    </div>
  );
};

const EditUserModal = (props: any) => {
  const [isLoading, setisLoading] = useState(false);

  const formik = useFormik({
    initialValues: {
      rol: props.rolOptions.find((r: any) => r.id === props.selectedRow.id_rol),
      active: props.selectedRow.active,
    },

    validationSchema: null,

    onSubmit: (values) => {
      setisLoading(true);

      const value = {
        id: props.selectedRow.id,
        id_rol: values.active ? values.rol.id : 4,
        active: true,
      };

      updateEmployees(value)
        .then(() => {
          toast.success("Guardado");
          setisLoading(false);
          props.setOpenModalShow(false);
        })
        .catch(() => {
          setisLoading(false);
          toast.error("Ha habido un error");
        });
    },
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <div className="form_new_user_internal">
        <div style={{ width: "80%" }} className=" p-4">
          <Autocomplete
            id="rol"
            value={formik.values.rol}
            options={props.rolOptions}
            onChange={(e, value) => {
              formik.setFieldValue("rol", value);
            }}
            getOptionLabel={(option: any) => option.name}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            renderInput={(params) => (
              <TextField name="rol" {...params} label="Rol" />
            )}
          />
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "end",
          }}
          className="w-10 p-4"
        >
          <FormControlLabel
            control={
              <Checkbox
                value={formik.values.active}
                // @ts-ignore
                defaultChecked={formik.values.active}
                onChange={formik.handleChange}
              />
            }
            label="Activado"
            id="active"
            name="active"
          />
        </div>
      </div>

      <div style={{ width: "fit-content", margin: "0 auto" }}>
        <LoadingButton
          type="submit"
          className="button blue-button"
          loading={isLoading}
        >
          {isLoading ? "" : "Guardar"}
        </LoadingButton>
      </div>
    </form>
  );
};
