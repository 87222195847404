import { useNavigate } from "react-router-dom";
import { goToURL, validatePermission } from "../Utils/permissionsUtils";
import { IoBusinessSharp } from "react-icons/io5";
import { MdOutlinePayments } from "react-icons/md";
import { PiGavelFill } from "react-icons/pi";
import { HiOutlineClipboardDocumentList } from "react-icons/hi2";
import { useState } from "react";
import { usePermission } from "../Hooks/usePermission";
import { useSelector } from "react-redux";
import { everyRol, userIDRolDict } from "../Utils/stringTransform";

export const MainMenu = () => {
  const navigate = useNavigate();
  const { hasPermission } = usePermission();
  const user = useSelector((state: any) => state.userReducer.user);
  interface MenuProps {
    url: string;
    Icon: any;
    text: string;
    canView?: boolean;
    onClick: (url: string) => void;
  }

  const Menu = ({ url, Icon, text, canView = true, onClick }: MenuProps) => {
    const [isHovered, setIsHovered] = useState(false);

    const handleClick = () => {
      if (canView) {
        onClick(url);
      }
    };

    return (
      <div
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        onClick={handleClick}
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          background: "white",
          borderRadius: "10px",
          width: "100%",
          boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
          cursor: canView ? "pointer" : "not-allowed",
          opacity: canView ? 1 : 0.5,
          transition: canView ? "transform 0.2s" : "",
          transform: canView ? (isHovered ? "scale(1.02)" : "scale(1)") : "",
        }}
      >
        <Icon style={{ fontSize: "50px" }} />
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "1rem",
            width: "10rem",
            alignItems: "center",
          }}
        >
          <h3>{text}</h3>
        </div>
      </div>
    );
  };

  return (
    <div
      style={{
        width: "100%",
        height: "85vh",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        gap: "5rem",
        paddingTop: "2rem",
      }}
    >
      <h2 style={{ maxWidth: "60%", textAlign: "center" }}>
        <img
          style={{ width: "100px", height: "100px" }}
          src={process.env.PUBLIC_URL + "/Assets/Img/LogoAedasSmall.svg"}
          alt='AEDAS LOGO'
        />{" "}
        AEDAS Homes - Aplicación gestión <br /> Sociedades, CFAs, Litigios y
        Reclamaciones
      </h2>
      <div
        style={{
          display: "grid",
          gridTemplateColumns: "repeat(2, minmax(200px, 1fr))",
          gridAutoRows: "minmax(200px, auto)",
          gap: "2rem",
          width: "60%",
        }}
      >
        <Menu
          url='/sociedades'
          Icon={IoBusinessSharp}
          text='Sociedades'
          canView={validatePermission(user, [
            userIDRolDict.lector,
            userIDRolDict.editor,
            userIDRolDict.admin,
          ])}
          onClick={navigate}
        />
        <Menu
          url='/cfa'
          Icon={MdOutlinePayments}
          text='CFAs'
          canView={validatePermission(user, everyRol)}
          onClick={navigate}
        />
        <Menu
          url={process.env.REACT_APP_LITIGIOS + "/lista"}
          Icon={PiGavelFill}
          text='Litigios'
          canView={hasPermission("litigationTable.access")}
          onClick={goToURL}
        />
        <Menu
          url={process.env.REACT_APP_LITIGIOS + "/reclamacion/lista"}
          Icon={HiOutlineClipboardDocumentList}
          text='Reclamaciones'
          canView={hasPermission("complaintTable.access")}
          onClick={goToURL}
        />
      </div>
    </div>
  );
};
