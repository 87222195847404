import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import moment from "moment";
import { Formik, useFormik } from "formik";
import * as yup from "yup";
import { useDispatch, useStore } from "react-redux";
import {
  addInicialCapital,
  resetCorporation,
} from "../../../../Redux/Corporate/CorporateAction";

import Box from "@mui/material/Box";
import {
  TextField,
  Autocomplete,
  Button,
  IconButton,
  Checkbox,
  Switch,
  FormGroup,
  FormControl,
  FormLabel,
  FormControlLabel,
  Slider,
} from "@mui/material";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import DeleteIcon from "@mui/icons-material/Delete";
import CloseIcon from "@mui/icons-material/Close";

import AedasLoading from "../../../Shared/AedasLoading/AedasLoading";

import { useFetch, useIsLoading } from "../../../../Hooks/useFetch";
import {
  getCurrency,
  addNewCorporate,
  addNewCorporateBody,
  addMember,
  addCapital,
} from "./../../../../Services/CorporateServices/CorporateServices";

import "./../CorporateMain.css";

const validationSchema = yup.object({
  nominal_value: yup
    .number()
    .min(0.01, "El valor mínimo es 0,01")
    .required("Campo obligatorio"),
  shares_issued: yup
    .number()
    .min(1, "El valor mínimo es 1")
    .required("Campo obligatorio"),
  percentage_paid: yup.number().max(25, "El valor máximo es del 25%."),
  date_from: yup.string().required("Campo obligatorio"),

  person: yup
    .array()
    .of(
      yup.object().shape({
        name: yup
          .string()
          .min(
            2,
            "Para sociedades debera incluir abreviatura de tipo de sociedad"
          )
          .required("Campo obligatorio"),
        cif_nif: yup.string().required(),
        number_shares: yup
          .number()
          .min(1, "Campo obligatorio")
          .required("Campo obligatorio"),
      })
    )
    .required(),
});

export const InicialCapital = () => {
  const navigate = useNavigate();
  const params: any = useParams();
  const id = params ? params.corporate_id : "nueva";
  const dispatch = useDispatch();

  const store = useStore();
  const storeIncialCapitalData = store.getState().corporateReducer.capital;

  // const [ currency, isFetchingCurrency ] = useFetch(getCurrency);
  const currency = ["EUR", "USD"];

  // const [ descriptionOptions, isFetchingdescriptionOptions ] = useFetch(getdescriptionOptions);
  const descriptionOptions = [
    "Acciones ordinarias",
    "Participaciones sociales",
    "Otros",
  ];

  // const isLoading = useIsLoading([isFetchingCurrency]);
  const isLoading = false;
  const [person, setPerson] = useState<any[]>(storeIncialCapitalData.person);
  const [removedPerson, setRemovedPerson] = useState<any[]>([]);
  const [sharesAddedList, setSharesAddedList] = useState<any[]>([]);

  const formik = useFormik({
    initialValues: {
      description: storeIncialCapitalData.description,
      nominal_value: storeIncialCapitalData.nominal_value,
      currency: storeIncialCapitalData.currency,
      shares_issued: storeIncialCapitalData.shares_issued,
      total_capital: 0,
      shares_partially_paid: storeIncialCapitalData.shares_partially_paid,
      percentage_paid: storeIncialCapitalData.percentage_paid,
      total_partially_paid: 0,
      political_rights: storeIncialCapitalData.political_rights,
      comments: storeIncialCapitalData.comments,
      shares_comments: storeIncialCapitalData.shares_comments,
      date_from: storeIncialCapitalData.date_from,
      date_to: storeIncialCapitalData.date_to,
      person: storeIncialCapitalData.person,
    },

    enableReinitialize: true,

    validationSchema: validationSchema,

    onSubmit: async (values: any) => {
      console.log(values);
      let sumShares = 0;

      let peopleShares = values.person.filter(
        (person: any) => parseInt(person.number_shares) > 0
      );

      peopleShares.map((person: any) => {
        sumShares += parseFloat(person.number_shares);
      });

      peopleShares.map((person: any) => {
        let intervalUpdatedFormat: any[] = [];

        if (person.interval.length > 0) {
          person.interval.forEach((item: any) => {
            intervalUpdatedFormat.push({
              id_range: null,
              start: item[0] || item.start,
              end: item[1] || item.end,
            });
          });

          person.interval = intervalUpdatedFormat;
        }
      });

      if (sumShares != parseFloat(values.shares_issued)) {
        toast.warning(
          "Revisa los valores, los títulos asignados deberán ser iguales a la suma de los emitidos."
        );
      } else {
        dispatch(addInicialCapital({ ...values, person: peopleShares }));
        toast.success("Se guardaron los datos ingresados");
        createCorporate();
      }
    },
  });

  useEffect(() => {
    formik.setFieldValue(
      "total_capital",
      formik.values.nominal_value * formik.values.shares_issued
    );
  }, [formik.values.nominal_value, formik.values.shares_issued]);

  useEffect(() => {
    formik.setFieldValue(
      "total_partially_paid",
      (formik.values.nominal_value *
        formik.values.shares_partially_paid *
        formik.values.percentage_paid) /
        100
    );
  }, [formik.values.shares_partially_paid, formik.values.percentage_paid]);

  const handleAddPerson = () => {
    const newPerson = [
      ...formik.values.person,
      {
        id: formik.values.person.length,
        name: " ",
        juridical: true,
        cif_nif: "",
        number_shares: 0,
        percentage: 0,
        interval: [],
      },
    ];

    setPerson(newPerson);
    formik.setFieldValue("person", newPerson);
  };

  const handleRemovePerson = (e: any) => {
    const idPerson = e.target.id !== "" ? e.target.id : e.target.parentNode.id;
    setRemovedPerson([...removedPerson, formik.values.person[idPerson]]);

    formik.setFieldValue(`person[${idPerson}].number_shares`, 0);
    formik.setFieldValue(`person[${idPerson}].interval`, []);

    document
      .querySelector(`#person-${idPerson}.person-inputs`)
      ?.setAttribute("style", "display: none");
  };

  const createCorporate = () => {
    const storedData = store.getState().corporateReducer;
    addCapital(storedData.capital, id)
      .then((response: any) => navigate("/sociedades"))
      .then((response: any) => dispatch(resetCorporation()));
  };

  return (
    <>
      {isLoading ? (
        <AedasLoading></AedasLoading>
      ) : (
        <div className="h-100 m-4">
          <div className="main-container py-3 px-5">
            <h1 className="main-title">Suscripción Capital Inicial</h1>
            <Box
              component="form"
              sx={{
                width: "100%",
                mx: "auto",
                my: 2,
              }}
              noValidate
              autoComplete="on"
              className="text-center"
              onSubmit={formik.handleSubmit}
            >
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div style={{ width: "50%", textAlign: "left" }}>
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <Autocomplete
                      id="description"
                      className="autocomplete coporate"
                      options={descriptionOptions}
                      // getOptionLabel={(option: any) => option.types}
                      style={{
                        width: "95%",
                        margin: "1rem 1rem 0 0",
                        paddingRight: "1rem",
                      }}
                      onChange={(e, value) => {
                        formik.setFieldValue("description", value);
                      }}
                      fullWidth
                      value={formik.values.description}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Descripción *"
                          style={{ margin: "1rem" }}
                          error={
                            formik.touched.description &&
                            Boolean(formik.errors.description)
                          }
                          helperText='En caso de elegirse el valor "Otros" detallar en anotaciones'
                        />
                      )}
                    />

                    <div
                      style={{
                        display: "flex",
                        width: "95%",
                        margin: "1rem",
                        paddingRight: "1rem",
                        justifyContent: "space-between",
                      }}
                    >
                      <TextField
                        id="nominal_value"
                        label="Valor nominal *"
                        variant="outlined"
                        style={{ width: "60%" }}
                        value={formik.values.nominal_value}
                        onChange={formik.handleChange}
                        error={
                          formik.touched.nominal_value &&
                          Boolean(formik.errors.nominal_value)
                        }
                        helperText={
                          formik.touched.nominal_value &&
                          formik.errors.nominal_value
                        }
                      />
                      <Autocomplete
                        id="currency"
                        className="autocomplete coporate"
                        options={currency}
                        // getOptionLabel={(option: any) => option.types}
                        onChange={(e, value) => {
                          formik.setFieldValue("currency", value);
                        }}
                        style={{ width: "30%" }}
                        value={formik.values.currency}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Divisa *"
                            style={{ margin: "0 !important" }}
                            error={
                              formik.touched.currency &&
                              Boolean(formik.errors.currency)
                            }
                            helperText={
                              formik.touched.currency && formik.errors.currency
                            }
                          />
                        )}
                      />
                    </div>
                    <div
                      style={{
                        display: "flex",
                        width: "95%",
                        margin: "1rem",
                        paddingRight: "1rem",
                        justifyContent: "space-between",
                      }}
                    >
                      <TextField
                        id="shares_issued"
                        label="Nº títulos emitidos *"
                        variant="outlined"
                        style={{ width: "60%" }}
                        value={formik.values.shares_issued}
                        onChange={formik.handleChange}
                        error={
                          formik.touched.shares_issued &&
                          Boolean(formik.errors.shares_issued)
                        }
                        helperText={
                          formik.touched.shares_issued &&
                          formik.errors.shares_issued
                        }
                      />

                      <TextField
                        id="total_capital"
                        label="Total capital emitido"
                        variant="outlined"
                        disabled
                        style={{ width: "30%" }}
                        value={formik.values.total_capital}
                      />
                    </div>
                  </div>

                  {formik.values.description != "Participaciones sociales" ? (
                    <div
                      className="grey-box capital"
                      style={{ width: "calc(95% - 1rem)" }}
                    >
                      <h4>Desembolsos parciales</h4>
                      <div style={{ display: "flex" }}>
                        <TextField
                          id="shares_partially_paid"
                          label="Nº Títulos Parc. Desembolsados"
                          style={{
                            backgroundColor: "#d8d8d8",
                            width: "50%",
                            margin: "1rem",
                          }}
                          variant="outlined"
                          value={formik.values.shares_partially_paid}
                          onChange={formik.handleChange}
                          error={
                            formik.touched.shares_partially_paid &&
                            Boolean(formik.errors.shares_partially_paid)
                          }
                          helperText={
                            formik.touched.shares_partially_paid &&
                            formik.errors.shares_partially_paid
                          }
                        />
                        <TextField
                          id="percentage_paid"
                          label="% Desembolsado"
                          style={{
                            backgroundColor: "#d8d8d8",
                            width: "50%",
                            margin: "1rem",
                          }}
                          InputLabelProps={{ shrink: true }}
                          variant="outlined"
                          value={formik.values.percentage_paid}
                          onChange={formik.handleChange}
                          error={
                            formik.touched.percentage_paid &&
                            Boolean(formik.errors.percentage_paid)
                          }
                          helperText={
                            formik.touched.percentage_paid &&
                            formik.errors.percentage_paid
                          }
                        />
                      </div>
                      <TextField
                        id="total_partially_paid"
                        label="Total desembolsado"
                        fullWidth
                        disabled
                        style={{
                          backgroundColor: "#d8d8d8",
                          width: "50%",
                          margin: "1rem",
                        }}
                        variant="outlined"
                        value={formik.values.total_partially_paid}
                      />
                    </div>
                  ) : (
                    <></>
                  )}

                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <div
                      style={{
                        display: "flex",
                        marginLeft: "1rem",
                        maxWidth: "95%",
                      }}
                    >
                      <FormControlLabel
                        value={formik.values.political_rights}
                        style={{
                          margin: "1rem 0",
                          flexDirection: "row-reverse",
                          justifyContent: "space-between",
                          padding: "0",
                        }}
                        className="check-box-label"
                        control={
                          <Switch
                            id="is-political-rights"
                            checked={formik.values.political_rights}
                            onClick={() =>
                              formik.setFieldValue(
                                "political_rights",
                                !formik.values.political_rights
                              )
                            }
                          />
                        }
                        label="Derechos políticos"
                      />

                      <TextField
                        id="date_from"
                        label="Vigencia desde *"
                        InputLabelProps={{ shrink: true }}
                        style={{ margin: "1rem", width: "30%" }}
                        variant="outlined"
                        type={"date"}
                        value={formik.values.date_from}
                        onChange={formik.handleChange}
                        error={
                          formik.touched.date_from &&
                          Boolean(formik.errors.date_from)
                        }
                        helperText={
                          formik.touched.date_from && formik.errors.date_from
                        }
                      />
                      <TextField
                        id="date_to"
                        label="Vigencia hasta"
                        InputLabelProps={{ shrink: true }}
                        style={{ margin: "1rem", width: "30%" }}
                        variant="outlined"
                        type={"date"}
                        inputProps={{
                          min: formik.values.date_from,
                        }}
                        value={formik.values.date_to}
                        onChange={formik.handleChange}
                        error={
                          formik.touched.date_to &&
                          Boolean(formik.errors.date_to)
                        }
                        helperText={
                          formik.touched.date_to && formik.errors.date_to
                        }
                      />
                    </div>
                    <TextField
                      id="comments"
                      label="Anotaciones"
                      fullWidth
                      multiline
                      minRows={3}
                      style={{
                        maxWidth: "95%",
                        margin: "0 1rem",
                        paddingRight: "1rem",
                      }}
                      variant="outlined"
                      value={formik.values.comments}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.comments &&
                        Boolean(formik.errors.comments)
                      }
                      helperText={
                        formik.touched.comments && formik.errors.comments
                      }
                    />
                  </div>
                </div>

                <div style={{ width: "50%", textAlign: "left", flexGrow: "1" }}>
                  <div
                    className="grey-box capital"
                    id="shares-inputs-container"
                    style={{ marginTop: "1rem" }}
                  >
                    <h4>Sociedad / Persona Tenedora</h4>

                    {person.map((person: any, index: number) => {
                      return (
                        <PersonRow
                          formik={formik}
                          key={index}
                          index={index}
                          sharesAddedList={sharesAddedList}
                          setSharesAddedList={setSharesAddedList}
                          handleRemovePerson={handleRemovePerson}
                        />
                      );
                    })}

                    <div
                      className="button-container"
                      style={{ margin: "0 auto 1rem", display: "flex" }}
                    >
                      <Button
                        // type='submit'
                        onClick={handleAddPerson}
                        // style={{ margin: "0 !important", padding: "0 !important", width: "10%"}}
                        color="primary"
                        className="button grey-button"
                        startIcon={<AddCircleIcon />}
                      >
                        {" "}
                        Agregar Sociedad / Persona Tenedora
                      </Button>
                    </div>

                    <TextField
                      id="shares_comments"
                      label="Comentarios"
                      fullWidth
                      style={{
                        backgroundColor: "#d8d8d8",
                        maxWidth: "95%",
                        margin: "1rem",
                      }}
                      // style={{maxWidth: "95%", margin: "0 1rem", paddingRight: "1rem"}}
                      variant="outlined"
                      multiline
                      minRows={3}
                      value={formik.values.shares_comments}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.shares_comments &&
                        Boolean(formik.errors.shares_comments)
                      }
                      helperText={
                        formik.touched.shares_comments &&
                        formik.errors.shares_comments
                      }
                    />
                  </div>
                </div>
              </div>

              <Button
                color="primary"
                className="button grey-button"
                onClick={() => navigate(`/agregar-miembros/${id}`)}
              >
                Volver
              </Button>

              <Button
                type="submit"
                color="primary"
                className="button blue-button"
              >
                Agregar capital inicial
              </Button>
            </Box>
          </div>
        </div>
      )}
    </>
  );
};

const PersonRow = (props: any) => {
  const { formik, sharesAddedList, setSharesAddedList } = props;
  const [minRangeSelector, setMinRangeSelector] = useState(0);
  const [maxRangeSelector, setMaxRangeSelector] = useState(0);

  const [sharesSelector, setSharesSelector] = useState<number[]>([
    minRangeSelector,
    maxRangeSelector,
  ]);
  const [sharesRange, setSharesRange] = useState<any[]>([]);

  let numberSharesAdded: number = 0;

  useEffect(() => {
    if (formik.values.shares_issued > 0 && formik.values.person.length > 0) {
      formik.setFieldValue(
        `person[${props.index}].percentage`,
        (formik.values.person[props.index].number_shares /
          formik.values.shares_issued) *
          100
      );
    }
  }, [formik.values.person[props.index]?.number_shares]);

  useEffect(() => {
    formik.setFieldValue(`person[${props.index}].interval`, sharesRange);
  }, [sharesRange]);

  const addValuesRange = (value: string, name: string) => {
    if (name.includes("max")) {
      if (parseInt(value) > parseInt(formik.values.shares_issued)) {
        toast.warning(
          "Revisa los valores ingresados, el número máximo a designar corresponde al número de títulos emitidos."
        );
      } else {
        setMaxRangeSelector(parseInt(value));
        setSharesSelector([minRangeSelector, parseInt(value)]);
      }
    } else if (name.includes("min")) {
      if (parseInt(value) < 1) {
        toast.warning("Revisa los valores ingresados, el valor mínimo es 1.");
      } else {
        setMinRangeSelector(parseInt(value));
        setSharesSelector([parseInt(value), maxRangeSelector]);
      }
    } else return;
  };

  const handleSetRange = (e: any) => {
    addValuesRange(e.target.value, e.target.id);

    // console.log('socios', formik.values.person, formik.values.person.length)

    // // first interval set
    // if ( formik.values.person.length === 1) {
    //   if (parseInt(e.target.value) > parseInt(formik.values.shares_issued)) {
    //     toast.warning('Revisa los valores ingresados, el número máximo a designar corresponde al número de títulos emitidos.')
    //   } else {
    //     addValuesRange(e.target.value, e.target.id);
    //   }
    // }

    // formik.values.person.map((person: any) => {
    //   person.interval.map((interval: any) => {
    //     if( e.target.value < interval[0] || e.target.value > interval[1]) {
    //       console.log(`${e.target.value} < ${interval[0]} || ${e.target.value} > ${interval[1]} --> ok`);
    //       addValuesRange(e.target.value, e.target.id);
    //     } else {
    //       console.log(`${e.target.value} | ${interval[0]} | ${interval[1]}`);
    //       toast.warning('Revisa los valores ingresados, no pueden estar dento de un rango ya asignado.')
    //     }
    //   })
    // })
  };

  const handleSetRangeButton = () => {
    sharesRange.map((range: any) => {
      numberSharesAdded += range[1] - range[0] + 1;
    });

    const allRangesPeople = formik.values.person
      .map((person: any) => {
        if (person.number_shares > 0) {
          return person.interval;
        } else return [];
      })
      .flat(1);

    if (allRangesPeople.length < 1) {
      if (
        sharesSelector[0] >= 1 &&
        sharesSelector[1] >= 1 &&
        sharesSelector[0] <= sharesSelector[1]
      ) {
        setSharesRange([...sharesRange, sharesSelector]);
        setMaxRangeSelector(0);
        setMinRangeSelector(0);
      } else {
        toast.warning(
          "Revisa los valores, no puden ser menores a 1 ni deben ingresarse en rangos invertidos."
        );
      }
      // if ( sharesSelector[0] === sharesSelector[1] ) {
      //   setSharesAddedList([...sharesAddedList, sharesSelector[0]])
      // } else {
      //     const selectorInterval = Array.from({length:(sharesSelector[1] - sharesSelector[0] - 1)},(v,k)=>sharesSelector[0]+1+k)
      //     setSharesAddedList([...sharesAddedList, ...sharesSelector, ...selectorInterval])
      // }
    } else {
      if (
        allRangesPeople.every(
          (range: any) =>
            numberSharesAdded <=
              parseInt(formik.values.person[props.index].number_shares) &&
            ((sharesSelector[1] < range[0] && sharesSelector[0] >= 1) ||
              (sharesSelector[0] > range[1] &&
                sharesSelector[1] <= parseInt(formik.values.shares_issued))) &&
            sharesSelector[0] <= sharesSelector[1]
        )
      ) {
        setSharesRange([...sharesRange, sharesSelector]);
        setMaxRangeSelector(0);
        setMinRangeSelector(0);
        numberSharesAdded += sharesSelector[1] - sharesSelector[0] + 1;
      } else {
        toast.warning("Revisa los valores ingresados.");
      }

      // allRangesPeople.every((range: any) => {
      //   if ( numberSharesAdded <= formik.values.person[props.index].number_shares &&
      //     (sharesSelector[1] < range[0] && sharesSelector[0] >= 1 ||
      //       sharesSelector[0] > range[1] && sharesSelector[1] <= parseInt(formik.values.shares_issued)) ) {

      //       console.log('from if', range)

      //       setSharesRange([...sharesRange, sharesSelector]);

      //       if ( sharesSelector[0] === sharesSelector[1] ) {
      //         setSharesAddedList([...sharesAddedList, sharesSelector[0]])
      //       } else {
      //           const selectorInterval = Array.from({length:(sharesSelector[1] - sharesSelector[0] - 1)},(v,k)=>sharesSelector[0]+1+k)
      //           setSharesAddedList([...sharesAddedList, ...sharesSelector, ...selectorInterval])
      //       }
      //   } else {
      //     console.log('from else', range)
      //     toast.warning('Revisa los valores.');
      //     return
      //   }
      // })
    }
  };

  const resetRange = () => {
    sharesRange.pop();
    setSharesRange([...sharesRange]);
  };

  return (
    <div className="person-inputs" id={`person-${props.index}`}>
      <div style={{ textAlign: "end" }}>
        <IconButton
          color="primary"
          aria-label="upload picture"
          component="label"
          onClick={props.handleRemovePerson}
          id={props.index}
        >
          {/* <DeleteIcon id={props.index} /> */}
          <CloseIcon id={props.index} />
        </IconButton>
      </div>

      <div>
        <TextField
          id="person-name"
          label="Nombre Completo *"
          style={{
            backgroundColor: "#d8d8d8",
            width: "calc(100% - 2rem)",
            margin: "1rem 0 1rem 1rem",
          }}
          variant="outlined"
          value={formik.values.person[props.index]?.name}
          // helperText="Para sociedades debera incluir abreviatura de tipo de sociedad"
          onChange={(e: any) =>
            formik.setFieldValue(`person[${props.index}].name`, e.target.value)
          }
          // error={
          //   formik.touched.person[props.index]?.name && Boolean(formik.errors.person[props.index]?.name)
          // }
          // helperText={formik.touched.person[props.index]?.name && formik.errors.person[props.index]?.name}
        />
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <TextField
            id="cif-nif"
            label="NIF *"
            style={{ backgroundColor: "#d8d8d8", width: "60%", margin: "1rem" }}
            variant="outlined"
            // helperText='Formato A12345678, sin espacios, ni guiones'
            value={formik.values.person[props.index]?.cif_nif}
            onChange={(e: any) =>
              formik.setFieldValue(
                `person[${props.index}].cif_nif`,
                e.target.value
              )
            }
          />
          <FormControlLabel
            value={formik.values.person[props.index]?.juridical}
            style={{
              margin: "2rem 1rem 0 0",
              width: "30%",
              height: "2em",
              flexDirection: "row-reverse",
              fontSize: "0.5rem",
              color: "rgba(0, 0, 0, 0.6)",
              fontFamily: "Roboto",
            }}
            control={
              <Switch
                id="is-jurirical"
                checked={formik.values.person[props.index]?.juridical}
                onClick={() =>
                  formik.setFieldValue(
                    `person[${props.index}].juridical`,
                    !formik.values.person[props.index]?.juridical
                  )
                }
              />
            }
            label="Persona Juridica"
          />
          {/* <Button
            // type='submit'
        
            // style={{ margin: "0 !important", padding: "0 !important", width: "10%"}}
            color="primary"
            className="button grey-button"
            startIcon={<AddCircleIcon />}
          > Agregar  Sociedad / Persona Tenedora
          </Button> */}
          {/* <div style={{marginTop: "1rem"}}>
            <IconButton color="primary" aria-label="upload picture" component="label" onClick={props.handleRemovePerson} id={props.index}>
              <DeleteIcon id={props.index} />
            </IconButton>
          </div> */}
        </div>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <TextField
            id="number_shares"
            label="Nº Títulos *"
            style={{ width: "50%", margin: "1rem" }}
            // style={{ color: "red", margin: "1rem", backgroundColor: "#d8d8d8", width: "40%",}}
            InputLabelProps={{ shrink: true }}
            variant="outlined"
            value={formik.values.person[props.index]?.number_shares}
            onChange={(e: any) =>
              formik.setFieldValue(
                `person[${props.index}].number_shares`,
                e.target.value
              )
            }
          />
          <TextField
            id="percentage"
            label="%"
            style={{ width: "50%", margin: "1rem" }}
            InputLabelProps={{ shrink: true }}
            variant="outlined"
            disabled
            value={formik.values.person[props.index]?.percentage}
          />
        </div>

        {formik.values.person[props.index]?.number_shares <= 0 ? (
          <></>
        ) : (
          <>
            <p style={{ margin: "0 1rem" }}>
              Establecer intervalos de participaciones:
            </p>
            <div style={{ display: "flex", alignItems: "center" }}>
              <TextField
                id="min_range_shares"
                label="Desde *"
                type={"number"}
                InputProps={{
                  inputProps: {
                    min: 1,
                    max: parseInt(formik.values.shares_issued),
                  },
                }}
                style={{
                  color: "red",
                  margin: "1rem",
                  backgroundColor: "#d8d8d8",
                  width: "40%",
                }}
                InputLabelProps={{ shrink: true }}
                variant="outlined"
                value={minRangeSelector}
                onChange={(event: any) => handleSetRange(event)}
              />
              <p style={{ marginBottom: "0" }}> al </p>
              <TextField
                id="max_range_shares"
                label="Hasta *"
                type={"number"}
                InputProps={{
                  inputProps: {
                    min: 1,
                    max: parseInt(formik.values.shares_issued),
                  },
                }}
                style={{
                  color: "red",
                  margin: "1rem",
                  backgroundColor: "#d8d8d8",
                  width: "40%",
                }}
                InputLabelProps={{ shrink: true }}
                variant="outlined"
                value={maxRangeSelector}
                onChange={(event: any) => handleSetRange(event)}
              />
              <div style={{ display: "flex", flexDirection: "column" }}>
                <Button
                  className="button blue-button shares"
                  onClick={handleSetRangeButton}
                >
                  Añadir
                </Button>
                <Button
                  className="button grey-button shares"
                  onClick={resetRange}
                >
                  Borrar
                </Button>
              </div>
            </div>
            {sharesRange.length <= 0 ? (
              <></>
            ) : (
              <>
                <div
                  className="shares-detail-container"
                  style={{ margin: "0 1rem" }}
                >
                  <span>Participaciones:</span>
                  {sharesRange.map((range: any, index: number) => {
                    return (
                      <span key={index}>
                        {" "}
                        {range[0] != range[1]
                          ? `${range[0]} al ${range[1]}`
                          : range[0]}
                      </span>
                    );
                  })}
                </div>
              </>
            )}
          </>
        )}
      </div>

      {/* { formik.values.person.length > 1 ? 
        <hr style={{width: "75%", margin: "1.5rem auto 1rem"}}/>
      : <></>} */}
    </div>
  );
};
