import { useEffect, useState } from 'react'
import { useAuth } from 'react-oidc-context'

export const usePermission = () => {
  const { user } = useAuth()
  const [isPermissionLoading, setIsPermissionLoading] = useState<boolean>(true);
  const [scopes, setScopes] = useState<any[]>([])
  const [token, setToken] = useState<any>();

  useEffect(() => {
    if (user) {
      setScopes(user.scopes || []); // Si user.scopes es undefined, establece un array vacío
      setToken(user.access_token);
    }
  }, [user])

  useEffect(() => {
    setIsPermissionLoading(scopes.length === 0); // Si no hay scopes, aún estamos cargando
  }, [scopes])

  const hasPermission = (perm:string)=>{
    return scopes?.some(scope=> perm.toLowerCase() === scope.toLowerCase())
  }

  return {isPermissionLoading, hasPermission,token}
}
